import React from "react";
import {
    Legend,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";

export function MeasurementSingleLineChart({ data, xAxisDataKey, yAxisLabelValue, lineData, xAxisLabel = "", isXAxisDate = false }) {
    return (
        <ResponsiveContainer width="100%" height={400}>
            <LineChart width={600} height={300} data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey={xAxisDataKey}
                    tickFormatter={isXAxisDate ? (dateStr) => dateStr : undefined
                    }
                    padding={{ left: 30, right: 30 }}
                    tickMargin={12}
                />
                <YAxis
                    label={{ value: yAxisLabelValue, angle: -90, position: "insideLeft" }}
                    tickCount={10}
                />
                <Tooltip labelFormatter={(value) => (xAxisLabel ? `${xAxisLabel}:` : "") + value} />
                <Line type="monotone" dataKey={lineData.key} name={lineData.name} stroke="#4496A8" strokeWidth={2} />
            </LineChart>
        </ResponsiveContainer>
    );
};

export function MeasurementMultiLineChart({ data, xAxisDataKey, lines, setLines, xAxisLabel = "", isXAxisDate = false, yAxisLabelValue = null }) {
    const handleLegendClick = (o) => {
        const { dataKey } = o;
        setLines(prevState => ({
            ...prevState,
            [dataKey]: {
                ...lines[dataKey],
                hide: !lines[dataKey].hide,
            }
        }));
    };
    return (
        <ResponsiveContainer width="100%" height={500}>
            <LineChart width={600} height={500} data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey={xAxisDataKey}
                    tickFormatter={isXAxisDate ? (dateStr) => dateStr : undefined
                    }
                    padding={{ left: 30, right: 30 }}
                    tickMargin={12}
                />
                <YAxis
                    label={yAxisLabelValue && { value: yAxisLabelValue, angle: -90, position: "insideLeft" }}
                    tickCount={15}
                    allowDecimals={false}
                    domain={([dataMin, dataMax]) => {
                        return [Math.max(0, Math.ceil(dataMin) - 10), Math.ceil(dataMax) + 10];
                    }}
                />
                <Tooltip labelFormatter={(value) => (xAxisLabel ? `${xAxisLabel}:` : "") + value} />
                {Object.keys(lines).map((key, index) => (
                    <Line key={index} type="monotone" dataKey={key} name={lines[key].name} stroke={lines[key].color} hide={lines[key].hide} strokeWidth={2} />
                ))}
                <Legend onClick={handleLegendClick} />

            </LineChart>
        </ResponsiveContainer>
    );
};
