import { configureStore } from "@reduxjs/toolkit"
import authReducer from "./features/slice/authSlice"
import userReducer from "./features/slice/userSlice"
import { api } from "./state/api.jsx";


import { setupListeners } from "@reduxjs/toolkit/query";

const store = configureStore({
    reducer: {
        auth: authReducer,
        user: userReducer,
        [api.reducerPath]: api.reducer
    },
    middleware: (getDefault) => getDefault().concat(api.middleware)
})

setupListeners(store.dispatch);


export default store;