import React, { useState, } from "react";
import {
  Box,
  Typography,
  Button,
} from "@mui/material";
import Header from "../../components/Header";
import EmailModal from "../../components/EmailModal";
import nutrtitionist_male_pc_img from "../../assets/Nutrizionista_uomo_al_pc.png";
import nutrtitionist_female_pc_img from "../../assets/Nutrizionista_donna_al_pc.png";
import {
  useGetClientsByMonthsQuery,
  useGetCountClientsQuery,
  useGetCountClientsFilteredQuery,
} from "../../redux/state/api";
import { useGetVarianceQuery } from "../../redux/state/api";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useUser } from "../../provider/UserProvider";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
} from "recharts";

const ITP = () => {
  const userData = useUser();

  const [open, setOpen] = useState(false);
  const { data: count, isLoading: isCountLoading } = useGetCountClientsQuery();
  const { data: variance, isLoading: isVariancesLoading } =
    useGetVarianceQuery();
  const { data: countByMonth, isLoading: isCountByMonthLoading } =
    useGetClientsByMonthsQuery();
  const { data: countActive, isLoading: isCountActiveLoading } =
    useGetCountClientsFilteredQuery("active");
  const { data: countInactive, isLoading: isCountInactiveLoading } =
    useGetCountClientsFilteredQuery("inactive");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (
    isCountLoading ||
    isVariancesLoading ||
    isCountByMonthLoading ||
    isCountActiveLoading ||
    isCountInactiveLoading
  ) {
        return <div class="loader-container">
    <div class="loader"></div>
</div>
;
  }

  const activeClients = countActive?.count || 0;
  const inactiveClients = countInactive?.count || 0;

  const data = [
    { name: "Attivi", value: activeClients },
    { name: "Non Attivi", value: inactiveClients },
  ];

  const COLORS = ["#B0D99C", "#EA6B4D"];

  return (
    <Box m="1.5rem 2.5rem" width="90%">
      <Header title="I tuoi progressi" />
      <Box display="flex">
        <Box width="70%">
          <Box
            width="100%"
            height="240px"
            mt="20px"
            display="flex"
            gap="3rem"
            sx={{ backgroundColor: "#B0D99C", borderRadius: "6px" }}
          >

            <Box component="img" alt="logo" src={userData.gender === "female" ? nutrtitionist_female_pc_img : nutrtitionist_male_pc_img} m="1.5rem 2.5rem"></Box>
            <Box mt="70px">
              <Box display="flex" gap="1rem">
                <Typography
                  variant="h1"
                  color="white"
                  sx={{ fontWeight: "600", fontSize: "48px" }}
                >
                  {count.count}
                </Typography>
                <Box
                  display="flex"
                  height="20px"
                  mt="15px"
                  sx={{ backgroundColor: "rgba(193, 199, 205, 0.50)" }}
                >
                  <ArrowUpwardIcon sx={{ color: "white" }} />
                  <Typography
                    variant="h1"
                    color="white"
                    pr="4px"
                    mt="4px"
                    sx={{ fontWeight: "600", fontSize: "14px" }}
                  >
                    {variance.variation ? variance.variation.toFixed(2) : 0} %
                  </Typography>
                </Box>
              </Box>
              <Typography
                variant="h1"
                color="white"
                mt="3px"
                sx={{ fontWeight: "400", fontSize: "14px" }}
              >
                Pazienti registrati da te su Dietiamo
              </Typography>
            </Box>
          </Box>
          <Typography
            variant="h1"
            color="black"
            mt="50px"
            sx={{ fontWeight: "700", fontSize: "24px" }}
          >
            Dati totali
          </Typography>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={countByMonth.data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="month"
                label={{ value: "Mese", position: "insideBottom" }}
              />
              <YAxis
                label={{ value: "Numero Pazienti", angle: -90, position: "insideLeft" }}
              />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="count"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </Box>
        <Box width="30%">
          <Box
            sx={{ border: "1px solid rgba(193, 199, 205, 1)" }}
            width="300px"
            height="240px"
            mt="20px"
            ml="50px"
          >
            <Typography
              variant="body2"
              color="black"
              mt="20px"
              ml="20px"
              mr="20px"
              sx={{ fontWeight: "400", fontSize: "16px" }}
            >
              Ci sono altri dati che secondo te potrebbe essere utile
              raccogliere?
              <strong>Suggeriscili</strong> e il Team di Dietiamo valuterà se
              aggiungerli. 
              <strong> <br/> Grazie per il tuo contributo!</strong>
            </Typography>
            <Button
              sx={{ mt: "20px", ml: "20px",  background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
  textTransform: 'none',
  fontWeight: 600,
  padding:"8px 16px",
  boxShadow: 'none',
  '&:hover, &:active, &:focus': {
    boxShadow: 'none'
  }
}} 
              variant="contained"
              mt="20px"
              style={{ borderRadius: "8px"}}
              onClick={handleOpen}
            >
              Suggerisci al Team!
            </Button>
          </Box>
          <Box
            sx={{ border: "1px solid rgba(193, 199, 205, 1)" }}
            width="300px"
            height="400px"
            mt="40px"
            ml="50px"
          >
            <Typography
              variant="h1"
              color="black"
              mt="20px"
              ml="20px"
              sx={{ fontWeight: "700", fontSize: "24px" }}
            >
              Divisione per attività
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={data}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  innerRadius={0}
                  fill="#8884d8"
                  label
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Box>
        </Box>
      </Box>
      <EmailModal
        title={"Suggerisci un dato da raccogliere"}
        subTitle={"Se hai notato qualche errore o funzionalità mancante, inviaci un'email per darci la possibilità di migliorare la Nutriboard."}
        type={"metric_suggestion"}
        open={open}
        handleClose={handleClose}
      >
      </EmailModal>
    </Box>
  );
};

export default ITP;
