import React, { useState } from "react";
import {
  Box,
  Typography,
  InputBase,
  IconButton,
  SvgIcon,
  Alert,
  Collapse,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Header from "../../components/Header";
import frame from "../../assets/Frame.png";
import { ReactComponent as copy } from "../../assets/icons/copy.svg";
import { useGetReferralsQuery } from "../../redux/state/api";
import { DataGrid } from "@mui/x-data-grid";

const Referral = () => {
  const [open, setOpen] = React.useState(false);

  const { data, isLoading } = useGetReferralsQuery();

  const columns = [
    { field: "nome", headerName: "Nome", flex: 1, headerClassName: "theme" },
    {
      field: "cognome",
      headerName: "Cognome",
      flex: 1,
      headerClassName: "theme",
    },
    {
      field: "dataDiIscrizione",
      headerName: "Data di iscrizione",
      flex: 1,
      headerClassName: "theme",
    },
    { field: "stato", headerName: "Stato", flex: 1, headerClassName: "theme" },
  ];

  if (isLoading) {
        return <div class="loader-container">
    <div class="loader"></div>
</div>
;
  }

  const rows = data.referrals.map((referral) => ({
    id: referral.id,
    nome: referral.nutritionists_referrer.name,
    cognome: referral.nutritionists_referrer.surname,
    dataDiIscrizione: new Date(
      referral.nutritionists_referrer.created_at
    ).toLocaleDateString(),
    stato: referral.referral_status,
  }));

  const code = data.referral_code;
  return (
    <Box
      m="1.5rem 2.5rem"
      height="100%"
      width="80%"
      sx={{
        display: "flex",
        justifyContent: "flex-between",
        overflow: "hidden",
      }}
    >
      <Box width="50%">
        <Box
          sx={{
            marginTop: "30%",
          }}
        >
          <Header title="Referral" />
          <Typography variant="body2" color="#2F1847" sx={{ fontWeight: 400 }}>
            Condividi questo codice con i tuoi colleghi che desiderano iniziare ad utilizzare Dietiamo con uno sconto speciale.
          </Typography>
          <Typography
            variant="h6"
            component="div"
            fontSize="12px"
            marginTop="64px"
            sx={{ fontWeight: 500 }}
          >
            IL TUO CODICE REFERRAL È:
          </Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            borderRadius="9px"
            border={"1px solid var(--border, rgba(193, 199, 205, 0.50))"}
            p="8px"
          >
            <InputBase
              placeholder={code}
              disabled="true"
              fullWidth
            />
            <IconButton

              onClick={() => {
                navigator.clipboard.writeText(code);
                setOpen(true);
              }}
            >
              <SvgIcon component={copy} inheritViewBox />
            </IconButton>
          </Box>
          <Collapse in={open}>
            <Alert
              severity="info"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 1, mt: 1 }}
            >
              Codice referral copiato
            </Alert>
          </Collapse>
          <Typography
            variant="h6"
            component="div"
            fontSize="12px"
            color="#2F1847"
            sx={{ fontWeight: 400 }}
            marginBottom="64px"
            marginTop="8px"
          >
            Il codice può essere utilizzato 3 volte!
          </Typography>
          <Typography
            variant="h3"
            component="div"
            fontSize="24px"
            color="#2F1847"
            sx={{ fontWeight: 700 }}
          >
            I tuoi referral
          </Typography>
          {data.referrals.length !== 0 ? (
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              sx={{
                mt: "20px",
                "& .theme": {
                  backgroundColor: "rgba(70, 72, 104, 0.10)",
                  color: "#2F1847",
                },
              }}
              hideFooterSelectedRowCount
              disableColumnMenu
              disableRowSelectionOnClick
            />
          ) : (
            <Typography
              variant="h6"
              component="div"
              fontSize="16px"
              color="#2F1847"
              sx={{ fontWeight: 400 }}
              marginTop="12px"
            >
              Non ci sono referral attivi al momento
            </Typography>
          )}
        </Box>
      </Box>
      <Box width="50%">
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "30%",
          }}
        >
          <Box
            component="img"
            alt="profile"
            src={frame}
            height="457px"
            width="450px"
            backgroundColor="#a1d392"
            borderRadius="10%"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Referral;
