import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Divider,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormGroup,
  Switch,
} from "@mui/material";
import Check from "@mui/icons-material/Check";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../../components/Header";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { usePutClientMutation } from "../../../redux/state/api";
import dayjs from "dayjs";
import { useGetClientDetailsQuery } from "../../../redux/state/api";
import "dayjs/locale/it";

dayjs.locale('it');
const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

const QontoStepIcon = (props) => {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
};

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const Step1Content = ({
  data2,
  handleChangeName,
  handleChangeSurname,
  handleChangeBirthDate,
  handleChangeGender,
  handleChangeEthnicity,
  handleChangeEmail,
  handleChangePhone,
  handleChangeTaxCode,
  handleChangeShowMacroAndEnergy
}) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "FETCH_SUCCESS":
        return [...state, ...action.payload];
      default:
        return state;
    }
  };

  const [etnia, setEtnia] = useState("");

  const handleChangeEtnia = (event) => {
    setEtnia(event.target.value);
  };

  const handleChangeSelect = (event) => {
    handleChangeEtnia(event);
    handleChangeEthnicity(event);
  };

  return (
    <Box>
      <Header title="Modifica paziente" />

      <Typography
        color="#2F1847"
        fontSize="24px"
        fontWeight="700"
        variant="h1"
        sx={{ mt: "20px" }}
      >
        Dati anagrafici
      </Typography>
      <Box mt="20px" ml="-8px">
        <FormControl
          variant="standard"
          sx={{ m: 1, width: "50%", }}
        >
          <TextField
            value={data2.name}
            id="nome"
            label="Nome"
            variant="filled"
            color="secondary"
            onChange={(event) => handleChangeName(event)}
            required
            error={(data2.hasOwnProperty("name") && data2.name.trim() === '')}
          />
        </FormControl>
        <FormControl variant="standard" sx={{ m: 1, width: "50%", }}>
          <TextField
            value={data2.surname}
            id="cognome"
            label="Cognome"
            variant="filled"
            color="secondary"
            onChange={(event) => handleChangeSurname(event)}
            required
            error={(data2.hasOwnProperty("surname") && data2.surname.trim() === '')}
          />
        </FormControl>
        <FormControl variant="standard" sx={{ m: 1, width: "100%", }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                label="Data di nascita *"
                id="birthdate"
                format="DD/MM/YYYY"
                slotProps={{
                  textField: { variant: "filled", color: "secondary" },
                }}
                onChange={(event) => handleChangeBirthDate(event)}
                error={(data2.hasOwnProperty("birth_date") && data2.birth_date.trim() === '')}
                value={data2.birth_date ? dayjs(data2.birth_date) : null}
              />
            </DemoContainer>
          </LocalizationProvider>
        </FormControl>
        <FormControl
          variant="standard"
          sx={{ m: 1, width: "100%", }}
          color="secondary"
        >
          <FormLabel id="sesso">Sesso *</FormLabel>
          <RadioGroup
            value={data2.gender}
            row
            aria-labelledby="sesso"
            name="row-radio-buttons-group"
            onChange={(event) => handleChangeGender(event)}
            required
            error={(data2.hasOwnProperty("gender") && data2.gender.trim() === '')}
          >
            {" "}
            <FormControlLabel
              value="male"
              control={<Radio color="secondary" />}
              label="Uomo"
            />
            <FormControlLabel
              value="female"
              control={<Radio color="secondary" />}
              label="Donna"
            />{" "}
          </RadioGroup>
        </FormControl>
        <FormControl variant="filled" sx={{ m: 1, width: "50%" }}>
          <InputLabel id="etnia-select-label" color="secondary">
            Etnia
          </InputLabel>
          <Select
            labelId="etnia-select-label"
            id="selectEtnia"
            label="Etnia"
            value={data2.ethnicity}
            onChange={(event) => handleChangeEthnicity(event)}
            color="secondary"
          >
            <MenuItem value={"caucasian"}>Caucasica</MenuItem>
            <MenuItem value={"african_american"}>Afroamericana</MenuItem>
            <MenuItem value={"asian"}>Asiatica</MenuItem>
            <MenuItem value={"hispanic"}>Ispanica</MenuItem>
            <MenuItem value={"mixed"}>Mista</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          variant="standard"
          sx={{ m: 1, width: "50%" }}
        >
          <TextField
            value={data2.tax_code}
            id="tax_code"
            label="Codice Fiscale"
            variant="filled"
            color="secondary"
            onChange={(event) => handleChangeTaxCode(event)}
          />
        </FormControl>
        <Divider
          sx={{
            marginTop: "20px",
            width: "100%",
            bgcolor: "transparent", // Imposta il colore di sfondo su trasparente
            border: 0, // Rimuove il bordo predefinito
            borderTop: "1px dashed rgba(193, 199, 205, 0.50)", // Imposta il bordo superiore come tratteggiato
          }}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', mt: "12px" }}>
          <Typography
            color="#2F1847"
            fontSize="20px"
            fontWeight="500"
            variant="h3"
            sx={{ mt: "20px", ml: "8px", }}
          >
            Mostra macronutrienti e kcal al tuo paziente in app
          </Typography>

          <FormGroup sx={{ ml: 1, mt: 2 }}>
            <Switch checked={data2.show_macro_and_energy} color="secondary" onChange={handleChangeShowMacroAndEnergy} />
          </FormGroup>
        </Box>
        <Divider
          sx={{
            marginTop: "20px",
            width: "100%",
            bgcolor: "transparent", // Imposta il colore di sfondo su trasparente
            border: 0, // Rimuove il bordo predefinito
            borderTop: "1px dashed rgba(193, 199, 205, 0.50)", // Imposta il bordo superiore come tratteggiato
          }}
        />
        <Typography
          color="#2F1847"
          fontSize="24px"
          fontWeight="700"
          variant="h1"
          sx={{ mt: "24px", ml: "8px" }}

        >
          Contatti
        </Typography>
        <Box mt="20px">
          <FormControl
            variant="standard"
            sx={{ m: 1, width: "25%" }}
          >
            <TextField
              value={data2.email}
              id="email"
              label="Email"
              variant="filled"
              color="secondary"
              type="email"
              onChange={(event) => handleChangeEmail(event)}
              required
              error={(data2.hasOwnProperty("email") && data2.email.trim() === '')}
            />
          </FormControl>
          <FormControl variant="standard" sx={{ m: 1, width: "24%" }}>
            <TextField
              value={data2.phone}
              id="phone"
              label="Numero di telefono"
              variant="filled"
              color="secondary"
              type="tel"
              onChange={(event) => handleChangePhone(event)}
            />
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

const steps = ["DATI ANAGRAFICI"];

const ModificaPaziente = () => {
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const pathnameParts = location.pathname.split("/");
  const id = pathnameParts[pathnameParts.length - 1];

  const { data, isLoading } = useGetClientDetailsQuery(id);

  const [clientData, setClientData] = useState({
    name: "",
    surname: "",
    birth_date: "",
    gender: "",
    ethnicity: "",
    email: "",
    phone: "",
    tax_code: "",
    show_macro_and_energy: true,
  });

  const [
    putClient,
    { isLoading: isPutClientLoading, isError: isPutClientError },
  ] = usePutClientMutation();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = async () => {
    try {
      await putClient({ data: clientData, id: id });

      setActiveStep(0);
      navigate(`/pazienti/details/${id}`);
      window.location.reload();
    } catch (err) {
      console.error("Error occurred while creating client:", err);
    }
  };

  const handleChangeName = (event) => {
    setClientData({ ...clientData, name: event.target.value });
  };

  const handleChangeSurname = (event) => {
    setClientData({ ...clientData, surname: event.target.value });
  };

  const handleChangeBirthDate = (event) => {
    const formattedDate = dayjs(event).format("YYYY-MM-DD");
    setClientData({ ...clientData, birth_date: formattedDate });
  };

  const handleChangeGender = (event) => {
    setClientData({ ...clientData, gender: event.target.value });
  };

  const handleChangeEthnicity = (event) => {
    setClientData({ ...clientData, ethnicity: event.target.value });
  };

  const handleChangeTaxCode = (event) => {
    setClientData({ ...clientData, tax_code: event.target.value });
  }

  const handleChangeShowMacroAndEnergy = () => {
    setClientData({ ...clientData, show_macro_and_energy: !clientData.show_macro_and_energy });
  };

  const handleChangeEmail = (event) => {
    setClientData({ ...clientData, email: event.target.value });
  };

  const handleChangePhone = (event) => {
    setClientData({ ...clientData, phone: event.target.value });
  };

  React.useEffect(() => {
    if (data) {
      setClientData((prevState) => ({
        ...prevState,
        name: data.name,
        surname: data.surname,
        birth_date: data.birth_date,
        gender: data.gender,
        ethnicity: data.ethnicity,
        email: data.email,
        phone: data.phone,
        tax_code: data.tax_code,
        show_macro_and_energy: data.show_macro_and_energy,
      }))
    }
  }, [data]);

  const stepContent = [
    <Step1Content
      data2={clientData}
      handleChangeName={handleChangeName}
      handleChangeSurname={handleChangeSurname}
      handleChangeGender={handleChangeGender}
      handleChangeEthnicity={handleChangeEthnicity}
      handleChangePhone={handleChangePhone}
      handleChangeBirthDate={handleChangeBirthDate}
      handleChangeEmail={handleChangeEmail}
      handleChangeTaxCode={handleChangeTaxCode}
      handleChangeShowMacroAndEnergy={handleChangeShowMacroAndEnergy}
    />,
  ];

  if (isPutClientLoading || isLoading) {
    return <div class="loader-container">
      <div class="loader"></div>
    </div>
      ;
  }

  if (isPutClientError) {
    return <p>Errore nel salvataggio</p>;
  }

  console.log(clientData);

  return (
    <Box m="1.5rem 2.5rem">
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Button
            sx={{
              color: "#464868",
              textDecoration: "underline",
              fontSize: "14px",
              fontWeight: "500",
            }}
            onClick={
              activeStep > 0
                ? handleBack
                : activeStep === 0
                  ? () => navigate(`/pazienti/details/${id}`)
                  : handleReset
            }
          >
            {activeStep > 0 ? "Indietro" : "Annulla"}
          </Button>
        </Box>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<QontoConnector />}
        >
          {steps.map((label) => (
            <Step sx={{ ml: "22px" }} key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box>
          <Button
            sx={{
              background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
              textTransform: 'none',
              color: "white",
              padding: "8px 16px",
              borderRadius: "8px",
              fontWeight: 600,
              boxShadow: 'none',
              '&:hover, &:active, &:focus': {
                boxShadow: 'none'
              }
            }}
            variant="contained"
            onClick={activeStep === steps.length - 1 ? handleReset : handleNext}
          >
            {activeStep === steps.length - 1 ? "Salva" : "Avanti"}
          </Button>
        </Box>
      </Box>
      {stepContent[activeStep]}
    </Box>
  );
};

export default ModificaPaziente;
