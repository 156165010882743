import React from "react";
import {
  Box,
  Typography,
  FormControl,
  FilledInput,
  InputAdornment,
  IconButton,
  InputLabel,
  Button,
  CircularProgress,
} from "@mui/material";
import Header from "../../components/Header";
import logo from "../../assets/Modalità_Isolamento.svg";
import frame from "../../assets/Frame.png";
import { useNavigate, useLocation } from "react-router-dom";
import { usePostNewPasswordLoginMutation } from "../../redux/state/api";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const NewPass = () => {
  const [pass, setPass] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [isPasswordValid, setIsPasswordValid] = React.useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const pathnameParts = location.pathname.split("/");
  const nutritionistId = pathnameParts[pathnameParts.length - 1];

  const [postNewPasswordLogin, { isLoading, isError, isSuccess }] =
    usePostNewPasswordLoginMutation();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (password) => {
    if (password.length < 8) {
      setIsPasswordValid(false);
      return;
    }
    setIsPasswordValid(true);
    password = password.trim();

    const data = { new_password: password, nutritionist_id: parseInt(nutritionistId, 10) };

    await postNewPasswordLogin(data);
  };

  if (isLoading) {
        return <div class="loader-container">
    <div class="loader"></div>
</div>
;
  }

  if (isError) {
    return <p>Error...</p>;
  }

  if (isSuccess) {
    navigate("/login");
  }

  return (
    <Box
      height="100%"
      width="100%"
      sx={{
        display: "flex",
        justifyContent: "flex-between",
        overflow: "hidden",
      }}
    >
      <Box height="100vh" width="50%" sx={{ bgcolor: "#a1d392" }}>
        <Box component="img" alt="logo" src={logo} width="25%" m="1.5rem 2.5rem"></Box>
        <Box m="10.5rem 2.5rem">
          <Typography
            variant="h5"
            color="white"
            sx={{ fontWeight: "600", fontSize: "32px", marginTop: "-8px" }}
            width="70%"
          >
            Benvenuto nella Nutriboard Dietiamo
          </Typography>
          <Typography
            variant="body2"
            color="white"
            sx={{ fontSize: "16px", marginTop: "10px" }}
            width="50%"
          >
            Inizia subito a connetterti ai tuoi pazienti, effettua il Login
          </Typography>
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "-13%" }}
        >
          <Box component="img" alt="frame" src={frame} width="55%" sx={{ marginLeft: "-300px"}}></Box>
        </Box>
      </Box>
      <Box height="100vh" width="50%">
        <Box m="5rem 3.5rem">
          <Typography
            variant="h1"
            fontSize="16px"
            sx={{
              color: "#464868",
              textDecoration: "underline",
              fontWeight: 500,
              cursor: "pointer",
              ":hover": {
                fontWeight: 600,
              },
            }}
            onClick={() => navigate("/login")}
          >
            Torna al login
          </Typography>
        </Box>
        <Box m="10rem 3.5rem">
          <Header title="Nuova password" />
          <Typography
            variant="body2"
            fontSize="16px"
            color="black"
            sx={{ mt: "50px" }}
          >
            Crea una nuova password per accedere al tuo account
          </Typography>
          <FormControl
            variant="standard"
            sx={{ m: 1, minWidth: 500, marginLeft: "-10px", marginTop: "30px" }}
          >
            <InputLabel
              id="password-label"
              sx={{
                marginLeft: "10px",
                textTransform: 'none',
                fontWeight: 500,
                boxShadow: 'none',
                '&:hover, &:active, &:focus': {
                  boxShadow: 'none'
                }
              }}
              color="secondary"
            >
              Password
            </InputLabel>
            <FilledInput
              onChange={(event) => setPass(event.target.value)}
              color="#2f1847"
              variant="filled"
              required
              id="password-label"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              error={!isPasswordValid}
            />
            {!isPasswordValid && <p style={{ color: "red" }}> La password deve essere almeno di 8 caratteri</p>}

          </FormControl>
          <Box sx={{ ml: "-10px", width: 500 }}>
            <Button
              sx={{  background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
                textTransform: 'none',
                fontWeight: 600,
                boxShadow: 'none',
                '&:hover, &:active, &:focus': {
                  boxShadow: 'none'
                },
                width: "100%"
              }}
              variant="contained"
              size="medium"
              onClick={() => handleSubmit(pass)}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress color="secondary" /> : "Crea"}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NewPass;
