import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Box,
  Chip,
  useMediaQuery,
  SvgIcon,
  Button,
  Alert,
  AlertTitle,
  Collapse,
} from "@mui/material";
import placeholder from "../assets/picture.png";
import SquareIcon from '@mui/icons-material/Square';
import { useNavigate } from "react-router-dom";
import { useDeleteRicettaMutation } from "../redux/state/api";

const Ricetta = ({ id, image, name, description, tags, nutrition_facts, buttonClass, showBorder, showDeleteButton = true }) => {
  const className = buttonClass || 'gradient-button';
  const classNamew = showBorder !== 'no-border' ? showBorder : 'gradient-border-wrapper';
  const isNonMobile = useMediaQuery("(min-width: 300px)");
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [deleteRicetta, { isLoading, isError }] = useDeleteRicettaMutation();
  const handleClick = (idRicetta) => {
    navigate(`/ricette/details/${idRicetta}`);
  };

  const handleDelete = async (idRicetta) => {
    try {
      setOpen(false);
      await deleteRicetta(idRicetta);
      window.location.reload();
    } catch (err) {
      console.error("Error occurred while deleting:", err);
    }
  };

  if (isLoading) {
    return <p>Eliminazio in corso...</p>;
  }

  if (isError) {
    return <p>Errore durante l'eliminazione della ricetta, ricaricare la pagina.</p>;
  }

  return (
    <Card sx={{ maxWidth: 290, overflow: 'visible', height: 'auto', boxShadow: 'none', border: '1px solid rgba(0, 0, 0, 0.12)'  }}>
      <CardMedia
        component="img"
        height="170"
        onClick={() => handleClick(id)}
        image={image || placeholder}
        alt="immagine ricetta"
      />
      <CardContent sx={{ marginTop: "16px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="h3"
            fontSize="20px"
            color="#2F1847"
            fontWeight="700"
            noWrap
            title={name}
          >
            {name}
          </Typography>
          <Typography
            variant="h3"
            fontSize="20px"
            color="#2F1847"
            fontWeight="500"
          >
            {nutrition_facts.energy.quantity.toFixed(0)}kcal
          </Typography>
        </Box>
        <Typography
          variant="body2"
          noWrap
          title={description}
        >
          {description}
        </Typography>
        <Box mt="11px" display="flex" gap="0.5rem" height="32px">
          {tags.slice(0, 1).map(({ id, name }) => (
            <Chip 
              style={{
                backgroundColor: 'rgba(70, 72, 104, 1)',
                color: "white",
                fontWeight: "500",
                borderRadius: "16px"
              }} 
              label={name} 
              key={id} 
            />
          ))}
          {tags.length > 1 &&
            <Chip
              label={`+${tags.length - 1}`}
              sx={{ backgroundColor: "rgba(70, 72, 104, 1)", color: "white" }}
            />
          }
        </Box>
        <Box mt="24px">
          <Typography
            variant="h5"
            component="div"
            fontSize="18px"
            color="#2F1847"
            fontWeight="700"
          >
            Macro
          </Typography>
          <Box
            mt="14px"
            display="grid"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            justifyContent="space-between"
            gap="8px"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4", },
            }}
          >
            <Box
              sx={{
                padding: "8px 6px",
                borderRadius: "4px",
                background: "rgba(242, 229, 139, 0.20)",
                display: "flex",
                gap: "2px",
              }}
            >
              <SquareIcon sx={{ marginBottom: "1px", color: "#EDC047", fontSize: "24px" }} />
              <Typography fontWeight="500" fontSize="10px" marginTop="4px" >{Math.round(nutrition_facts.carbohydrates.percentage)}%</Typography>
            </Box>
            <Box
              sx={{
                padding: "8px 6px",
                borderRadius: "4px",
                background: "rgba(38, 180, 206, 0.20)",
                display: "flex",
                gap: "2px",
              }}
            >
              <SquareIcon sx={{ marginBottom: "1px", color: "#4496A8", fontSize: "24px" }} />
              <Typography fontWeight="500" fontSize="10px" marginTop="4px" >{Math.round(nutrition_facts.proteins.percentage)}%</Typography>
            </Box>
            <Box
              sx={{
                padding: "8px 6px",
                borderRadius: "4px",
                background: "rgba(241, 161, 130, 0.20)",
                display: "flex",
                gap: "2px",
              }}
            >
              {/* <SvgIcon component={arancio} inheritViewBox style={{ width: '20px', height: '20px', marginTop: "1px" }}
              /> */}
              <SquareIcon sx={{ marginBottom: "1px", color: "#ED8047", fontSize: "24px" }} />
              <Typography fontWeight="500" fontSize="10px" marginTop="4px" >{Math.round(nutrition_facts.fats.percentage)}%</Typography>
            </Box>
            <Box
              sx={{
                padding: "8px 6px",
                borderRadius: "4px",
                background: "rgba(214, 100, 100, 0.20)",
                display: "flex",
                gap: "2px",
              }}
            >
              <SquareIcon sx={{ marginBottom: "1px", color: "#BF2B2B", fontSize: "24px" }} />
              <Typography fontWeight="500" fontSize="10px" marginTop="4px" >{Math.round(nutrition_facts.alcohol.percentage)}%</Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
      <CardActions disableSpacing>
        <Box display="flex" justifyContent="space-between" width="100%">
          {showDeleteButton ? <Button
            color="error"
            sx={{
              textDecoration: "underline",
              textTransform: "none"
            }}
            onClick={() => setOpen(!open)}
          >
            {!open ? "Elimina" : "Annulla"}
          </Button> :
            <Box></Box>}
        <div className={classNamew}>
          <Button
          className={className}
            onClick={() => handleClick(id)}
            sx={{ fontWeight: 600, textTransform: "none" }}
          >
            Apri ricetta
          </Button>
        </div>
        </Box>
      </CardActions>
      <Collapse in={open}>
        <Alert
          severity="warning"
          action={
            <Button
              color="inherit"
              sx={{
                textTransform: "none"
              }}
              onClick={() => handleDelete(id)}
            >
              Elimina
            </Button>
          }
          sx={{ mb: 2 }}
        >
          <AlertTitle>Attenzione</AlertTitle>
          Stai eliminando una ricetta — <strong>Sei sicuro?</strong>
        </Alert>
      </Collapse>
    </Card>
  );
};

export default Ricetta;
