import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const FileUploadButton = ({ textButton, onFileUpload, onRemoveFile }) => {
    const [fileName, setFileName] = useState('');

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        onFileUpload(file);
        setFileName(file.name)
    };

    const handleRemoveFile = () => {
        setFileName('');
        onRemoveFile();
    };

    return (
        <div>
            <Button
                component="label"
                variant="outlined"
                startIcon={<CloudUploadIcon />}
            >
                {textButton}
                <VisuallyHiddenInput
                    id="fileInput"
                    type="file"
                    onChange={handleFileChange}
                />
            </Button>
            {fileName && (
                <div>
                    {fileName}
                    <Button  onClick={handleRemoveFile} startIcon={<DeleteIcon color="error" />}>
                    </Button>
                </div>
            )}
        </div>
    );
};

export default FileUploadButton;
