import { createSlice } from "@reduxjs/toolkit";

import { createAsyncThunk } from "@reduxjs/toolkit";

const backendURL = process.env.REACT_APP_BASE_URL

const fetchUserData = createAsyncThunk(
    'user/fetchUserData',
    async (payload, thunkAPI) => {
        const { userId, accessToken } = payload;
        try {
            const res = await fetch(`${backendURL}/nutriboard/nutritionists/${userId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            if (!res.ok) {
                throw new Error("Request Failed")
            }

            const data = await res.json();
            return data;
        } catch (err) {
            throw new Error("Failed to fetch user data " + err.message);
        }
    }
)

const initialState = {
    data: null,
    loading: false,
    error: null,
    initialFetchDone: false,
    lastFetchTime: null,
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        clearUserState: (state) => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserData.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchUserData.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
                state.initialFetchDone = true;
                state.lastFetchTime = Date.now();
            })
            .addCase(fetchUserData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
    }
})

export { fetchUserData };
export const { clearUserState } = userSlice.actions;
export default userSlice.reducer