import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box, IconButton } from "@mui/material";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import profileImage from "../../assets/icons/logo.png";
import rectangle from "../../assets/Rectangle 74.png";
import Header from "../../components/Header";
import { ProfileTabs } from "../../components/Tabs";
import { useUser } from "../../provider/UserProvider";
import { usePutNutritionistMutation } from "../../redux/state/api";
import { fetchUserData } from "../../redux/features/slice/userSlice"

const Profile = () => {
  const userInformation = useUser();
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = React.useState("");
  const [putNutritionist, { isLoading: isPutLoading }] =
    usePutNutritionistMutation();

  const id = useSelector((state) => state.auth.userId);
  const accessToken = useSelector((state) => state.auth.userToken);

  const handleImageUpload = async (base64Data) => {
    try{
      await putNutritionist({ data: {"profile_picture": base64Data}, id: id });
      dispatch(fetchUserData({ userId: id, accessToken }));
    } catch (error) {
      console.error("Error occurred while updating profile picture:", error);
    }
  };
  const handleBeforeUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
  
      reader.onload = async (e) => {
        const base64Image = e.target.result;
        setSelectedImage(base64Image);
        await handleImageUpload(base64Image)
      };
  
      reader.readAsDataURL(file);
    }
  };

  React.useEffect(() => {
    setSelectedImage(userInformation ? userInformation.profile_picture : profileImage)
  }, [userInformation])

  if (!userInformation || isPutLoading) {
    return <div class="loader-container">
    <div class="loader"></div>
</div>;
  }
  return (
    <Box sx={{ overflow: "hidden" }}>
      <Box sx={{ position: "relative" }}>
        <Box
          component="img"
          alt="rectangle"
          src={rectangle}
          width="100%"
          height="138px"
          sx={{ objectFit: "cover" }}
        ></Box>
        <Box
          sx={{
            objectFit: "cover",
            position: "absolute",
            left: "70px",
            margin: "auto",
            top: "60px",
          }}
        >
          <Avatar
            alt="Profile Picture"
            src={selectedImage || profileImage}
            sx={{ width: 150, height: 150 }}
          />
          <input
            accept="image/*"
            id="profile-image-upload"
            type="file"
            style={{ display: 'none' }}
            onChange={handleBeforeUpload}
          />
          <label 
            htmlFor="profile-image-upload"
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              background: 'white',
              padding: '4px',
              border: "3px solid white",
              borderRadius: "50%",
            }}
          >
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <BorderColorIcon />
            </IconButton>
          </label>
        </Box>
      </Box>
      <Box ml="70px" width="100%" mt="110px">
        <Box style={{ marginLeft: '-16px' }}>
          <Header title="Il tuo profilo" />
        </Box>
        <ProfileTabs />
    </Box>
    </Box>
  );
};

export default Profile;
