import { createSlice } from "@reduxjs/toolkit";
import axios from "axios"
import { createAsyncThunk } from "@reduxjs/toolkit"


const userToken = localStorage.getItem("userToken")
  ? localStorage.getItem("userToken")
  : sessionStorage.getItem("userToken")
  ? sessionStorage.getItem("userToken")
  : null;
const userId = localStorage.getItem("userId")
  ? localStorage.getItem("userId")
  : sessionStorage.getItem("userId")
  ? sessionStorage.getItem("userId")
  : null;

const initialState = {
    loading: false,
    userInfo: {},
    userToken,
    userId,
    error: null,
    success: false
}



const backendURL = process.env.REACT_APP_BASE_URL

const userLogin = createAsyncThunk(
    'auth/login',
    async ({ email, password, rememberMe }, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            const { data } = await axios.post(
                `${backendURL}/nutriboard/auth/login`,
                { email, password },
                config
            )
            if (rememberMe) {
                localStorage.setItem("userToken", data.authentication.access_token)
                localStorage.setItem("userId", data.nutritionist.id);
            } else {
                sessionStorage.setItem("userToken", data.authentication.access_token);
                sessionStorage.setItem("userId", data.nutritionist.id);
            }
            return data
        } catch (err) {
            if (err.response && err.response.data.message) {
                return rejectWithValue(err.response.data.message)
            } else {
                return rejectWithValue(err.message)
            }
        }
    }
)

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        clearToken: (state) => {
            localStorage.removeItem("userToken")
            localStorage.removeItem("userId")
            sessionStorage.removeItem("userToken");
            state.userToken = null;
            state.userId = null;
            state.success = false;
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(userLogin.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(userLogin.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.userInfo = payload;
                state.success = true;

                if (state.rememberMe) {
                    state.userToken = payload.authentication.access_token;
                    state.userId = payload.nutritionist.id;
                    localStorage.setItem("userToken", payload.authentication.access_token);
                    localStorage.setItem("userId", payload.nutritionist.id);
                } else {
                    state.userToken = payload.authentication.access_token;
                    state.userId = payload.nutritionist.id;
                }
            })
            .addCase(userLogin.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
    }
})

export { userLogin }
export const { clearToken } = authSlice.actions
export default authSlice.reducer