import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  IconButton,
  Modal,
  ListItemText,
  ListItemIcon,
  Dialog,
  DialogContent,
} from "@mui/material";
import Header from "../../components/Header";
import { useUser } from "../../provider/UserProvider";
import VerifiedIcon from "@mui/icons-material/Verified";
import {
  useGetCountClientsQuery,
  useGetVarianceQuery,
  useGetAllClientsQuery,
  useDeleteClienteMutation,
  useGetRicetteQuery,
} from "../../redux/state/api";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import nutrtitionist_male_pc_img from "../../assets/Nutrizionista_uomo_al_pc.png";
import nutrtitionist_female_pc_img from "../../assets/Nutrizionista_donna_al_pc.png";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid } from "@mui/x-data-grid";
import Ricetta from "../../components/Ricetta";
import Carousel from "react-material-ui-carousel";
import nutritionist_help_img from "../../assets/Aiuto_nutrizionista.png";
import referral_img from "../../assets/Referral.png";
import dayjs from "dayjs";
import "dayjs/locale/it";

dayjs.locale('it');

const Dashboard = () => {
  const [ricettePerSlide, setRicettePerSlide] = useState(4); // Valore iniziale

  useEffect(() => {
    const handleResize = () => {
      const larghezzaFinestra = window.innerWidth;
      const larghezzaCardRicetta = 290; // Larghezza massima di una card ricetta
      const margineTotalePerCard = 20; // Assumi 10px di margine su ciascun lato
      const larghezzaTotalePerCard = larghezzaCardRicetta + margineTotalePerCard;

      const nuoveRicettePerSlide = Math.max(1, Math.floor(larghezzaFinestra / larghezzaTotalePerCard));
      setRicettePerSlide(nuoveRicettePerSlide);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const data = useUser();
  const { data: count, isLoading: isCountLoading } = useGetCountClientsQuery();
  const { data: variance, isLoading: isVariancesLoading } =
    useGetVarianceQuery();
  const { data: clients, isLoading: isClientsLoading } =
    useGetAllClientsQuery();
  const [deleteCliente, { isLoading: isDeleteCliente, isError }] =
    useDeleteClienteMutation();
  const { data: ricette, isLoading: isRicetteLoading } = useGetRicetteQuery();

  const navigate = useNavigate();

  const handleAddFirstClient = () => {
    navigate("/pazienti/aggiuntanuovo");
  };

  const handleAddFirstRecipe = () => {
    navigate("/ricette/creazionericetta");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (id) => {
    try {
      setOpenModal(false);
      await deleteCliente(id);
      window.location.reload();
    } catch (err) {
      console.error("Error occurred while deleting:", err);
    }
  };

  const handleAddVisit = (id) => {
    setOpenModal(false);
    navigate(`/pazienti/addVisit/${id}`);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedRow(null);
  };

  const handleOpenModal = (row) => {
    setOpenModal(true);
    setSelectedRow(row);
  };

  const columns = [
    {
      field: "surname",
      headerClassName: "theme",
      headerName: <div style={{ marginRight: '10px' }}>Cognome</div>, // Aggiunto un margine a destra
      flex: 20,
      renderCell: (params) => (
        <Link
          style={{ color: "#000", fontWeight: "bold", textDecoration: "none" }}
          to={`/pazienti/details/${params.row.id}`}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "name",
      headerClassName: "theme",
      headerName: <div style={{ marginRight: '10px' }}>Nome</div>, // Aggiunto un margine a destra
      flex: 20,
      renderCell: (params) => (
        <Link
          style={{ color: "#000", fontWeight: "bold", textDecoration: "none" }}
          to={`/pazienti/details/${params.row.id}`}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "lastVisitDate",
      headerName: <div style={{ marginRight: '10px' }}>Ultima visita</div>, // Aggiunto un margine a destra
      headerClassName: "theme",
      type: "date",
      flex: 20,
      sortable: true,
    },
    {
      field: "createdAt",
      headerName: <div style={{ marginRight: '10px' }}>Data di aggiunta</div>, // Aggiunto un margine a destra
      headerClassName: "theme",
      type: "date",
      flex: 20,
      sortable: true,
    },
    {
      field: "typology",
      headerClassName: "theme",
      headerName: <div style={{ marginRight: '10px' }}>Stato paziente</div>, // Aggiunto un margine a destra
      flex: 20,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <ListItemText primary={params.row.typology} />
          <ListItemIcon>
            <IconButton onClick={() => handleOpenModal(params.row)}>
              <MoreHorizIcon />
            </IconButton>
          </ListItemIcon>
        </div>
      ),
    },
  ];

  if (!data) {
    return <div class="loader-container">
    <div class="loader"></div>
</div>
;
  }

  if (
    isCountLoading ||
    isVariancesLoading ||
    isClientsLoading ||
    isDeleteCliente ||
    isRicetteLoading
  ) {
    return <div class="loader-container">
    <div class="loader"></div>
</div>
;
  }

  if (isError) {
    return <p>Error</p>;
  }

  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  const allRows =
    clients?.data?.map((item) => ({
      id: item.id,
      surname: item.surname,
      name: item.name,
      lastVisitDate: new Date(item.last_visit_date),
      createdAt: new Date(item.created_at),
      typology: item.typology === "active" ? "attivo" : "non attivo",
    })) || [];

  const chunkedRicette = chunkArray(ricette || [], ricettePerSlide - 1);

  
  return (
    <Box m="1.5rem 1.5rem" width="96%">
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box>
          <Header title={`Bentornat${data.gender === "female" ? "a" : "o"},`} />
          <Header title={`${data.gender === "male" ? "Dott." : data.gender === "female" ? "Dott.ssa" : ""} ${data.name} ${data.surname}`} />
        </Box>
        <Box
          mt="20px"
          width="250px"
          height="50px"
          sx={{
            backgroundColor: data.verified ? "#B0D99C" : "#BF2B2B",
            borderRadius: "6px",
          }}
        >
          <Box display="flex">
            <VerifiedIcon
              sx={{ color: "white", width: "34px", height: "34px", m: 1 }}
            />
            <Box sx={{ m: 1 }}>
              <Typography
                variant="h1"
                color="white"
                sx={{ fontWeight: "700", fontSize: "16px" }}
              >
                {data.verified ? "Utente verificato" : "Utente da verificare"}
              </Typography>
              <Typography
                variant="h1"
                color="rgba(255, 255, 255, 0.50)"
                sx={{
                  fontWeight: "500",
                  fontSize: "12px",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={handleClickOpen}
              >
                CHE COSA VUOL DIRE?
              </Typography>
              <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                  <Typography variant="body1">
                    Questo è il bollino che Dietiamo ha studiato per consentire l'accesso alla Nutriboard solo ed esclusivamente a professionisti della nutrizione: se vedi scritto "Utente da verificare", significa che devi ancora caricare le tue informazioni professionali nella sezione "Il mio profilo" per diventare un esperto verificato. Se non caricherai le informazioni, terminato il periodo di prova, le funzioni saranno limitate.
                  </Typography>
                </DialogContent>
              </Dialog>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box mt="30px">
        <Typography color="#2F1847" fontSize="24px" fontWeight="700">
          I tuoi progressi
        </Typography>
        <Box
          width="100%"
          height="240px"
          mt="20px"
          display="flex"
          gap="3rem"
          sx={{ backgroundColor: "#464868", borderRadius: "6px" }}
        >
          <Box component="img" alt="logo" src={data.gender === "female" ? nutrtitionist_female_pc_img: nutrtitionist_male_pc_img} m="1.5rem 0.7rem" sx={{ ml: "50px" }}></Box>

          <Box mt="70px">
            <Typography
              variant="h1"
              color="white"
              mt="-24px"
              sx={{ fontWeight: "600", fontSize: "24px" }}
            >
              Pazienti totali
            </Typography>
            <Box display="flex" gap="1rem">
              <Typography
                variant="h1"
                mt="26px"
                color="white"
                sx={{ fontWeight: "600", fontSize: "48px" }}
              >
                {count.count}
              </Typography>
              <Box
                display="flex"
                height="20px"
                mt="40px"
                sx={{ backgroundColor: "rgba(193, 199, 205, 0.50)" }}
              >
                <ArrowUpwardIcon sx={{ color: "white" }} />
                <Typography
                  variant="h1"
                  color="white"
                  pr="4px"
                  mt="4px"
                  sx={{ fontWeight: "600", fontSize: "14px" }}
                >
                  {variance.variation ? variance.variation.toFixed(1) : 0.0} %
                </Typography>
              </Box>
            </Box>
            <Typography
              variant="h1"
              color="white"
              mt="16px"
              sx={{ fontWeight: "400", fontSize: "14px" }}
            >
              Incremento rispetto al mese precedente
            </Typography>
          </Box>
          <Box position="relative" display="flex" alignItems="center" justifyContent="right" height="calc(100% - 32px)" width="calc(100% - 32px)" p="8px 16px">
  <Button
    variant="contained"
    sx={{
      top: "96px",
      // La larghezza può essere rimossa o impostata su un valore specifico se si desidera ridurre la larghezza del bottone
      maxWidth: "fit-content", // Assicura che il bottone sia solo grande quanto il suo contenuto, più il padding
      borderRadius: '8px',
      backgroundColor: 'white',
      color: '#1B1C33',
      textTransform: 'none',
      padding: "8px 16px",
      fontWeight: 600,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: 'white',
        boxShadow: 'none'
      }
    }}
    onClick={() => navigate("/i tuoi progressi")}
  >
    Vai al dettaglio
  </Button>
</Box>
        </Box>
      </Box>
      <Box mt="30px">
        <Box display={"flex"} justifyContent="space-between">
          <Typography color="#2F1847" fontSize="24px" fontWeight="700">
            I tuoi pazienti
          </Typography>
          <Button
          
            variant="contained"
            sx={{  background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
  textTransform: 'none',
  fontWeight: 600,
  padding:"8px 16px",
  borderRadius: "8px",
  boxShadow: 'none',
  '&:hover, &:active, &:focus': {
    boxShadow: 'none'
  }
}}
            onClick={() => navigate("/pazienti")}
          >
            Vedi tutti
          </Button>
        </Box>
        {clients.data.length > 0 ? (
          <Box mt="30px">
            
            <DataGrid
              localeText={{ noRowsLabel: '' }}
              rows={allRows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              hideFooterSelectedRowCount
              disableColumnMenu
              disableRowSelectionOnClick
              sx={{
                width: "100%",
                "& .theme": {
                  backgroundColor: "rgba(70, 72, 104, 0.10)",
                  color: "#2F1847",
                  
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-cell:focus-within': {
                  outline: 'none',
                },
                '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
                  outline: 'none',
                },
              }}
            />
          </Box>
        ) : (
          <Box mt="20px">
            <Typography
              color="black"
              fontSize="18px"
              fontWeight="400"
              variant="h1"
            >
                Non ci sono ancora pazienti.{" "}
                <u><strong onClick={handleAddFirstClient}>
                  Aggiungi il tuo primo paziente
                </strong></u>
                {" "}adesso!
            </Typography>
          </Box>
        )}

        <Modal open={openModal} onClose={handleCloseModal}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: 'fixed', // Usa fixed per posizionare la modale in relazione alla viewport
              top: '45%',       // Posiziona la parte superiore della modale a metà della viewport
              left: '50%',      // Posiziona il lato sinistro della modale a metà della viewport
              transform: 'translate(-50%, -50%)', // Sposta la modale indietro di metà della sua altezza e larghezza
            }}
          >
            {selectedRow && (
                <Box
                sx={{
                  backgroundColor: "white",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  position: "relative",
                }}
              >          
                <Typography variant="h6" mb="8px">
                  Paziente: {selectedRow.surname} {selectedRow.name}
                </Typography>
          
                <Button
                  sx={{ 
                    marginRight: 2, 
                    background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
                    textTransform: 'none',
                    borderRadius: "8px",
                    fontWeight: 600,
                    padding: "8px 16px",
                    color: "white",
                    boxShadow: 'none',
                    '&:hover': {
                      boxShadow: 'none'
                    }
                  }}
                  startIcon={<AddIcon />}
                  onClick={() => handleAddVisit(selectedRow.id)}
                >
                  Aggiungi visita
                </Button>
          
                <div className="gradient-border-wrapper" style={{ marginRight: "16px"}}>
                  <Button
                    sx={{ marginRight: 2, fontWeight:"600", textTransform: "none", color: "#B0D99C"}}
                    startIcon={<EditIcon />}
                    className="gradient-button"
                  >
                    Modifica
                  </Button>
                </div>
          
                <div className="gradient-border-wrapper-r" style={{ marginRight: "16px"}}>
                  <Button
                    sx={{ marginRight: 2, fontWeight:"600", textTransform: "none", color: "red"}}
                    className="red-button"
                    startIcon={<DeleteIcon />}
                    onClick={() => handleDelete(selectedRow.id)}
                  >
                    Elimina
                  </Button>
                </div>
              </Box>
            )}
          </Box>
        </Modal>
      </Box>
      <Box mt="30px">
        <Box display={"flex"} justifyContent="space-between">
          <Typography color="#2F1847" fontSize="24px" fontWeight="700">
            Le tue ricette
          </Typography>
          <Button
            variant="contained"
            sx={{
              background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
              textTransform: 'none',
              borderRadius: "8px",
              fontWeight: 600,
              boxShadow: 'none',
              padding: "8px 16px",
              boxShadow: 'none',
              '&:hover, &:active, &:focus': {
                boxShadow: 'none'
              }
            }}
            onClick={() => navigate("/ricette")}
          >
            Vedi tutte
          </Button>
        </Box >
        {ricette.length > 0 ? (
          <Carousel
            interval={8000} // Cambia ogni 5 secondi
            duration={600} // Durata della transizione di 600ms
            sx={{ mt: "20px" }}
            indicators={false}
            navButtonsAlwaysVisible={false}
            navButtonsProps={{
              style: {
                top: "auto",
                bottom: "20px",
                right: 0, // Posiziona il bottone destro all'interno del carosello
                zIndex: 1 // Assicurati che il bottone sia sopra il contenuto del carosello
              },
            }}
          >
            {/* Map over the chunkedRicette array and render rows of Ricetta components within each slide */}
            {chunkedRicette.map((chunk, index) => (
  <div key={index} style={{ display: "flex"}}>
    {chunk.map(({ id, image, name, description, tags, nutrition_facts }) => (
      <Grid sx={{ position: "relative"}} >
        <Box sx={{ 
            mt: "20px", 
            width: "100%",
            overflow: "visible", 
            margin: "5px",
            // Opzionalmente, puoi aggiungere un'altezza ridotta se necessario
          }}>
          <Ricetta
            showBorder="no-border"
            buttonClass="gradient-button"
            key={id}
            id={id}
            name={name}
            description={description || "Nessuna descrizione"}
            image={image}
            tags={tags}
            nutrition_facts={nutrition_facts}
            showDeleteButton={false}
          />
        </Box>
      </Grid>
    ))}
  </div>
))}
          </Carousel>
        ) : (
          <Box mt="20px">
            <Typography
              color="black"
              fontSize="18px"
              fontWeight="400"
              variant="h1"
            >
              Non ci sono ancora ricette.{" "}
              <u><strong onClick={handleAddFirstRecipe}>
                Aggiungi la tua prima ricetta
              </strong></u>
              {" "}adesso!
            </Typography>
          </Box>
        )}
      </Box>
      <Box mt="50px" display="flex" gap="2rem">
  <Box
    width="50%"
    height="300px"
    pl="16px"
    pb="16px"
    sx={{ backgroundColor: "#57BCD8", borderRadius: "6px", position: 'relative' }} // Imposta position relative qui
  >
    <Box
      component="img"
      alt="logo"
      src={nutritionist_help_img}
      m="0.55rem -0.2rem"
      height="80px"
    ></Box>
    <Typography
      color="white"
      fontSize="14px"
      fontWeight="400"
      sx={{ m: 1 }}
    >
      Hai bisogno di aiuto?
    </Typography>
    <Typography
      color="white"
      fontSize="26px"
      fontWeight="700"
      sx={{ m: 1 }}
    >
      Scopri ora le guide e utilizza Dietiamo nel migliore dei modi!
    </Typography>
    <Box sx={{ position: 'absolute', bottom: '16px', left: '16px'}}>
      <Button
        variant="contained"
        color="info"
        onClick={() => navigate("/guide")}
        sx={{
          borderRadius: "8px",
          textTransform: 'none',
          fontWeight: 600,
          padding: "8px 16px",
          boxShadow: 'none',
          '&:hover, &:active, &:focus': {
            boxShadow: 'none'
          }
        }}
      >
        Scopri le guide
      </Button>
    </Box>
  </Box>
  <Box
    width="50%"
    height="300px"
    pl="16px"
    pb="16px"
    sx={{ backgroundColor: "#EDC34C", borderRadius: "6px", position: 'relative' }} // Imposta position relative qui
  >
    <Box
      component="img"
      alt="logo"
      src={referral_img}
      m="0.55rem -0.2rem"
      height="80px"
    ></Box>
    <Typography
      color="black"
      fontSize="14px"
      fontWeight="400"
      sx={{ m: 1 }}
    >
      Vuoi condividere Dietiamo con i colleghi?
    </Typography>
    <Typography
      color="black"
      fontSize="26px"
      fontWeight="700"
      sx={{ m: 1 }}
    >
      Referral: invita i tuoi colleghi nutrizionisti e ricevi degli
      sconti!
    </Typography>
    <Box sx={{ position: 'absolute', bottom: '16px', left: '16px' }}>
      <Button
        variant="contained"
        color="warning"
        onClick={() => navigate("/referral")}
        sx={{ borderRadius: "8px",
        textTransform: 'none',
fontWeight: 600,
padding:"8px 16px",
boxShadow: 'none',
'&:hover, &:active, &:focus': {
  boxShadow: 'none'
}
        }}
      >
        Vai ai referral
      </Button>
    </Box>
  </Box>
</Box>

    </Box>
  );
};

export default Dashboard;
