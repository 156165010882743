import React from "react";
import {
    Box,
    Typography
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
    whr
} from "../../utils/measurements_metrics"

const renderNumWith1Decimals = (params) => {
    if (params.value) {
        const value = Number(params.value).toFixed(1);
        return value;
    }
}

export function CircumferenceMetricsSection({ visitsData }) {
    const circumferenceRows = visitsData.data.map((item) => {
        const circumference_measurement = { ...item.circumference_measurement };
        const whr_value = circumference_measurement && circumference_measurement.waist && circumference_measurement.hips ?
            whr(circumference_measurement.waist, circumference_measurement.hips) :
            0;
        circumference_measurement["whr"] = whr_value;
        circumference_measurement["visit_date"] = new Date(item.visit_date);
        circumference_measurement["id"] = item.id;

        return circumference_measurement;
    });
    const circumferenceColumns = [
        {
            field: "visit_date",
            headerName: "Data Visita",
            type: "date",
            flex: 1,
            headerClassName: "theme",
        },
        {
            type: "number", field: "arm", headerName: "Braccio", flex: 1, headerClassName: "theme", sortable: false, valueFormatter: renderNumWith1Decimals,
        },
        {
            type: "number", field: "contracted_arm", headerName: "Braccio contratto", flex: 1.2, headerClassName: "theme", sortable: false, valueFormatter: renderNumWith1Decimals,
        },
        {
            type: "number", field: "chest", headerName: "Torace", flex: 1, headerClassName: "theme", sortable: false, valueFormatter: renderNumWith1Decimals,
        },
        {
            type: "number", field: "waist", headerName: "Vita", headerClassName: "theme", sortable: false, valueFormatter: renderNumWith1Decimals,
        },
        {
            type: "number", field: "abdomen", headerName: "Addome", flex: 1, headerClassName: "theme", sortable: false, valueFormatter: renderNumWith1Decimals,
        },
        {
            type: "number", field: "hips", headerName: "Fianchi", flex: 1, headerClassName: "theme", sortable: false, valueFormatter: renderNumWith1Decimals,
        },
        {
            type: "number", field: "thigh", headerName: "Coscia", flex: 1, headerClassName: "theme", sortable: false, valueFormatter: renderNumWith1Decimals,
        },
        {
            type: "number", field: "calf", headerName: "Polpaccio", flex: 1, headerClassName: "theme", sortable: false, valueFormatter: renderNumWith1Decimals,
        },
        {
            type: "number", field: "neck", headerName: "Collo", flex: 1, headerClassName: "theme", sortable: false, valueFormatter: renderNumWith1Decimals,
        },
        {
            type: "number", field: "wrist", headerName: "Polso", flex: 1, headerClassName: "theme", sortable: false, valueFormatter: renderNumWith1Decimals
        },
        {
            type: "number", field: "whr", headerName: "WHR", flex: 1, headerClassName: "theme", sortable: false, valueFormatter: renderNumWith1Decimals
        },
    ];
    return (
        <Box mt="32px" ml="-18px">
            <Box mb="20px">
                <Typography color="#2F1847" fontSize="24px" fontWeight="700">
                    Circonferenze
                </Typography>
            </Box>
            <Box mt="10px" ml="-18px" pl={3}>
                <DataGrid
                    rows={circumferenceRows.sort((a, b) => new Date(b.visit_date) - new Date(a.visit_date)).filter((elem) =>
                        !!elem["arm"] |
                        !!elem["contracted_arm"] |
                        !!elem["chest"] |
                        !!elem["waist"] |
                        !!elem["abdomen"] |
                        !!elem["hips"] |
                        !!elem["thigh"] |
                        !!elem["calf"] |
                        !!elem["neck"] |
                        !!elem["wrist"]
                    )}
                    columns={circumferenceColumns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5,
                            },
                        },
                    }}
                    pageSizeOptions={[5]}
                    sx={{
                        "& .theme": {
                            backgroundColor: "rgba(70, 72, 104, 0.10)",
                            color: "#2F1847",
                        },
                    }}
                    hideFooterSelectedRowCount
                    disableColumnMenu
                    disableRowSelectionOnClick
                />
            </Box>
        </Box>
    );
};

