// webpack.config.js
import React from "react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Typography,
} from "@mui/material";
import {
  SettingsOutlined,
  ChevronLeft,
  ChevronRightOutlined,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import { ReactComponent as dashboardI } from "../assets/icons/home.svg";
import { ReactComponent as clientiI } from "../assets/icons/folder.svg";
import { ReactComponent as ricetteI } from "../assets/icons/document-text.svg";
import { ReactComponent as itpI } from "../assets/icons/graph.svg";
import { ReactComponent as guideI } from "../assets/icons/heart-search.svg";
import { ReactComponent as referralI } from "../assets/icons/share.svg";
import { ReactComponent as aiutoI } from "../assets/icons/message-text.svg";
import profileImage from "../assets/icons/logo.png";
import logoDiet from "../assets/icons/logodiet.svg";
import EmailModal from "./EmailModal";


const navItems = [
  {
    text: "Dashboard",
    icon: <SvgIcon component={dashboardI} inheritViewBox />,
  },
  {
    text: "Pazienti",
    icon: <SvgIcon component={clientiI} inheritViewBox />,
  },
  {
    text: "Ricette",
    icon: <SvgIcon component={ricetteI} inheritViewBox />,
  },
  {
    text: "I tuoi progressi",
    icon: <SvgIcon component={itpI} inheritViewBox />,
  },
  {
    text: "Guide",
    icon: <SvgIcon component={guideI} inheritViewBox />,
  },

  {
    text: "Referral",
    icon: <SvgIcon component={referralI} inheritViewBox />,
  },
  {
    text: "Aiuto",
    icon: <SvgIcon component={aiutoI} inheritViewBox />,
  },
];

const insertDividerAfter = (array, index) => {
  return [
    ...array.slice(0, index + 1),
    'divider', // Inserisci un elemento 'divider' dopo l'indice specificato
    ...array.slice(index + 1)
  ];
};

// Trova l'indice di "Guide" in navItems
const guideIndex = navItems.findIndex(item => item.text === 'Guide');
// Inserisci il Divider dopo "Guide"
const updatedNavItems = insertDividerAfter(navItems, guideIndex);



const Sidebar = ({
  user,
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  const isSubscriptionValid = () => {

    const currentDate = new Date();
    const currentMs = currentDate.getTime();

    const targetDate = new Date(user.subscription_plan_expiration);
    const targetMs = targetDate.getTime();

    return targetMs > currentMs
  }
  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: "#2F1847",
              backgroundColor: "none",
              boxSizing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
              boxShadow: "8px 8px 10px 0px rgba(47, 24, 71, 0.04)",
            },
          }}
        >
          <Box width="100%" sx={{ marginBottom: "24px", }}>
            <Box m="1.5rem 2rem 2rem 3rem">
              <FlexBetween color="red">
                <Box
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  component="img"
                  alt="logo"
                  src={logoDiet}
                />
                {!isNonMobile && (
                  <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>
            {isSubscriptionValid() && 
            <List>
              
            {updatedNavItems.map((item, index) => {
              if (item === 'divider') {
                return <Divider
                  sx={{
                    marginTop: "32px",
                    marginBottom: "32px",
                    marginLeft: "45px",
                    width: "70%",
                    opacity: "60%",
                    bgcolor: "transparent", // Imposta il colore di sfondo su trasparente
                    border: 0, // Rimuove il bordo predefinito
                    borderTop: "1px dashed rgba(193, 199, 205, 0.50)", // Imposta il bordo superiore come tratteggiato
                  }}
                  key={'divider-' + index}
                />;
              }

              // Estrae il testo e l'icona dell'elemento corrente
              const { text, icon } = item;
              const lcText = text.toLowerCase();

                if (text === "Aiuto") {
                  return (
                    <ListItem key={text} disablePadding>
                      <ListItemButton
                        onClick={() => {
                          setActive(lcText);
                          setOpen(true);
                        }}
                        sx={{
                          backgroundColor:
                            decodeURIComponent(active) === lcText ? "white" : "transparent",
                          color: decodeURIComponent(active) === lcText ? "black" : "#2F1847",
                          typography: "Geomanist",
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            ml: "2rem",
                            mr: "1rem",
                            color: decodeURIComponent(active) === lcText ? "white" : "red",
                            minWidth: "24px"
                          }}
                        >
                          {icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={text}
                          sx={{
                            fontWeight: decodeURIComponent(active) === lcText ? 700 : 'inherit', // Change fontWeight based on the condition
                            '& .MuiTypography-root': {
                              fontWeight: decodeURIComponent(active) === lcText ? 700 : 'inherit', // Ensure the Typography component's styles are overridden
                            }
                          }}
                        />
                        {/* {decodeURIComponent(active) === lcText && (
                          <ChevronRightOutlined sx={{ ml: "auto" }} />
                        )} */}
                      </ListItemButton>
                    </ListItem>
                  );
                }

                return (
                  <ListItem key={text} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        navigate(`/${lcText}`);
                        setActive(lcText);
                      }}
                      sx={{
                        backgroundColor:
                          decodeURIComponent(active) === lcText ? "white" : "transparent",
                        color: decodeURIComponent(active) === lcText ? "black" : "#2F1847",
                        // typography: "Geomanist",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          ml: "2rem",
                          mr: "1rem",
                          color: decodeURIComponent(active) === lcText ? "white" : "red",
                          minWidth: "24px"
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={text}
                        sx={{
                          fontWeight: decodeURIComponent(active) === lcText ? 700 : 'inherit', // Change fontWeight based on the condition
                          '& .MuiTypography-root': {
                            fontWeight: decodeURIComponent(active) === lcText ? 700 : 'inherit', // Ensure the Typography component's styles are overridden
                          }
                        }}
                      />
                      {/* {decodeURIComponent(active) === lcText && (
                        <ChevronRightOutlined sx={{ ml: "auto" }} />
                      )} */}
                    </ListItemButton>
                  </ListItem>
                );
              })}
              </List>}
          </Box>
          <Box position="sticky" bgcolor="white">
            
          <Divider       sx={{
        marginBottom: "32px",
        marginLeft: "45px",
        width: "70%",
        opacity: "60%",
        bgcolor: "transparent", // Imposta il colore di sfondo su trasparente
        border: 0, // Rimuove il bordo predefinito
        borderTop: "1px dashed rgba(193, 199, 205, 0.50)", // Imposta il bordo superiore come tratteggiato
      }}
        />
            <FlexBetween textTransform="none" gap="1rem" m="1.5rem 0.5rem 0 2rem">
              <Box
                component="img"
                alt="profile"
                src={
                  user.profile_picture != null
                    ? user.profile_picture
                    : profileImage
                }
                height="60px"
                width="60px"
                borderRadius="50%"
                sx={{ objectFit: "cover" }}
              />
              <Box textAlign="left">
                <Typography
                  fontWeight="bold"
                  fontSize="0.8rem"
                  sx={{ color: "#21272A" }}
                >
                  {user.gender === "male" ? "Dott." : user.gender === "female" ? "Dott.ssa" : ""} {user.name} {user.surname}
                </Typography>
                <Typography
                  sx={{
                    color: "#697077",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/profile");
                    setActive("/profile");
                  }}
                >
                  Vai al profilo
                </Typography>
              </Box>
              <SettingsOutlined sx={{ color: "white", fontSize: "25px" }} />
            </FlexBetween>
          </Box>
        </Drawer>
      )}
      <EmailModal
        title={"Manda un'email al team"}
        subTitle={"Se hai bisogno di aiuto, inviaci un'email per darci la possibilità di migliorare la Nutriboard."}
        type={"help"}
        open={open}
        handleClose={handleClose}
      >
      </EmailModal>
    </Box>
  );
};

export default Sidebar;
