import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Divider,
  Autocomplete,
  Chip,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Modal,
  TextareaAutosize,
  Popover
} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Check from "@mui/icons-material/Check";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  useGetVisitsDetailsQuery,
  useGetVisitsQuery,
  usePostVisitMutation,
} from "../../redux/state/api";
import { useGetIngredientsQuery } from "../../redux/state/api";
import { useLocation } from "react-router-dom";
import { useGetClientDetailsQuery } from "../../redux/state/api";
import IngredientSearchBar from "../../components/IngredientSearchBar";
import plicheIcon from '../../assets/pliche.svg';
import circoIcon from '../../assets/circonferenze.svg';
import biaIcon from '../../assets/bia.svg';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

const QontoStepIcon = (props) => {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
};

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const Step1Content = ({
  visitData,
  handleChangeLike,
  handleChangeNotLike,
  clientData,
  handleChangeFoodProfile,
  handleChangePathologies,
  handleChangeClinicalHistory,
  handleChangeDrugsAndSupplements,
  handleChangeMenstruation,
  handleChangeSleepHours,
  handleChangeHabits,
  handleChangeBowel,
  handleChangeSport,
  handleChangeWeeklyTrainingHours,
  handleChangeActivitiesLevel,
  handleChangeSmoker,
  handleChangeNotes,
}) => {
  const [fileList, setFileList] = useState([]);
  const [textLike, setTextLike] = useState("");
  const [textNotLike, setTextNotLike] = useState("");

  const { data: ingredientsLike, isLoading: isIngredientesLikeLoading } =
    useGetIngredientsQuery(textLike.toLowerCase());
  const { data: ingredientsNotLike, isLoading: isIngredientesNotLikeLoading } =
    useGetIngredientsQuery(textNotLike.toLowerCase());
  const handleChange = ({ file, fileList }) => {
    setFileList(fileList);
  };

  const handleUpload = () => {
    if (fileList.length > 0) {
      message.success("FIle caricato con successo.");
      setFileList([]);
    } else {
      message.warning("Seleziona un file da caricare.");
    }
  };

  const sports = [
    { name: "american_football" },
    { name: "archery" },
    { name: "athletics" },
    { name: "badminton" },
    { name: "baseball" },
    { name: "basketball" },
    { name: "beach_volleyball" },
    { name: "boxing" },
    { name: "canoeing" },
    { name: "cricket" },
    { name: "curling" },
    { name: "cycling" },
    { name: "diving" },
    { name: "equestrianism" },
    { name: "fencing" },
    { name: "field_hockey" },
    { name: "golf" },
    { name: "gym" },
    { name: "gymnastics" },
    { name: "handball" },
    { name: "ice_hockey" },
    { name: "judo" },
    { name: "karate" },
    { name: "kayaking" },
    { name: "lacrosse" },
    { name: "martial_arts" },
    { name: "motocross" },
    { name: "netball" },
    { name: "paddle_tennis" },
    { name: "rugby" },
    { name: "sailing" },
    { name: "skateboarding" },
    { name: "skiing" },
    { name: "snowboarding" },
    { name: "soccer" },
    { name: "surfing" },
    { name: "swimming" },
    { name: "table_tennis" },
    { name: "tennis" },
    { name: "triathlon" },
    { name: "volleyball" },
    { name: "water_polo" },
    { name: "weightlifting" },
    { name: "wrestling" },
  ];

  if (isIngredientesLikeLoading || isIngredientesNotLikeLoading) {
    return <div class="loader-container">
      <div class="loader"></div>
    </div>
      ;
  }

  return (
    <Box>
      <Header title="Creazione visita" />

      <Typography
        color="#2F1847"
        fontSize="24px"
        fontWeight="700"
        variant="h1"
        sx={{ mt: "20px" }}
      >
        Anamnesi
      </Typography>
      <Box mt="20px" display="flex" sx={{ flexDirection: "column" }}>
        <Box display="flex" ml="-5px" sx={{ flexDirection: "row" }}>
          <FormControl
            variant="filled"
            sx={{ m: 1, width: "50%" }}
          >
            <InputLabel id="profilo-alimentare-select-label" color="secondary">
              Profilo alimentare
            </InputLabel>
            <Select
              labelId="profilo-alimentare-select-label"
              id="selectProfiloAlimentare"
              label="Profilo alimentare"
              value={visitData.anamnesis.food_profile}
              onChange={(event) => handleChangeFoodProfile(event)}
              color="secondary"
            >
              <MenuItem value={"omnivorous"}>Onnivoro</MenuItem>
              <MenuItem value={"vegan"}>Vegano</MenuItem>
              <MenuItem value={"vegetarian"}>Vegetariano</MenuItem>
              <MenuItem value={"ovo_vegetarian"}>Ovo Vegetariano</MenuItem>
              <MenuItem value={"lacto_vegetarian"}>Lacto Vegetariano</MenuItem>
              <MenuItem value={"semi_vegetarian"}>Semi Vegetariano</MenuItem>
              <MenuItem value={"paleo"}>Paleo</MenuItem>
              <MenuItem value={"fruitarian"}>Fruttariano</MenuItem>
              <MenuItem value={"raw_food"}>Crudariano</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box display="flex" ml="-5px" sx={{ flexDirection: "row" }}>
          <FormControl
            variant="standard"
            sx={{ m: 1, width: "50%" }}
          >
            <TextField
              id="patologie"
              label="Patologie"
              variant="filled"
              color="secondary"
              value={visitData.anamnesis.pathologies}
              onChange={(event) => handleChangePathologies(event)}
            />
          </FormControl>
          <Box mt="15px">
            <Upload
              fileList={fileList}
              onChange={handleChange}
              beforeUpload={() => false}
            >
              <Button color="secondary" startIcon={<UploadOutlined />}>
                Carica le analisi
              </Button>
            </Upload>
            {fileList.length !== 0 ? (
              <Button
                color="secondary"
                onClick={handleUpload}
                disabled={fileList.length === 0}
              >
                Upload
              </Button>
            ) : (
              ""
            )}
          </Box>
        </Box>
        <Box display="flex" ml="-5px" sx={{ flexDirection: "row" }}>
          <FormControl
            variant="standard"
            sx={{ m: 1, width: "50%" }}
          >
            <TextField
              id="storiaClinica"
              label="Storia clinica (allergie, intolleranze, ...)"
              variant="filled"
              color="secondary"
              value={visitData.anamnesis.clinical_history}
              onChange={(event) => handleChangeClinicalHistory(event)}
            />
          </FormControl>
          <FormControl variant="standard" sx={{ m: 1, width: "35%" }}>
            <TextField
              id="farmaci"
              label="Farmaci ed integratori"
              variant="filled"
              color="secondary"
              value={visitData.anamnesis.drugs_and_supplements}
              onChange={(event) => handleChangeDrugsAndSupplements(event)}
            />
          </FormControl>
        </Box>
        {clientData.gender === "female" ? (
          <Box display="flex" ml="-5px" sx={{ flexDirection: "row" }}>
            <FormControl
              variant="filled"
              sx={{ m: 1, width: "50%" }}
            >
              <InputLabel id="ciclo-select-label" color="secondary">
                CICLO
              </InputLabel>
              <Select
                labelId="ciclo-select-label"
                id="selectCiclo"
                label="CICLO"
                value={visitData.anamnesis.menstruation}
                onChange={(event) => handleChangeMenstruation(event)}
                color="secondary"
              >
                <MenuItem value={"irregular"}>Irregolare</MenuItem>
                <MenuItem value={"regular"}>Regolare</MenuItem>
                <MenuItem value={"amenorrhea"}>Amenorrea</MenuItem>
                <MenuItem value={"menopause"}>Menopausa</MenuItem>
              </Select>
            </FormControl>
          </Box>
        ) : (
          ""
        )}

        <Divider
          sx={{
            marginTop: "32px",
            width: "100%",
            bgcolor: "transparent", // Imposta il colore di sfondo su trasparente
            border: 0, // Rimuove il bordo predefinito
            borderTop: "1px dashed rgba(193, 199, 205, 0.50)", // Imposta il bordo superiore come tratteggiato
          }}
        />
        <Typography
          color="#2F1847"
          fontSize="18px"
          fontWeight="700"
          variant="h1"
          sx={{ mt: "50px" }}
        >
          Stile di vita
        </Typography>
        <Box display="flex" ml="-5px" sx={{ flexDirection: "row" }}>
          <FormControl
            variant="standard"
            sx={{ m: 1, width: "50%" }}
          >
            <TextField
              id="orediSonno"
              label="Ore di sonno"
              variant="filled"
              color="secondary"
              type="number"
              value={visitData.anamnesis.sleep_hours}
              onChange={(event) => handleChangeSleepHours(event)}
            />
          </FormControl>
          <FormControl variant="standard" sx={{ m: 1, width: "50%" }}>
            <TextField
              id="abitudini"
              label="Abitudini"
              variant="filled"
              color="secondary"
              value={visitData.anamnesis.habits}
              onChange={(event) => handleChangeHabits(event)}
            />
          </FormControl>
          <FormControl variant="filled" sx={{ m: 1, width: "50%" }}>
            <InputLabel id="alvo-select-label" color="secondary">
              Alvo
            </InputLabel>
            <Select
              labelId="alvo-select-label"
              id="selectAlvo"
              label="Alvo"
              value={visitData.anamnesis.bowel}
              onChange={(event) => handleChangeBowel(event)}
              color="secondary"
            >
              <MenuItem value={"irregular"}>Irregolare</MenuItem>
              <MenuItem value={"regular"}>Regolare</MenuItem>
              <MenuItem value={"constipated"}>Constipato</MenuItem>
              <MenuItem value={"diarrheal"}>Diarrea</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box display="flex" ml="-5px" sx={{ flexDirection: "row" }}>
          <FormControl
            variant="standard"
            sx={{ m: 1, width: "50%" }}
          >
            <Autocomplete
              value={
                visitData.anamnesis.sports != null
                  ? visitData.anamnesis.sports
                  : []
              }
              multiple
              id="sport"
              options={sports.map((option) => option.name)}
              freeSolo
              onChange={handleChangeSport}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    style={{
                      backgroundColor: 'rgba(70, 72, 104, 1)',
                      color: "white",
                      fontWeight: "500",
                      borderRadius: "4px"
                    }}
                    color="info"
                    variant="filled"
                    label={option}
                    size="small"
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  label="Sport"
                  color="secondary"
                />
              )}
            />
          </FormControl>
          <FormControl variant="standard" sx={{ m: 1, width: "50%" }}>
            <TextField
              id="oreSettimanalidiSport"
              label="Ore settimanali di sport"
              variant="filled"
              color="secondary"
              type="number"
              value={visitData.anamnesis.weekly_training_hours}
              onChange={(event) => handleChangeWeeklyTrainingHours(event)}
            />
          </FormControl>
          <FormControl variant="filled" sx={{ m: 1, width: "50%" }}>
            <InputLabel
              id="livello-attività-fisica-select-label"
              color="secondary"
            >
              Livello di attività fisica
            </InputLabel>
            <Select
              labelId="livello-attività-fisica-select-label"
              id="selectLivello"
              label="Livello di attività fisica"
              value={visitData.anamnesis.activities_level}
              onChange={(event) => handleChangeActivitiesLevel(event)}
              color="secondary"
            >
              <MenuItem value={"low"}>Basso</MenuItem>
              <MenuItem value={"medium"}>Medio</MenuItem>
              <MenuItem value={"high"}>Alto</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <FormControl
          variant="standard"
          sx={{ m: 1, width: "50%" }}
          color="secondary"
        >
          <FormLabel id="fumatore">Fumatore</FormLabel>
          <RadioGroup
            row
            aria-labelledby="fumatore"
            name="row-radio-buttons-group"
            value={visitData.anamnesis.smoker ? "Si" : "No"}
            onChange={(event) => handleChangeSmoker(event)}
          >
            {" "}
            <FormControlLabel
              value="Si"
              control={<Radio color="secondary" />}
              label="Si"
            />
            <FormControlLabel
              value="No"
              control={<Radio color="secondary" />}
              label="No"
            />{" "}
          </RadioGroup>
        </FormControl>
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          marginLeft: "-5px",
          width: "100%",
          bgcolor: "transparent", // Imposta il colore di sfondo su trasparente
          border: 0, // Rimuove il bordo predefinito
          borderTop: "1px dashed rgba(193, 199, 205, 0.50)", // Imposta il bordo superiore come tratteggiato
        }}
      />
      <Typography
        color="#2F1847"
        fontSize="18px"
        fontWeight="700"
        variant="h1"
        sx={{ mt: "50px" }}
      >
        Note
      </Typography>
      <Box display="flex" sx={{ flexDirection: "row" }}>
        <FormControl variant="standard" sx={{ mt: 1, minWidth: "50%" }}>
          <TextareaAutosize
            minRows={5}
            placeholder="Aggiungi qui le tue note"
            variant="filled"
            color="secondary"
            value={visitData.anamnesis.notes}
            onChange={(event) => handleChangeNotes(event)}
          />
        </FormControl>
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          marginLeft: "-5px",
          width: "100%",
          bgcolor: "transparent", // Imposta il colore di sfondo su trasparente
          border: 0, // Rimuove il bordo predefinito
          borderTop: "1px dashed rgba(193, 199, 205, 0.50)", // Imposta il bordo superiore come tratteggiato
        }}
      />
      <Typography
        color="#2F1847"
        fontSize="24px"
        fontWeight="700"
        variant="h1"
        sx={{ mt: "20px" }}
      >
        Alimenti
      </Typography>
      <Box mt="20px">
        <IngredientSearchBar
          value={visitData.anamnesis.like}
          multiple
          id="likedIngredients"
          options={ingredientsLike.map((option) => option)}
          getOptionLabel={(option) => `${option.simple_name_it} ${option.specific_info ? option.specific_info : ""}`}
          onInputChange={(event, newInputValue) => setTextLike(newInputValue)}
          onChange={(event, newValue) => handleChangeLike(newValue)}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                style={{
                  backgroundColor: 'rgba(70, 72, 104, 1)',
                  color: "white",
                  fontWeight: "500",
                  borderRadius: "4px"
                }}
                color="info"
                variant="filled"
                label={`${option.simple_name_it} ${option.specific_info ? option.specific_info : ""}`}
                size="small"
                {...getTagProps({ index })}
              />
            ))
          }
          label="Preferiti"
          color="secondary"
        />
      </Box>

      <Box mt="20px">
        <IngredientSearchBar
          value={visitData.anamnesis.not_like}
          multiple
          id="notLikedIngredients"
          options={ingredientsNotLike.map((option) => option)}
          getOptionLabel={(option) => `${option.simple_name_it} ${option.specific_info ? option.specific_info : ""}`}
          onInputChange={(event, newInputValue) => setTextNotLike(newInputValue)}
          onChange={(event, newValue) => handleChangeNotLike(newValue)}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                style={{
                  backgroundColor: 'rgba(70, 72, 104, 1)',
                  color: "white",
                  fontWeight: "500",
                  borderRadius: "4px"
                }}
                color="info"
                variant="filled"
                label={`${option.simple_name_it} ${option.specific_info ? option.specific_info : ""}`}
                size="small"
                {...getTagProps({ index })}
              />
            ))
          }
          label="Da evitare"
          color="secondary"
        />
      </Box>

    </Box>
  );
};
const Step2Content = ({
  visitData,
  handleChangeWeight,
  handleChangeHeight,
  handleChangeResistance,
  handleChangeReactance,
  handleChangeWrist,
  handleChangeArm,
  handleChangeArmContracted,
  handleChangeNeck,
  handleChangeChest,
  handleChangeWaist,
  handleChangeAbdomen,
  handleChangeHips,
  handleChangeThigh,
  handleChangeCalf,
  handleChangeBicipital,
  handleChangeTricipital,
  handleChangeSubScapular,
  handleChangeMidAx,
  handleChangePectoral,
  handleChangeMidCl,
  handleChangeAntAx,
  handleChangeAbdominal,
  handleChangeThigh2,
  handleChangeCalf2,
  saveBiaMeasurement,
  saveCircumferenceMeasurement,
  saveSkinfoldMeasurement,
  setSaveBiaMeasurement,
  setSaveCircumferenceMeasurement,
  setSaveSkinfoldMeasurement
}) => {
  const [openModal1, setOpenModal1] = useState(false);
  const [disabled1, setDisabled1] = useState(false);

  const handleCloseModal1 = () => {
    setOpenModal1(false);
    setDisabled1(false);
  };

  const handleOpenModal1 = () => {
    setOpenModal1(true);
    setDisabled1(false);
  };

  const handleAdd1 = () => {
    setOpenModal1(false);
    setSaveBiaMeasurement(true);
    setDisabled1(false);
  };


  const [openModal2, setOpenModal2] = useState(false);
  const [disabled2, setDisabled2] = useState(false);

  const handleCloseModal2 = () => {
    setOpenModal2(false);
    setDisabled2(false);
  };

  const handleOpenModal2 = () => {
    setOpenModal2(true);
    setDisabled2(false);
  };
  const handleAdd2 = () => {
    setOpenModal2(false);
    setSaveCircumferenceMeasurement(true);
    setDisabled2(false);
  };


  const [openModal3, setOpenModal3] = useState(false);
  const [disabled3, setDisabled3] = useState(false);

  const handleCloseModal3 = () => {
    setOpenModal3(false);
    setDisabled3(false);
  };

  const handleOpenModal3 = () => {
    setOpenModal3(true);
    setDisabled3(false);
  };

  const handleAdd3 = () => {
    setOpenModal3(false);
    setSaveSkinfoldMeasurement(true);
    setDisabled3(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: 600,
    bgcolor: "background.paper",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    p: 4,
    overflowX: "hidden",
  };
  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: 800,
    bgcolor: "background.paper",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    p: 4,
    overflowX: "hidden",
  };

  return (
    <Box>
      <Header title="Creazione visita" />

      <Typography
        color="#2F1847"
        fontSize="24px"
        fontWeight="700"
        variant="h1"
        sx={{ mt: "20px" }}
      >
        Informazioni di base
      </Typography>
      <Box mt="20px" display="flex">
        <FormControl
          variant="standard"
          sx={{ m: 1, minWidth: 400, ml: "-5px" }}
        >
          <TextField
            id="peso"
            label="Peso (kg)"
            variant="filled"
            color="secondary"
            type="number"
            value={visitData.measurement.weight ? visitData.measurement.weight : ''}
            onChange={handleChangeWeight}
            required
            error={!visitData.measurement.weight}
          />
        </FormControl>
        <FormControl variant="standard" sx={{ m: 1, width: "30%" }}>
          <TextField
            id="altezza"
            label="Statura (cm)"
            variant="filled"
            color="secondary"
            type="number"
            value={visitData.measurement.height ? visitData.measurement.height : ''}
            onChange={handleChangeHeight}
            required
            error={!visitData.measurement.height}
          />
        </FormControl>
        <Box sx={{
          borderRadius: "8px",
          border: "1px solid rgba(193, 199, 205, 1)",
          m: 1,
          width: "8%",
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Typography
            color="#2F1847"
            fontSize="20px"
            fontWeight="600"
          >
            BMI:
          </Typography>
          <Typography
            ml="5px"
            mt="-2px"
            color="#2F1847"
            fontSize="20px"
            fontWeight="400"
          >
            {` ${(visitData.measurement.weight / ((visitData.measurement.height / 100) * (visitData.measurement.height / 100))).toFixed(1)}`}
          </Typography>
        </Box>
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          width: "100%",
          bgcolor: "transparent", // Imposta il colore di sfondo su trasparente
          border: 0, // Rimuove il bordo predefinito
          borderTop: "1px dashed rgba(193, 199, 205, 0.50)", // Imposta il bordo superiore come tratteggiato
        }}
      />
      <Typography
        color="#2F1847"
        fontSize="24px"
        fontWeight="700"
        variant="h1"
        sx={{ mt: "50px" }}
      >
        Informazioni aggiuntive
      </Typography>
      <Box display="flex" ml="-5px" mt="25px" gap="3rem" width="100%">
        <Box
          width="30%"
          height="10%"
          sx={{
            borderRadius: "6px",
            border: "1px solid rgba(193, 199, 205, 1)",
          }}
        >
          <Box m="16px">
          <Box>
              <img src={biaIcon} alt="Bioimpedenziometria" />
              </Box>
            <Typography
              color="#2f1847"
              fontSize="28px"
              fontWeight="700"
              variant="h1"
              sx={{ mt: "20px" }}
            >
              BIA
            </Typography>
            <Typography
              color="#2f1847"
              fontSize="16px"
              fontWeight="400"
              variant="body2"
            >
              Aggiungi i valori di resistenza e reattanza.
            </Typography>
            {visitData.bia_measurement ? (
              <Box
                display="flex"
                sx={{ justifyContent: "flex-end", ml: "-5px", mt: "25px" }}
              >
                <div className="gradient-border-wrapper">
                  <Button
                    sx={{
                      color: "linear-gradient(to top right, #B0D99C, #68BE6A)",
                      fontWeight: 600, textTransform: 'none'
                    }}
                    className="gradient-button"

                    onClick={handleOpenModal2}
                  >
                    Modifica
                  </Button>
                </div>
              </Box>
            ) : (
              <Box
                display="flex"
                sx={{ justifyContent: "flex-end", ml: "-5px", mt: "25px" }}
              >
                <div className="gradient-border-wrapper">
                  <Button
                    sx={{
                      color: "linear-gradient(to top right, #B0D99C, #68BE6A)",
                      fontWeight: 600, textTransform: 'none'
                    }}
                    className="gradient-button"
                    onClick={handleOpenModal2}
                  >
                    Aggiungi
                  </Button>
                </div>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          width="30%"
          height="10%"
          sx={{
            borderRadius: "6px",
            border: "1px solid rgba(193, 199, 205, 1)",
          }}
        >
          <Box m="16px">
          <Box>
              <img src={circoIcon} alt="Circonferenze" />
              </Box>
            <Typography
              color="#2f1847"
              fontSize="28px"
              fontWeight="700"
              variant="h1"
              sx={{ mt: "20px" }}
            >
              Circonferenze
            </Typography>
            <Typography
              color="#2f1847"
              fontSize="16px"
              fontWeight="400"
              variant="body2"
            >
              Aggiungi le misure delle circonferenze.
            </Typography>
            {visitData.circumference_measurement ? (
              <Box
                display="flex"
                sx={{ justifyContent: "flex-end", ml: "-5px", mt: "25px" }}
              >
                <div className="gradient-border-wrapper">
                  <Button
                    sx={{
                      color: "linear-gradient(to top right, #B0D99C, #68BE6A)",
                      fontWeight: 600, textTransform: 'none'
                    }}
                    className="gradient-button"
                    onClick={handleOpenModal1}
                  >
                    Modifica
                  </Button>
                </div>
              </Box>
            ) : (
              <Box
                display="flex"
                sx={{ justifyContent: "flex-end", ml: "-5px", mt: "25px" }}
              >
                <div className="gradient-border-wrapper">
                  <Button
                    sx={{
                      color: "linear-gradient(to top right, #B0D99C, #68BE6A)",
                      fontWeight: 600, textTransform: 'none'
                    }}
                    className="gradient-button"
                    onClick={handleOpenModal1}
                  >
                    Aggiungi
                  </Button>
                </div>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          width="30%"
          height="10%"
          sx={{
            borderRadius: "6px",
            border: "1px solid rgba(193, 199, 205, 1)",
          }}
        >
          <Box m="16px">
          <Box>
              <img src={plicheIcon} alt="Pliche" />
              </Box>
            <Typography
              color="#2f1847"
              fontSize="28px"
              fontWeight="700"
              variant="h1"
              sx={{ mt: "20px" }}
            >
              Pliche
            </Typography>
            <Typography
              color="#2f1847"
              fontSize="16px"
              fontWeight="400"
              variant="body2"
            >
              Aggiungi le misure delle pliche.
            </Typography>
            {visitData.skinfold_measurement ? (
              <Box
                display="flex"
                sx={{ justifyContent: "flex-end", ml: "-5px", mt: "25px" }}
              >
                <div className="gradient-border-wrapper">
                  <Button
                    sx={{
                      color: "linear-gradient(to top right, #B0D99C, #68BE6A)",
                      fontWeight: 600, textTransform: 'none'
                    }}
                    className="gradient-button"
                    onClick={handleOpenModal3}
                  >
                    Modifica
                  </Button>
                </div>
              </Box>
            ) : (
              <Box
                display="flex"
                sx={{ justifyContent: "flex-end", ml: "-5px", mt: "25px" }}
              >
                <div className="gradient-border-wrapper">
                  <Button
                    sx={{
                      color: "linear-gradient(to top right, #B0D99C, #68BE6A)",
                      fontWeight: 600, textTransform: 'none'
                    }}
                    className="gradient-button"
                    onClick={handleOpenModal3}
                  >
                    Aggiungi
                  </Button>
                </div>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Modal open={openModal1} onClose={handleCloseModal1}>
        <Box sx={style2}>
          <Box width="100%" height="5%">
            <Typography
              variant="h1"
              color="#2F1847"
              sx={{ fontWeight: "400", fontSize: "14px" }}
            >
              {`Visita del ${new Date().toLocaleDateString()}`}
            </Typography>
          </Box>
          <Box width="100%" height="10%">
            <Typography
              variant="h1"
              color="#2F1847"
              sx={{ fontWeight: "700", fontSize: "32px" }}
            >
              Circonferenze
            </Typography>
          </Box>
          <Box width="100%" height="5%" mt="5px">
            <Typography
              variant="h1"
              color="#2F1847"
              sx={{ fontWeight: "700", fontSize: "24px" }}
            >
              Parte superiore
            </Typography>
          </Box>
          <Box display="flex" ml="-10px">
            <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
              <TextField
                id="polso"
                label="Polso (cm)"
                variant="filled"
                color="secondary"
                type="number"
                disabled={disabled1}
                onChange={handleChangeWrist}
                defaultValue={
                  visitData.circumference_measurement
                    ? visitData.circumference_measurement.wrist
                    : ""
                }
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
              <TextField
                id="braccio"
                label="Braccio (cm)"
                variant="filled"
                color="secondary"
                type="number"
                disabled={disabled1}
                onChange={handleChangeArm}
                defaultValue={
                  visitData.circumference_measurement
                    ? visitData.circumference_measurement.arm
                    : ""
                }
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Box>
          <Box display="flex" ml="-10px">
            <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
              <TextField
                id="braccioContratto"
                label="Braccio contratto (cm)"
                variant="filled"
                color="secondary"
                type="number"
                disabled={disabled1}
                onChange={handleChangeArmContracted}
                defaultValue={
                  visitData.circumference_measurement
                    ? visitData.circumference_measurement.contracted_arm
                    : ""
                }
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
              <TextField
                id="collo"
                label="Collo (cm)"
                variant="filled"
                color="secondary"
                type="number"
                disabled={disabled1}
                onChange={handleChangeNeck}
                defaultValue={
                  visitData.circumference_measurement
                    ? visitData.circumference_measurement.neck
                    : ""
                }
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Box>
          <Box display="flex" ml="-10px">
            <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
              <TextField
                id="petto"
                label="Torace (cm)"
                variant="filled"
                color="secondary"
                type="number"
                disabled={disabled1}
                onChange={handleChangeChest}
                defaultValue={
                  visitData.circumference_measurement
                    ? visitData.circumference_measurement.chest
                    : ""
                }
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Box>
          <Box width="100%" height="5%" mt="40px">
            <Typography
              variant="h1"
              color="#2F1847"
              sx={{ fontWeight: "700", fontSize: "24px" }}
            >
              Parte inferiore
            </Typography>
          </Box>
          <Box display="flex" ml="-10px">
            <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
              <TextField
                id="girovita"
                label="Vita (cm)"
                variant="filled"
                color="secondary"
                type="number"
                disabled={disabled1}
                onChange={handleChangeWaist}
                defaultValue={
                  visitData.circumference_measurement
                    ? visitData.circumference_measurement.waist
                    : ""
                }
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
              <TextField
                id="addome"
                label="Addome (cm)"
                variant="filled"
                color="secondary"
                type="number"
                disabled={disabled1}
                onChange={handleChangeAbdomen}
                defaultValue={
                  visitData.circumference_measurement
                    ? visitData.circumference_measurement.abdomen
                    : ""
                }
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Box>
          <Box display="flex" ml="-10px">
            <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
              <TextField
                id="fianchi"
                label="Fianchi (cm)"
                variant="filled"
                color="secondary"
                type="number"
                disabled={disabled1}
                onChange={handleChangeHips}
                defaultValue={
                  visitData.circumference_measurement
                    ? visitData.circumference_measurement.hips
                    : ""
                }
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
              <TextField
                id="coscia"
                label="Coscia (cm)"
                variant="filled"
                color="secondary"
                type="number"
                disabled={disabled1}
                onChange={handleChangeThigh}
                defaultValue={
                  visitData.circumference_measurement
                    ? visitData.circumference_measurement.thigh
                    : ""
                }
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Box>
          <Box display="flex" ml="-10px">
            <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
              <TextField
                id="polpaccio"
                label="Polpaccio (cm)"
                variant="filled"
                color="secondary"
                type="number"
                disabled={disabled1}
                onChange={handleChangeCalf}
                defaultValue={
                  visitData.circumference_measurement
                    ? visitData.circumference_measurement.calf
                    : ""
                }
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Box>
          <Box
            display="flex"
            ml="-10px"
            mt="30px"
            sx={{ justifyContent: "space-between" }}
          >
            <Button
              sx={{
                color: "#464868",
                textDecoration: "underline",
                fontSize: "14px",
                fontWeight: "500",
              }}
              onClick={handleCloseModal1}
            >
              Annulla
            </Button>
            <Button
              sx={{
                background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
                textTransform: 'none',
                fontWeight: 600,
                borderRadius: "8px",
                color: "white",
                padding: "8px 16px",
                boxShadow: 'none',
                '&:hover': {
                  boxShadow: 'none'
                }
              }}
              onClick={handleAdd1}
              disabled={disabled1}
            >
              Salva le misure
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal open={openModal2} onClose={handleCloseModal2}>
        <Box sx={style}>
          <Box width="100%" height="5%">
            <Typography
              variant="h1"
              color="#2F1847"
              sx={{ fontWeight: "400", fontSize: "14px" }}
            >
              {`Visita del ${new Date().toLocaleDateString()}`}
            </Typography>
          </Box>
          <Box width="100%" height="10%">
            <Typography
              variant="h1"
              color="#2F1847"
              sx={{ fontWeight: "700", fontSize: "32px" }}
            >
              BIA
            </Typography>
          </Box>
          <Box ml="-10px">
            <FormControl variant="standard" sx={{ m: 1, minWidth: 500 }}>
              <TextField
                id="resistance"
                label="Resistenza (Ω)"
                variant="filled"
                color="secondary"
                type="number"
                disabled={disabled2}
                onChange={handleChangeResistance}
                defaultValue={
                  visitData.bia_measurement
                    ? visitData.bia_measurement.resistance
                    : ""
                }
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 500 }}>
              <TextField
                id="reactance"
                label="Reattanza (Ω)"
                variant="filled"
                color="secondary"
                type="number"
                disabled={disabled2}
                onChange={handleChangeReactance}
                defaultValue={
                  visitData.bia_measurement
                    ? visitData.bia_measurement.reactance
                    : ""
                }
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Box>
          <Box
            display="flex"
            ml="-10px"
            mt="270px"
            sx={{ justifyContent: "space-between" }}
          >
            <Button
              sx={{
                color: "#464868",
                textDecoration: "underline",
                fontSize: "14px",
                fontWeight: "500",
              }}
              onClick={handleCloseModal2}
            >
              Annulla
            </Button>
            <Button
              sx={{
                background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
                textTransform: 'none',
                fontWeight: 600,
                color: "white",
                borderRadius: "8px",
                padding: "8px 16px",
                boxShadow: 'none',
                '&:hover': {
                  boxShadow: 'none'
                }
              }}
              onClick={handleAdd2}
              disabled={disabled2}
            >
              Salva le misure
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal open={openModal3} onClose={handleCloseModal3}>
        <Box sx={style}>
          <Box width="100%" height="5%">
            <Typography
              variant="h1"
              color="#2F1847"
              sx={{ fontWeight: "400", fontSize: "14px" }}
            >
              {`Visita del ${new Date().toLocaleDateString()}`}
            </Typography>
          </Box>
          <Box width="100%" height="10%">
            <Typography
              variant="h1"
              color="#2F1847"
              sx={{ fontWeight: "700", fontSize: "32px" }}
            >
              Pliche
            </Typography>
          </Box>
          <Box display="flex" ml="-10px">
            <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
              <TextField
                id="bicipite"
                label="Bicipitale (mm)"
                variant="filled"
                color="secondary"
                type="number"
                disabled={disabled3}
                onChange={handleChangeBicipital}
                defaultValue={
                  visitData.skinfold_measurement
                    ? visitData.skinfold_measurement.bicipital
                    : ""
                }
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
              <TextField
                id="tricipite"
                label="Tricipitale (mm)"
                variant="filled"
                color="secondary"
                type="number"
                onChange={handleChangeTricipital}
                defaultValue={
                  visitData.skinfold_measurement
                    ? visitData.skinfold_measurement.tricipital
                    : ""
                }
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Box>
          <Box display="flex" ml="-10px">
            <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
              <TextField
                id="subscapular"
                label="Sottoscapolare (mm)"
                variant="filled"
                color="secondary"
                type="number"
                disabled={disabled3}
                onChange={handleChangeSubScapular}
                defaultValue={
                  visitData.skinfold_measurement
                    ? visitData.skinfold_measurement.subscapular
                    : ""
                }
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
              <TextField
                id="mid_axillary"
                label="Ascellare (mm)"
                variant="filled"
                color="secondary"
                type="number"
                disabled={disabled3}
                onChange={handleChangeMidAx}
                defaultValue={
                  visitData.skinfold_measurement
                    ? visitData.skinfold_measurement.mid_axillary
                    : ""
                }
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Box>
          <Box display="flex" ml="-10px">
            <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
              <TextField
                id="pectoral"
                label="Pettorale (mm)"
                variant="filled"
                color="secondary"
                type="number"
                disabled={disabled3}
                onChange={handleChangePectoral}
                defaultValue={
                  visitData.skinfold_measurement
                    ? visitData.skinfold_measurement.pectoral
                    : ""
                }
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
              <TextField
                id="midclavicular"
                label="Sovrailiaca mediana (mm)"
                variant="filled"
                color="secondary"
                type="number"
                disabled={disabled3}
                onChange={handleChangeMidCl}
                defaultValue={
                  visitData.skinfold_measurement
                    ? visitData.skinfold_measurement.midclavicular
                    : ""
                }
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Box>
          <Box display="flex" ml="-10px">
            <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
              <TextField
                id="anterior_axillary"
                label="Sovrailiaca anteriore (mm)"
                variant="filled"
                color="secondary"
                type="number"
                disabled={disabled3}
                onChange={handleChangeAntAx}
                defaultValue={
                  visitData.skinfold_measurement
                    ? visitData.skinfold_measurement.anterior_axillary
                    : ""
                }
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
              <TextField
                id="abdominal"
                label="Addominale (mm)"
                variant="filled"
                color="secondary"
                type="number"
                disabled={disabled3}
                onChange={handleChangeAbdominal}
                defaultValue={
                  visitData.skinfold_measurement
                    ? visitData.skinfold_measurement.abdominal
                    : ""
                }
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Box>
          <Box display="flex" ml="-10px">
            <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
              <TextField
                id="thigh"
                label="Coscia (mm)"
                variant="filled"
                color="secondary"
                type="number"
                disabled={disabled3}
                onChange={handleChangeThigh2}
                defaultValue={
                  visitData.skinfold_measurement
                    ? visitData.skinfold_measurement.thigh
                    : ""
                }
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
              <TextField
                id="calf"
                label="Polpaccio (mm)"
                variant="filled"
                color="secondary"
                type="number"
                disabled={disabled3}
                onChange={handleChangeCalf2}
                defaultValue={
                  visitData.skinfold_measurement
                    ? visitData.skinfold_measurement.calf
                    : ""
                }
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Box>
          <Box
            display="flex"
            ml="-10px"
            mt="50px"
            sx={{ justifyContent: "space-between" }}
          >
            <Button
              sx={{
                color: "#464868",
                textDecoration: "underline",
                fontSize: "14px",
                fontWeight: "500",
              }}
              onClick={handleCloseModal3}
            >
              Annulla
            </Button>
            <Button
              sx={{
                background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
                textTransform: 'none',
                fontWeight: 600,
                borderRadius: "8px",
                color: "white",
                padding: "8px 16px",
                boxShadow: 'none',
                '&:hover': {
                  boxShadow: 'none'
                }
              }}
              onClick={handleAdd3}
              disabled={disabled3}
            >
              Salva le misure
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

const steps = ["ANAMNESI", "ANTROPOMETRIA"];

const AddVisit = () => {
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const pathnameParts = location.pathname.split("/");
  const id = pathnameParts[pathnameParts.length - 1];

  const { data: visits, isLoading: isVisitsLoading } = useGetVisitsQuery(id);

  const visitsIds = visits?.data.map(({ id }) => id);

  let lastVisitId = visitsIds?.[visitsIds.length - 1];

  const { data: lastVisit, isLoading: isLastVisitLoading, isError: isLastVisitError } =
    useGetVisitsDetailsQuery(lastVisitId);

  console.log(lastVisit);

  const [visitData, setVisitData] = useState({
    anamnesis: {
      sports: [],
      like: [],
      not_like: [],
      food_profile: "omnivorous",
      pathologies: "",
      clinical_history: "",
      drugs_and_supplements: [],
      menstruation: null,
      habits: "",
      bowel: null,
      activities_level: null,
      smoker: false,
      notes: ""
    },
    measurement: {
      weight: "",
      height: ""
    },
    clients_id: null,
  });

  const [anchorMissingFormField, setAnchorMissingFormField] = React.useState(null);

  React.useEffect(() => {
    if (lastVisit) {
      setVisitData((prevState) => ({
        ...prevState,
        anamnesis: {
          sports: lastVisit.anamnesis.sports,
          like: lastVisit.anamnesis.like,
          not_like: lastVisit.anamnesis.not_like,
          food_profile: lastVisit.anamnesis.food_profile,
          pathologies: lastVisit.anamnesis.pathologies,
          clinical_history: lastVisit.anamnesis.clinical_history,
          menstruation: lastVisit.anamnesis.menstruation,
          drugs_and_supplements: [lastVisit.anamnesis.drugs_and_supplements],
          bowel: lastVisit.anamnesis.bowel,
          smoker: lastVisit.anamnesis.smoker,
          activities_level: lastVisit.anamnesis.activities_level,
          sleep_hours: lastVisit.anamnesis.sleep_hours,
          habits: lastVisit.anamnesis.habits,
          weekly_training_hours: lastVisit.anamnesis.weekly_training_hours,
        },
        measurement: {
          weight: lastVisit.measurement.weight,
          height: lastVisit.measurement.height,
        },
        clients_id: null,
      }));
    }
  }, [lastVisit]);
  const [saveBiaMeasurement, setSaveBiaMeasurement] = useState(false);
  const [saveCircumferenceMeasurement, setSaveCircumferenceMeasurement] = useState(false);
  const [saveSkinfoldMeasurement, setSaveSkinfoldMeasurement] = useState(false);
  const [
    postVisit,
    { isLoading: isPostVisitLoading, isError: isPostVisitError },
  ] = usePostVisitMutation();
  const { data: clientData, isLoading: isClientLoading } = useGetClientDetailsQuery(id);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const isFormValid = () => {
    // Check if all required fields are filled
    return visitData.measurement.weight && visitData.measurement.height;
    // Add more conditions for other required fields
  };

  const handleReset = async (event) => {
    try {
      if (!isFormValid()) {
        setAnchorMissingFormField(event.currentTarget);
        return;
      }
      const updatedVisitData = { ...visitData, clients_id: id };
      const updatedLike = updatedVisitData.anamnesis.like.map((option) => option.id)
      const updatedNotLike = updatedVisitData.anamnesis.not_like.map((option) => option.id)
      updatedVisitData.anamnesis = { ...updatedVisitData.anamnesis, like: updatedLike, not_like: updatedNotLike };
      updatedVisitData.visit_date = new Date()
      const res = await postVisit(updatedVisitData);

      setActiveStep(0);
      navigate(`/pazienti/details/${id}`);
      window.location.reload();
    } catch (err) {
      console.error("Error occurred while creating visit:", err);
    }
  };

  const handleChangeLike = (likes) => {
    setVisitData({
      ...visitData,
      anamnesis: {
        ...visitData.anamnesis,
        like: likes,
      },
    });
  };

  const handleChangeNotLike = (notLikes) => {
    setVisitData({
      ...visitData,
      anamnesis: {
        ...visitData.anamnesis,
        not_like: notLikes,
      },
    });
  };

  const handleChangeFoodProfile = (event) => {
    setVisitData({
      ...visitData,
      anamnesis: { ...visitData.anamnesis, food_profile: event.target.value },
    });
  };

  const handleChangePathologies = (event) => {
    setVisitData({
      ...visitData,
      anamnesis: { ...visitData.anamnesis, pathologies: event.target.value },
    });
  };

  const handleChangeClinicalHistory = (event) => {
    setVisitData({
      ...visitData,
      anamnesis: {
        ...visitData.anamnesis,
        clinical_history: event.target.value,
      },
    });
  };

  const handleChangeDrugsAndSupplements = (event) => {
    setVisitData({
      ...visitData,
      anamnesis: {
        ...visitData.anamnesis,
        drugs_and_supplements: [event.target.value],
      },
    });
  };

  const handleChangeMenstruation = (event) => {
    setVisitData({
      ...visitData,
      anamnesis: { ...visitData.anamnesis, menstruation: event.target.value },
    });
  };

  const handleChangeSleepHours = (event) => {
    setVisitData({
      ...visitData,
      anamnesis: {
        ...visitData.anamnesis,
        sleep_hours: Number(event.target.value),
      },
    });
  };

  const handleChangeHabits = (event) => {
    setVisitData({
      ...visitData,
      anamnesis: { ...visitData.anamnesis, habits: event.target.value },
    });
  };

  const handleChangeBowel = (event) => {
    setVisitData({
      ...visitData,
      anamnesis: { ...visitData.anamnesis, bowel: event.target.value },
    });
  };

  const handleChangeSport = (event, sports) => {
    setVisitData({
      ...visitData,
      anamnesis: {
        ...visitData.anamnesis,
        sports: sports
      },
    });
  };

  const handleChangeWeeklyTrainingHours = (event) => {
    setVisitData({
      ...visitData,
      anamnesis: {
        ...visitData.anamnesis,
        weekly_training_hours: Number(event.target.value),
      },
    });
  };

  const handleChangeActivitiesLevel = (event) => {
    setVisitData({
      ...visitData,
      anamnesis: {
        ...visitData.anamnesis,
        activities_level: event.target.value,
      },
    });
  };

  const handleChangeSmoker = (event) => {
    let smoker = null;
    if (event.target.value === "Si") {
      smoker = true;
    } else {
      smoker = false;
    }
    setVisitData({
      ...visitData,
      anamnesis: { ...visitData.anamnesis, smoker: smoker },
    });
  };

  const handleChangeNotes = (event) => {
    setVisitData({
      ...visitData,
      anamnesis: {
        ...visitData.anamnesis,
        notes: event.target.value,
      },
    });
  };

  const handleChangeWeight = (event) => {
    setVisitData({
      ...visitData,
      measurement: {
        ...visitData.measurement,
        weight: Number(event.target.value),
      },
    });
  };

  const handleChangeHeight = (event) => {
    setVisitData({
      ...visitData,
      measurement: {
        ...visitData.measurement,
        height: Number(event.target.value),
      },
    });
  };

  const handleChangeResistance = (event) => {
    setVisitData({
      ...visitData,
      bia_measurement: {
        ...visitData.bia_measurement,
        resistance: Number(event.target.value),
      },
    });
  };

  const handleChangeReactance = (event) => {
    setVisitData({
      ...visitData,
      bia_measurement: {
        ...visitData.bia_measurement,
        reactance: Number(event.target.value),
      },
    });
  };

  const handleChangeWrist = (event) => {
    setVisitData({
      ...visitData,
      circumference_measurement: {
        ...visitData.circumference_measurement,
        wrist: Number(event.target.value),
      },
    });
  };

  const handleChangeArm = (event) => {
    setVisitData({
      ...visitData,
      circumference_measurement: {
        ...visitData.circumference_measurement,
        arm: Number(event.target.value),
      },
    });
  };

  const handleChangeArmContracted = (event) => {
    setVisitData({
      ...visitData,
      circumference_measurement: {
        ...visitData.circumference_measurement,
        contracted_arm: Number(event.target.value),
      },
    });
  };

  const handleChangeNeck = (event) => {
    setVisitData({
      ...visitData,
      circumference_measurement: {
        ...visitData.circumference_measurement,
        neck: Number(event.target.value),
      },
    });
  };

  const handleChangeChest = (event) => {
    setVisitData({
      ...visitData,
      circumference_measurement: {
        ...visitData.circumference_measurement,
        chest: Number(event.target.value),
      },
    });
  };

  const handleChangeWaist = (event) => {
    setVisitData({
      ...visitData,
      circumference_measurement: {
        ...visitData.circumference_measurement,
        waist: Number(event.target.value),
      },
    });
  };

  const handleChangeAbdomen = (event) => {
    setVisitData({
      ...visitData,
      circumference_measurement: {
        ...visitData.circumference_measurement,
        abdomen: Number(event.target.value),
      },
    });
  };

  const handleChangeHips = (event) => {
    setVisitData({
      ...visitData,
      circumference_measurement: {
        ...visitData.circumference_measurement,
        hips: Number(event.target.value),
      },
    });
  };

  const handleChangeThigh = (event) => {
    setVisitData({
      ...visitData,
      circumference_measurement: {
        ...visitData.circumference_measurement,
        thigh: Number(event.target.value),
      },
    });
  };

  const handleChangeCalf = (event) => {
    setVisitData({
      ...visitData,
      circumference_measurement: {
        ...visitData.circumference_measurement,
        calf: Number(event.target.value),
      },
    });
  };

  const handleChangeBicipital = (event) => {
    setVisitData({
      ...visitData,
      skinfold_measurement: {
        ...visitData.skinfold_measurement,
        bicipital: Number(event.target.value),
      },
    });
  };

  const handleChangeTricipital = (event) => {
    setVisitData({
      ...visitData,
      skinfold_measurement: {
        ...visitData.skinfold_measurement,
        tricipital: Number(event.target.value),
      },
    });
  };

  const handleChangeSubScapular = (event) => {
    setVisitData({
      ...visitData,
      skinfold_measurement: {
        ...visitData.skinfold_measurement,
        subscapular: Number(event.target.value),
      },
    });
  };

  const handleChangeMidAx = (event) => {
    setVisitData({
      ...visitData,
      skinfold_measurement: {
        ...visitData.skinfold_measurement,
        mid_axillary: Number(event.target.value),
      },
    });
  };

  const handleChangePectoral = (event) => {
    setVisitData({
      ...visitData,
      skinfold_measurement: {
        ...visitData.skinfold_measurement,
        pectoral: Number(event.target.value),
      },
    });
  };

  const handleChangeMidCl = (event) => {
    setVisitData({
      ...visitData,
      skinfold_measurement: {
        ...visitData.skinfold_measurement,
        midclavicular: Number(event.target.value),
      },
    });
  };

  const handleChangeAntAx = (event) => {
    setVisitData({
      ...visitData,
      skinfold_measurement: {
        ...visitData.skinfold_measurement,
        anterior_axillary: Number(event.target.value),
      },
    });
  };

  const handleChangeAbdominal = (event) => {
    setVisitData({
      ...visitData,
      skinfold_measurement: {
        ...visitData.skinfold_measurement,
        abdominal: Number(event.target.value),
      },
    });
  };

  const handleChangeThigh2 = (event) => {
    setVisitData({
      ...visitData,
      skinfold_measurement: {
        ...visitData.skinfold_measurement,
        thigh: Number(event.target.value),
      },
    });
  };

  const handleChangeCalf2 = (event) => {
    setVisitData({
      ...visitData,
      skinfold_measurement: {
        ...visitData.skinfold_measurement,
        calf: Number(event.target.value),
      },
    });
  };

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirmDialog = () => {
    setOpenConfirmDialog(false);
    navigate(`/pazienti/details/${id}`)
  };

  const handleClosePopoverMissingFormField = () => {
    setAnchorMissingFormField(null);
  };

  const stepContent = [
    <Step1Content
      visitData={visitData}
      clientData={clientData}
      handleChangeFoodProfile={handleChangeFoodProfile}
      handleChangePathologies={handleChangePathologies}
      handleChangeClinicalHistory={handleChangeClinicalHistory}
      handleChangeDrugsAndSupplements={handleChangeDrugsAndSupplements}
      handleChangeMenstruation={handleChangeMenstruation}
      handleChangeSleepHours={handleChangeSleepHours}
      handleChangeHabits={handleChangeHabits}
      handleChangeBowel={handleChangeBowel}
      handleChangeSport={handleChangeSport}
      handleChangeWeeklyTrainingHours={handleChangeWeeklyTrainingHours}
      handleChangeActivitiesLevel={handleChangeActivitiesLevel}
      handleChangeSmoker={handleChangeSmoker}
      handleChangeLike={handleChangeLike}
      handleChangeNotLike={handleChangeNotLike}
      handleChangeNotes={handleChangeNotes}
    />,
    <Step2Content
      visitData={visitData}
      handleChangeWeight={handleChangeWeight}
      handleChangeHeight={handleChangeHeight}
      handleChangeResistance={handleChangeResistance}
      handleChangeReactance={handleChangeReactance}
      handleChangeWrist={handleChangeWrist}
      handleChangeArm={handleChangeArm}
      handleChangeArmContracted={handleChangeArmContracted}
      handleChangeNeck={handleChangeNeck}
      handleChangeChest={handleChangeChest}
      handleChangeWaist={handleChangeWaist}
      handleChangeAbdomen={handleChangeAbdomen}
      handleChangeHips={handleChangeHips}
      handleChangeThigh={handleChangeThigh}
      handleChangeCalf={handleChangeCalf}
      handleChangeBicipital={handleChangeBicipital}
      handleChangeTricipital={handleChangeTricipital}
      handleChangeSubScapular={handleChangeSubScapular}
      handleChangeMidAx={handleChangeMidAx}
      handleChangePectoral={handleChangePectoral}
      handleChangeMidCl={handleChangeMidCl}
      handleChangeAntAx={handleChangeAntAx}
      handleChangeAbdominal={handleChangeAbdominal}
      handleChangeThigh2={handleChangeThigh2}
      handleChangeCalf2={handleChangeCalf2}
      saveBiaMeasurement={saveBiaMeasurement}
      saveCircumferenceMeasurement={saveCircumferenceMeasurement}
      saveSkinfoldMeasurement={saveSkinfoldMeasurement}
      setSaveBiaMeasurement={setSaveBiaMeasurement}
      setSaveCircumferenceMeasurement={setSaveCircumferenceMeasurement}
      setSaveSkinfoldMeasurement={setSaveSkinfoldMeasurement}
    />
  ];

  if (
    isPostVisitLoading ||
    isClientLoading ||
    isVisitsLoading ||
    isLastVisitLoading
  ) {
    return <div class="loader-container">
      <div class="loader"></div>
    </div>
      ;
  }

  if (isPostVisitError || (isLastVisitError && visits)) {
    return <p>Errore nel salvataggio</p>;
  }

  return (
    <Box m="1.5rem 2.5rem">
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Button
            sx={{
              color: "#464868",
              textDecoration: "underline",
              fontSize: "14px",
              fontWeight: "500",
            }}
            onClick={
              handleOpenConfirmDialog
            }
          >
            {"Annulla"}
          </Button>
        </Box>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<QontoConnector />}
        >
          {steps.map((label) => (
            <Step sx={{ml: "22px"}}  key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box>
          {activeStep > 0 ?
            <div className="gradient-border-wrapper">
              <Button

                onClick={handleBack}
                sx={{
                  color: "linear-gradient(to top right, #B0D99C, #68BE6A)",
                  fontWeight: 600, textTransform: 'none'
                }}
                className="gradient-button"
              >

                {"Indietro"}
              </Button>
            </div>
            : null
          }
          <Button
            sx={{
              marginLeft: '16px',
              background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
              textTransform: 'none',
              fontWeight: 600,
              borderRadius: "8px",
              padding: "8px 16px",
              color: "white",
              boxShadow: 'none',
              '&:hover, &:active, &:focus': {
                boxShadow: 'none'
              }
            }}
            variant="contained"
            onClick={activeStep === steps.length - 1 ? handleReset : handleNext}
          >
            {activeStep === steps.length - 1 ? "Salva" : "Avanti"}
          </Button>
          <Popover
            id="popover_id"
            open={Boolean(anchorMissingFormField)}
            anchorEl={anchorMissingFormField}
            onClose={handleClosePopoverMissingFormField}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Typography color="error" sx={{ p: 2 }}>Compila i campi mancanti</Typography>
          </Popover>
        </Box>
        <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
          <DialogTitle>Conferma annullamento</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Sei sicuro di voler tornare indietro? Perderai i dati inseriti.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseConfirmDialog} color="error">
              Annulla
            </Button>
            <Button onClick={handleConfirmDialog} sx={{
              background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
              textTransform: 'none',
              borderRadius: "8px",
              color: "white",
              padding: "8px 16px",
              fontWeight: 600,
              boxShadow: 'none',
              '&:hover, &:active, &:focus': {
                boxShadow: 'none'
              }
            }}>
              Conferma
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      {stepContent[activeStep]}
    </Box>
  );
};

export default AddVisit;
