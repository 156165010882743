import React, { useState, useEffect } from "react";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  FormControl,
  TextField,
  Select,
  MenuItem,
  Chip,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { BasicTooltip } from '@nivo/tooltip'
import { ResponsivePie } from '@nivo/pie'
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../../components/Header";
import { Ingrediente } from "../details/index";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddIcon from "@mui/icons-material/Add";
import UploadDragAndDrop from "../../../components/UploadDragAndDrop";
import {
  useGetTagsQuery,
  usePutRicettaMutation,
  useGetRicetteDetailsQuery,
} from "../../../redux/state/api";
import IngredientAndQuantity from "../../../components/IngredientAndQuantity";

const ModificaRicetta = () => {

  const categories = [
    { id: 1, name: "Colazione" },
    { id: 2, name: "Pranzo" },
    { id: 3, name: "Spuntino" },
    { id: 4, name: "Cena" },
  ];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const location = useLocation();

  const [quantity, setQuantity] = useState("");
  const navigate = useNavigate();
  const [macronutrients, setMacronutrients] = useState({
    carbohydrates: 0,
    proteins: 0,
    fats: 0,
    alcohol: 0,
    fibers: 0,
    sugars: 0,
  });


  const { data: tagsData, isLoading: isTagsLoading } = useGetTagsQuery();
  const [putRicetta, { isLoading: isPutLoading, isError }] =
    usePutRicettaMutation();

  const handleNavigateBack = () => {
    navigate(`/ricette/details/${id}`);
  };

  const nutrientQuantity = (nutrient, quantity) => {
    return parseFloat((nutrient * quantity / 100).toFixed(2));
  }
  const handleAddClick = (selectedIngredient) => {

    if (selectedIngredient) {
      const newIngredient = {
        ingredients_id: selectedIngredient.id,
        quantity: Number(quantity),
        simple_name: selectedIngredient.simple_name_it,
        specific_info: selectedIngredient.specific_info,
        carbohydrates: {
          percentage: selectedIngredient.carbohydrates,
          quantity: nutrientQuantity(selectedIngredient.carbohydrates, quantity),
        },
        proteins: {
          percentage: selectedIngredient.proteins,
          quantity: nutrientQuantity(selectedIngredient.proteins, quantity),
        },
        fats: {
          percentage: selectedIngredient.fats,
          quantity: nutrientQuantity(selectedIngredient.fats, quantity),
        },
        alcohol: {
          percentage: selectedIngredient.alcohol,
          quantity: nutrientQuantity(selectedIngredient.alcohol, quantity),
        },
        sugars: {
          percentage: selectedIngredient.sugars,
          quantity: nutrientQuantity(selectedIngredient.sugars, quantity),
        },
        fibers: {
          percentage: selectedIngredient.fibers,
          quantity: nutrientQuantity(selectedIngredient.fibers, quantity),
        },
        energy: {
          quantity: selectedIngredient.energy,
        },
        categories_id: selectedIngredient.categories_id,
      };

      // Update the ricetta.ingredients array with the formatted ingredient
      setRicetta((prevRicetta) => ({
        ...prevRicetta,
        ingredients: [...prevRicetta.ingredients, newIngredient],
      }));


      // Reset ingredient and quantity fields
      setQuantity("");
    }

  };

  const handleRemoveIngredient = (id) => {
    const updatedIngredients = ricetta.ingredients.filter(
      (ingredient) => ingredient.ingredients_id !== id
    );
    setRicetta((prevRicetta) => ({
      ...prevRicetta,
      ingredients: updatedIngredients,
    }))
  };

  const handleAddStep = () => {
    const newStep = { description_it: "" };

    // Update 'ricetta.steps' array as well
    setRicetta((prevRicetta) => ({
      ...prevRicetta,
      steps: [...prevRicetta.steps, newStep],
    }));
  };

  const handleRemoveStep = (index) => {
    // Create copies of 'steps' and 'ricetta.steps' arrays
    const updatedRicettaSteps = [...ricetta.steps];

    // Remove the step at the specified index from both arrays
    updatedRicettaSteps.splice(index, 1);

    // Update the local 'steps' array

    // Update the 'ricetta.steps' array
    setRicetta((prevRicetta) => ({
      ...prevRicetta,
      steps: updatedRicettaSteps,
    }));
  };

  const handleStepChange = (event, index) => {
    // Update the 'ricetta.steps' array as well
    setRicetta((prevRicetta) => {
      const updatedRicettaSteps = [...prevRicetta.steps];
      updatedRicettaSteps[index].description_it = event.target.value;
      return { ...prevRicetta, steps: updatedRicettaSteps };
    });
  };

  const pathnameParts = location.pathname.split("/");
  const id = pathnameParts[pathnameParts.length - 1];

  const { data: ricettaDetails, isLoading: isRicettaDetailsLoading } =
    useGetRicetteDetailsQuery(id);

  const [ricetta, setRicetta] = useState({
    name_it: ricettaDetails.name,
    categories: ricettaDetails.categories.map((category) => ({
      categories_id: category.id,
    })),
    prep_time: ricettaDetails.prep_time,
    cook_time: ricettaDetails.cook_time,
    description_it: ricettaDetails.description,
    recipe_tags: ricettaDetails.tags.map((tag) => ({ tags_id: tag.id })),
    ingredients: ricettaDetails.ingredients.map((ingredient) => ({
      ingredients_id: ingredient.id,
      quantity: ingredient.quantity,
      simple_name: ingredient.simple_name,
      specific_info: ingredient.specific_info,
      energy: ingredient.nutrition_facts.energy,
      carbohydrates: ingredient.nutrition_facts.carbohydrates,
      proteins: ingredient.nutrition_facts.proteins,
      fats: ingredient.nutrition_facts.fats,
      alcohol: ingredient.nutrition_facts.alcohol,
      fibers: ingredient.nutrition_facts.fibers,
      sugars: ingredient.nutrition_facts.sugars,
      categories_id: ingredient.categories_id,
    })),
    steps: ricettaDetails.steps.map((step) => ({
      description_it: step.description,
    })),
    image: ricettaDetails.image,
  });

  const [selectedTags, setSelectedTags] = useState(
    ricetta.recipe_tags.map((tag) => tag.tags_id)
  );

  const [tipologiaPasto, setTipologiaPasto] = useState(
    ricetta.categories.map((category) => category.categories_id)
  );

  const handleChipClick = (tagId) => {
    if (selectedTags.includes(tagId)) {
      // Deselect tag and remove it from recipe_tags
      setSelectedTags(selectedTags.filter((id) => id !== tagId));
      setRicetta((prevRicetta) => ({
        ...prevRicetta,
        recipe_tags: prevRicetta.recipe_tags.filter(
          (tag) => tag.tags_id !== tagId
        ),
      }));
    } else {
      // Select tag and add it to recipe_tags
      setSelectedTags([...selectedTags, tagId]);
      setRicetta((prevRicetta) => ({
        ...prevRicetta,
        recipe_tags: [...prevRicetta.recipe_tags, { tags_id: tagId }],
      }));
    }
  };

  const handleImageUpload = (base64Data) => {
    setRicetta({ ...ricetta, image: base64Data });
  };

  const handleSelectPasto = (event) => {
    const {
      target: { value },
    } = event;
    // Update tipologiaPasto with the selected categories
    setTipologiaPasto(value);
    // Update ricetta.categories based on the selected categories
    setRicetta((prevRicetta) => ({
      ...prevRicetta,
      categories: value.map((categoryId) => ({ categories_id: categoryId })),
    }));
  };

  const handleChangeQuantity = (event) => {
    setQuantity(event.target.value)
  }
  const onSubmit = async () => {
    try {
      const simplifiedIngredients = ricetta.ingredients.map(({ ingredients_id, quantity }) => ({ ingredients_id, quantity }))
      const steps = ricetta.steps.filter((step) => {
        return step.description_it !== ""
      })
      if (ricetta.image.startsWith("http")) {
        // image is not a base64 because it's the S3 path
        const { image, ...ricettaWithoutImg } = ricetta;
        await putRicetta({ data: { ...ricettaWithoutImg, ingredients: simplifiedIngredients, steps: steps }, id: id });
        navigate(`/ricette/details/${id}`);
        window.location.reload();
      } else {
        // If ricetta.image does not start with "http," proceed without modification
        await putRicetta({ data: { ...ricetta, ingredients: simplifiedIngredients, steps: steps }, id: id });
        navigate(`/ricette/details/${id}`);
        window.location.reload();
      }
    } catch (error) {
      console.error("Errore durante la modifica della ricetta:", error);
    }
  };


  const nutrientPercentage = (nutrient, energy, factor) => {
    return parseFloat((100 * nutrient * factor / energy).toFixed(2));
  }
  
  useEffect(() => {

    let energy = 0.00;
    let carbohydrates = 0.00;
    let proteins = 0.00;
    let fats = 0.00;
    let alcohol = 0.00;
    let fibers = 0.00;
    let sugars = 0.00;
    ricetta.ingredients.forEach((ingredient) => {
      energy += ingredient.energy.quantity;
      carbohydrates += ingredient.carbohydrates.quantity;
      proteins += ingredient.proteins.quantity;
      fats += ingredient.fats.quantity;
      alcohol += ingredient.alcohol.quantity;
      fibers += ingredient.fibers.quantity;
      sugars += ingredient.sugars.quantity;
    })
    const totalNutrient = carbohydrates + proteins + fats + alcohol;
    const totalMacronutrients = {
        carbohydrates: {
            quantity: parseFloat(carbohydrates.toFixed(2)),
        percentage: nutrientPercentage(carbohydrates, energy, 4) - nutrientPercentage(sugars, energy, 4)
        },
        proteins: {
            quantity: parseFloat(proteins.toFixed(2)),
            percentage: nutrientPercentage(proteins, energy, 4)
        },
        fats: {
            quantity: parseFloat(fats.toFixed(2)),
            percentage: nutrientPercentage(fats, energy, 9)
        },
        alcohol: {
            quantity: parseFloat(alcohol.toFixed(2)),
            percentage: nutrientPercentage(alcohol, energy, 7)
        },
        fibers: {
          quantity: parseFloat(fibers.toFixed(2)),
          percentage: nutrientPercentage(fibers, energy, 0)
        },
        sugars: {
            quantity: parseFloat(sugars.toFixed(2)),
          percentage: nutrientPercentage(sugars, energy, 4)
        },
        energy: {
            quantity: parseFloat(energy.toFixed(2)),
        },
    }

    setMacronutrients(totalMacronutrients);
  }, [ricetta.ingredients, ricettaDetails]);

  if (
    isTagsLoading ||
    isPutLoading ||
    isRicettaDetailsLoading
  ) {
    return <div className="loader-container">
      <div className="loader"></div>
</div>
;
  }

  if (isError) {
    return <p>Errore durante la modifica della ricetta.</p>;
  }

  return (
    <Box
      m="1.5rem 2.5rem"
      width="90%"
      sx={{
        overflow: "hidden",
      }}
    >
      {" "}
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Button
          sx={{
            color: "#464868",
            textDecoration: "underline",
            fontSize: "14px",
            fontWeight: "500",
          }}
          onClick={handleNavigateBack}
        >
          Annulla
        </Button>
        <Button
          variant="outlined"
          style={{
            color: 'green', // Testo verde
            borderColor: 'green', // Contorno verde
            backgroundColor: 'white' // Sfondo bianco
          }}
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            borderRadius: "8px",
          }}
          disabled={isPutLoading}
          onClick={onSubmit}
        >
          {isPutLoading ? (
            <CircularProgress color="secondary" />
          ) : (
            "Salva Modifiche"
          )}
        </Button>
      </Toolbar>
      <Box
        width="100%"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Box width="70%">
          <Box ml="20px">
            <Header title="Modifica ricetta" />
            <Box mt="50px">
              <Typography
                color="#2F1847"
                fontSize="24px"
                fontWeight="700"
                variant="h1"
              >
                Informazioni generali
              </Typography>
            </Box>
            <Box mt="20px">
              <FormControl
                variant="standard"
                sx={{ width: "48%", mr: "2%", mb: "2%" }}
              >
                <TextField
                  id="nome"
                  label="Nome ricetta"
                  variant="filled"
                  color="secondary"
                  value={ricetta.name_it}
                  onChange={(event) =>
                    setRicetta({ ...ricetta, name_it: event.target.value })
                  }
                />
              </FormControl>
              <FormControl variant="filled" sx={{ width: "48%", mr: "2%", mb: "2%" }}>
                <InputLabel id="pasto-select-label" color="secondary">
                  Tipologia di pasto
                </InputLabel>
                <Select
                  labelId="pasto-select-label"
                  id="selectPasto"
                  label="Tipologia di pasto"
                  multiple
                  value={tipologiaPasto}
                  onChange={handleSelectPasto}
                  color="secondary"
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => {
                        const selectedItem = categories.find(
                          (category) => category.id === value
                        );
                        return (
                          <Chip
                            key={selectedItem.id}
                            label={selectedItem.name}
                            style={{
                              backgroundColor: 'rgba(70, 72, 104, 1)',
                              color: "white",
                              fontWeight: "500",
                              borderRadius: "4px"
                            }} 
                          />
                        );
                      })}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {categories.map(({ name, id }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                sx={{ width: "48%", mr: "2%", mb: "2%" }}
              >
                <TextField
                  id="tdc"
                  label="Tempo di cottura"
                  variant="filled"
                  color="secondary"
                  type="number"
                  required
                  value={ricetta.cook_time}
                  onChange={(event) =>
                    setRicetta({ ...ricetta, cook_time: event.target.value })
                  }
                />
              </FormControl>
              <FormControl variant="filled" sx={{ width: "48%", mr: "2%", mb: "2%" }}>
                <TextField
                  id="tdp"
                  label="Tempo di preparazione"
                  variant="filled"
                  color="secondary"
                  type="number"
                  required
                  value={ricetta.prep_time}
                  onChange={(event) =>
                    setRicetta({
                      ...ricetta,
                      prep_time: event.target.value,
                    })
                  }
                />
              </FormControl>
              <FormControl
                variant="filled"
                sx={{ width: "98%", mr: "2%", mb: "2%" }}
              >
                <TextField
                  id="description"
                  label="Descrizione"
                  multiline
                  rows={10}
                  variant="filled"
                  color="secondary"
                  value={ricetta.description_it}
                  onChange={(event) =>
                    setRicetta({
                      ...ricetta,
                      description_it: event.target.value,
                    })
                  }
                />
              </FormControl>
            </Box>
            <Box mt="90px">
              <Typography
                color="#2F1847"
                fontSize="24px"
                fontWeight="700"
                variant="h1"
              >
                Caratteristiche
              </Typography>
              <Typography
                color="#21272A"
                fontSize="16px"
                fontWeight="400"
                variant="h1"
                sx={{ mt: "10px" }}
              >
                Seleziona tra i badge le caratteristiche della ricetta
              </Typography>

              <Box display="flex" gap="1rem" mt="10px" flexWrap="wrap" flex="5">
                {tagsData.map(({ id, name }) => (
                  <Chip
                    label={name}
                    key={id}
                    onClick={() => handleChipClick(id)}
                    color={selectedTags.includes(id) ? "secondary" : "default"}
                    sx={{ cursor: "pointer" }}
                  />
                ))}
              </Box>
            </Box>
            <Box mt="90px">
              <Typography
                color="#2F1847"
                fontSize="24px"
                fontWeight="700"
                variant="h1"
              >
                Ingredienti
              </Typography>

              {ricetta.ingredients.map(
                ({ ingredients_id, quantity, simple_name, specific_info, energy, categories_id }) => {
                  return (
                    <Box display="flex" gap="1rem" key={ingredients_id}>
                      <Ingrediente
                        name={simple_name}
                        specific_info={specific_info}
                        quantity={quantity}
                        energy={energy.quantity}
                        categories_id={categories_id}
                      />
                      <Box
                        mt="20px"
                        display="inherit"
                        gap="1rem"
                        onClick={() => handleRemoveIngredient(ingredients_id)}
                      >
                        <HighlightOffIcon
                          sx={{ cursor: "pointer", color: "red" }}
                        />
                      </Box>
                    </Box>
                  );
                }
              )}
              <IngredientAndQuantity
                quantity={quantity}
                setQuantity={setQuantity}
                handleChangeQuantity={handleChangeQuantity}
                handleAddClick={handleAddClick}
              />
              <Box mt="90px">
                <Typography
                  color="#2F1847"
                  fontSize="24px"
                  fontWeight="700"
                  variant="h1"
                >
                  Procedimento
                </Typography>

                {[...ricetta.steps].map((step, index) => (
                  <Box mt="20px" key={index}>
                    <Typography
                      color="#21272A"
                      fontSize="12px"
                      fontWeight="500"
                      variant="h1"
                    >
                      Step {index + 1}
                    </Typography>

                    <Box display="flex" gap="80px">
                      <FormControl
                        variant="filled"
                        sx={{ width: "80%", mr: "2%", mb: "2%" }}
                      >
                        <TextField
                          id={`procedimento${index}`}
                          label={`Step ${index + 1}`}
                          multiline
                          variant="filled"
                          color="secondary"
                          value={step.description_it}
                          onChange={(event) => handleStepChange(event, index)}
                        />
                      </FormControl>
                      <Box mt="20px">
                        <HighlightOffIcon
                          sx={{ cursor: "pointer", color: "red" }}
                          onClick={() => handleRemoveStep(index)}
                        />
                      </Box>
                    </Box>
                  </Box>
                ))}

                <Button
                      sx={{  background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
                      textTransform: 'none',
                      fontSize: "14px",
                      color: "white",
                      fontWeight: 600,
                      padding:"8px 16px",
                      borderRadius: "8px",
                      boxShadow: 'none',
                      '&:hover, &:active, &:focus': {
                        boxShadow: 'none'
                      }
                    }}
                  startIcon={<AddIcon />}
                  onClick={handleAddStep}
                >
                  Aggiungi Step
                </Button>
              </Box>
              <Box display="flex" justifyContent="flex-end" mt="20px" mr="20px">
                <Button
                  variant="outlined"
                  style={{
                    color: 'green', // Testo verde
                    borderColor: 'green', // Contorno verde
                    backgroundColor: 'white' // Sfondo bianco
                  }}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    borderRadius: "8px",
                  }}
                  disabled={isPutLoading}
                  onClick={onSubmit}
                >
                  {isPutLoading ? (
                    <CircularProgress color="secondary" />
                  ) : (
                    "Salva Modifiche"
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box width="30%">
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box height="332px" width="100%" borderRadius="6px" marginBottom="32px">
              <UploadDragAndDrop
                action={
                  "http://localhost:3000/ricette/creazionemodificaricetta"
                }
                text={"Trascina l'immagine"}
                onUpload={handleImageUpload}
              />
            </Box>
          </Box>
          <Typography
            color="#2F1847"
            fontSize="24px"
            fontWeight="700"
            variant="h1"
            sx={{ mt: "70px" }}
          >
            Valori (auto-completati)
          </Typography>
          <Box mt="20px" display="flex" justifyContent="flex-end">
            <Box
              sx={{
                width: "100%",
                height: "332px",
                borderRadius: "6px",
                border: "1px solid rgba(193, 199, 205, 1)",
              }}
            >
              <Box display="flex">
                <Box minWidth="350px" mt="40px" ml="25px" mr="25px">
                  <Typography
                    color="#464868"
                    fontSize="20px"
                    fontWeight="700"
                    variant="h1"
                  >
                    Macronutrienti
                  </Typography>
                </Box>
              </Box>
              <ResponsivePie
                colors={["#EDC047", "#4496A8", "#ED8047", "#BF2B2B", "#F2E68A", "#ffffff"]}
                tooltip={e => {
                  let { datum } = e; return <BasicTooltip
                    id={datum.id}
                    value={`${datum.formattedValue}%`}
                    enableChip={true}
                    color={datum.color}
                  />
                }}
                data={[
                  {
                    id: "Carbo",
                    value: macronutrients.carbohydrates.percentage,
                    label: `Carbo ${macronutrients.carbohydrates.quantity}g`,
                  },
                  {
                    id: "Proteine",
                    value: macronutrients.proteins.percentage,
                    label: `Proteine ${macronutrients.proteins.quantity}g`,
                  },
                  {
                    id: "Grassi",
                    value: macronutrients.fats.percentage,
                    label: `Grassi ${macronutrients.fats.quantity}g`,
                  },
                  {
                    id: "Alcol",
                    value: macronutrients.alcohol.percentage,
                    label: `Alcol ${macronutrients.alcohol.quantity}g`,
                  },
                  {
                    id: "Zuccheri",
                    value: macronutrients.sugars.percentage,
                    label: `Zuccheri ${macronutrients.sugars.quantity}g`,
                  },
                  {
                    id: "Fibre",
                    label: `Fibre ${macronutrients.fibers.quantity}g`,
                  },
                ]}
                margin={{ top: 30, right: 70, bottom: 100, left: -100 }}
                innerRadius={0.4}
                enableArcLinkLabels={false}
                padAngle={1}
                cornerRadius={5}
                enableArcLabels={false}
                theme={{ legends: { text: { fontSize: 18, fontFamily: "Geomanist" } } }}
                activeOuterRadiusOffset={8}
                legends={[
                  {
                    anchor: 'right',
                    direction: 'column',
                    justify: false,
                    translateX: -10,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemWidth: 80,
                    itemHeight: 20,
                    itemTextColor: '#464868',
                    itemDirection: 'left-to-right',
                    itemOpacity: 1,
                    symbolSize: 16,
                    symbolShape: 'square',
                  }
                ]}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ModificaRicetta;
