import { CssBaseline } from "@mui/material";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Layout from "./scenes/layout";
import Dashboard from "./scenes/dashboard";
import Guide from "./scenes/guide";
import Referral from "./scenes/referral";
import Profile from "./scenes/profile";
import Login from "./scenes/login";
import Pazienti from "./scenes/pazienti";
import Ricette from "./scenes/ricette";
import ITP from "./scenes/itp";
import PrimoAccesso from "./scenes/primoAccesso";
import RicettaDetails from "./scenes/ricette/details";
import { useDispatch } from "react-redux";
import { userLogin } from "./redux/features/slice/authSlice";
import { useSelector } from "react-redux";
import React from "react";
import { UserProvider } from "./provider/UserProvider";
import CreazioneRicetta from "./scenes/ricette/creazione";
import AddPaziente from "./scenes/pazienti/add";
import PazientiDetails from "./scenes/pazienti/details";
import AddVisit from "./scenes/visit";
import PianoAlimentare from "./scenes/pianoAlimentare";
import ModificaRicetta from "./scenes/ricette/modifica";
import PassDim from "./scenes/passDim";
import Code from "./scenes/code";
import NewPass from "./scenes/newpass";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ModificaPazient from "./scenes/pazienti/modifica";
import EditVisit from "./scenes/visit/modifica";
import PrivateRoute from "./routes/PrivateRoute";
import "./index.css";
const theme = createTheme({
  typography: {
    fontFamily: 'Geomanist, Arial, sans-serif', // Aggiungi il font Geomanist
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        disabled: {
          // Stili personalizzati per l'elemento disabilitato
          color: '#000', // Un colore più scuro per una migliore visibilità
          opacity: 1, // Riduci la trasparenza
        },
      },
    },
  },
});

function App() {
  const { userToken } = useSelector((state) => state.auth);
  const userInformation = useSelector((state) => state.user).data;

  const isSubscriptionValid = () => {

    const currentDate = new Date();
    const currentMs = currentDate.getTime();

    const targetDate = new Date(userInformation.subscription_plan_expiration);
    const targetMs = targetDate.getTime();

    return targetMs > currentMs
  }

  return (
    <div className="App">
      <BrowserRouter>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <UserProvider>
            <Routes>
              <Route path="/primoaccesso" element={<PrimoAccesso />} />
              <Route path="/login" element={<Login />} />
              <Route path="/passwordDimenticata" element={<PassDim />} />
              <Route path="/code/:short_id" element={<Code />} />
              <Route path="/newPass/:nutritionist_id" element={<NewPass />} />
              {!userToken ? (
                <Route path="/" element={<Navigate to="/login" replace />} />
              ) : (
                <Route
                  path="/"
                  element={<Navigate to="/dashboard" replace />}
                />
              )}
              {/* Example of conditional route */}
              {/* <ProtectedRoute isAllowed={!!user && user.permissions.includes("dashboard")} redirectTo='/home'>
                <DashboardPage />
              </ProtectedRoute> */}
              <Route element={<PrivateRoute isAllowed={!!userToken} />}>
                <Route element={<Layout />}>
                  <Route element={<PrivateRoute isAllowed={!(!!userInformation) || isSubscriptionValid()} redirectTo="/profile" />}>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/pazienti" element={<Pazienti />} />
                  <Route path="/pazienti/aggiuntanuovo" element={<AddPaziente />} />
                  <Route
                    path="/pazienti/details/:id"
                    element={<PazientiDetails />}
                  />
                  <Route path="/pazienti/addVisit/:id" element={<AddVisit />} />
                  <Route path="/pazienti/editVisit/:id" element={<EditVisit />} />
                  <Route
                    path="/pazienti/pianoAlimentare/:id"
                    element={<PianoAlimentare />}
                  />
                  <Route
                    path="/pazienti/:client_id/editPianoAlimentare/:meal_plan_id"
                    element={<PianoAlimentare />}
                  />
                  <Route
                    path="/pazienti/modifica/:id"
                    element={<ModificaPazient />}
                  />
                  <Route path="/ricette" element={<Ricette />} />
                  <Route
                    path="/ricette/details/:id"
                    element={<RicettaDetails />}
                  />
                  <Route
                    path="/ricette/creazionericetta"
                    element={<CreazioneRicetta />}
                  />
                  <Route
                    path="/ricette/modificaricetta/:id"
                    element={<ModificaRicetta />}
                  />
                  <Route path="/i tuoi progressi" element={<ITP />} />
                  <Route path="/guide" element={<Guide />} />
                  <Route path="/referral" element={<Referral />} />
                  </Route>
                  <Route path="/profile" element={<Profile />} />
                </Route>
              </Route>
            </Routes>
          </UserProvider>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
