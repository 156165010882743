import React, { useState } from "react";
import { Upload, Button } from "antd";

const UploadDragAndDrop = ({ action, text, onUpload }) => {
  const [base64Image, setBase64Image] = useState(null);

  const handleBeforeUpload = (file) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const base64 = event.target.result;
      setBase64Image(base64);
      onUpload(base64); // Callback to parent component with the base64 image data
    };

    reader.readAsDataURL(file);
    return false; // Prevent default upload behavior
  };

  return (
    <Upload.Dragger
      multiple
      listType="picture"
      action={action}
      showUploadList={{ showRemoveIcon: true }}
      accept=".png,.jpeg,.doc"
      beforeUpload={handleBeforeUpload}
    >
      {text} qui o
      <br />
      <Button>Clicca qui per caricare</Button>
    </Upload.Dragger>
  );
};

export default UploadDragAndDrop;
