import React, { useState } from "react";
import {
  Box,
  IconButton,
  InputBase,
  Toolbar,
  Card,
  CardContent,
  CardActions,
  Typography,
  useMediaQuery,
  Modal,
  ButtonBase
} from "@mui/material";
import { Search } from "@mui/icons-material";
import Header from "../../components/Header";
import FlexBetween from "../../components/FlexBetween";
import { useGetGuideQuery } from "../../redux/state/api.jsx";

const VideoModal = ({ videoSrc, open, handleClose }) => (
  <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="video-modal-title"
    aria-describedby="video-modal-description"
    style={{ display: 'grid', placeItems: 'center' }}
  >
    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '80%', height: '80%', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
      <iframe
        title="Expanded Video"
        width="100%"
        height="100%"
        src={videoSrc}
        frameBorder="0"
        allowFullScreen
      />
    </Box>
  </Modal>
);

const Guida = ({ title, description, video, guideCategory }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const thumbnailUrl = `https://img.youtube.com/vi/${video.split('/').pop().split('?')[0]}/0.jpg`;

  return (
    <Card sx={{ maxWidth: 275 }} variant="outlined">
      <CardContent>
        {/* <Typography sx={{ fontSize: 14, fontWeight: "600" }} color="text.secondary" gutterBottom>
          {guideCategory}
        </Typography> */}
        <Typography variant="h5" component="div" fontWeight="bold">
          {title}
        </Typography>
        {/* <Typography variant="body2">{description}</Typography> */}
      </CardContent>
      <CardActions>
        <ButtonBase onClick={handleOpenModal} style={{ width: '100%', display: 'block', textAlign: 'center' }}>
          <img src={thumbnailUrl} alt="Play Video" style={{ width: '100%', height: 'auto' }} />
        </ButtonBase>
      </CardActions>
      <VideoModal videoSrc={video} open={modalOpen} handleClose={handleCloseModal} />
    </Card>
  );
};

const Guide = () => {
  const [searchFilter, setSearchFilter] = useState("");
  const { data, isLoading } = useGetGuideQuery();
  const isNonMobile = useMediaQuery("(min-width:1501px)");
  const isMedium = useMediaQuery("(max-width:1500px) and (min-width:1201px)");
  const isSmall = useMediaQuery("(max-width:1200px) and (min-width:901px)");
  const isExtraSmall = useMediaQuery("(max-width:900px)");

  const handleSearchChange = (event) => {
    setSearchFilter(event.target.value);
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  const guides = data?.data || [];

  const filteredData = guides.filter((item) => {
    const titleMatches = !searchFilter || item.title.toLowerCase().includes(searchFilter.toLowerCase());
    return titleMatches;
  });

  let columns;
  if (isNonMobile) {
    columns = "repeat(4, minmax(0, 1fr))";
  } else if (isMedium) {
    columns = "repeat(3, minmax(0, 1fr))";
  } else if (isSmall) {
    columns = "repeat(2, minmax(0, 1fr))";
  } else if (isExtraSmall) {
    columns = "repeat(1, minmax(0, 1fr))";
  }

  return (
    <Box m="1.5rem 2.5rem">
      <Toolbar sx={{ ml: "-24px", justifyContent: "space-between" }}>
        <Header title="Guide" />
        <FlexBetween gap="1.5rem">
          <FlexBetween
            backgroundColor={"transparent"}
            borderRadius="9px"
            gap="3rem"
            p="0.1rem 1.5rem"
            border={"1px solid var(--border, rgba(193, 199, 205, 0.50))"}
          >
            <InputBase
              placeholder="Cerca tra le guide..."
              onChange={handleSearchChange}
            />
            <IconButton>
              <Search sx={{ color: "black" }} />
            </IconButton>
          </FlexBetween>
        </FlexBetween>
      </Toolbar>

      {data || !isLoading ? (
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns={columns}
          justifyContent="space-between"
          rowGap="20px"
          columnGap="1.33%"
        >
          {filteredData.map(({ _id, title, description, video, guideCategory }) => (
            <Guida
              key={_id}
              _id={_id}
              title={title}
              description={description}
              video={video}
              guideCategory={guideCategory}
            />
          ))}
        </Box>
      ) : (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
    </Box>
  );
};

export default Guide;
