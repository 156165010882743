import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ReactComponent as clientiI } from "../../assets/icons/folder.svg";
import { ReactComponent as ricetteI } from "../../assets/icons/document-text.svg";
import { ReactComponent as appI } from "../../assets/icons/mobile.svg";

import {
SvgIcon,
  Button,
  Card,
  CardContent,
  CardActions,
  Divider,
  FormControl,
  TextField,
} from "@mui/material";
import { useUser } from "../../provider/UserProvider";
import { useCheckReferralCodeMutation } from "../../redux/state/api";


export function Subscription(props) {

  const userInformation = useUser();
  const expirationDate = new Date(props.expirationDate);
  const formattedExpirationDate = new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(expirationDate);
  const [checkReferralCode, { isLoading, data }] = useCheckReferralCodeMutation();
  const [referralCode, setReferralCode] = React.useState('');
  const [showPromoPriceTable, setShowPromoPriceTable] = React.useState(false);

  const handleSubmit = async () => {
    const res = await checkReferralCode({
      referral_code: referralCode
    });
    if (res.data.valid_code) {
      setShowPromoPriceTable(true)
    }
    setReferralCode('')
  };

  const nutritionistCanSubscribe = () => {
    return !!userInformation.identity_card && !!userInformation.order && !!userInformation.order_id && !!userInformation.pec
  }

  const plan_options = {
    "free": "Prova Gratuita",
    "monthly": "Piano Mensile",
      "trimestral": "Piano Trimestrale",
      "semestral": "Piano Semestrale",
      "annual": "Piano Annuale"
    };

  return (
    <Box sx={{ display: "flex", gap: '20px' }}>

      {/* First Card */}
      <Card sx={{
        maxWidth: 348,
        overflow: "hidden",
        height: "100%",
        borderRadius: "16px",
        border: "1px solid #dddddd",
        boxShadow: 'none'
      }}>
        <CardContent sx={{ marginTop: "16px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="h3"
              fontSize="20px"
              color="#2F1847"
              fontWeight="700"
            >
              Il tuo piano
            </Typography>
          </Box>
          <Divider
            sx={{
              marginTop: "32px",
              width: "100%",
              bgcolor: "transparent", // Imposta il colore di sfondo su trasparente
              border: 0, // Rimuove il bordo predefinito
              borderTop: "1px dashed rgba(193, 199, 205, 0.50)", // Imposta il bordo superiore come tratteggiato
            }}
          />
          <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "36px", textTransform: "uppercase" }}>
            <Typography
              variant="h3"
              fontSize="12px"
              color="#2F1847"
              fontWeight="700"
            >
              {plan_options[props.subscription_plan]}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "left", marginTop: "8px" }}>
            <Typography
              variant="h3"
              fontSize="16px"
              color="#2F1847"
              fontWeight="500"
            >
              {props.subscription_plan === "free" ?
                (expirationDate < new Date() ?
                  "Prova gratuita scaduta, abbonati per continuare ad usare Dietiamo!" :
                  `Prova gratuita valida fino al ${formattedExpirationDate}`) :
                (expirationDate < new Date() ?
                  "Iscrizione scaduta, abbonati nuovamente per continuare ad usare Dietiamo!" :
                  `Iscrizione attiva`)
              }
            </Typography>
          </Box>
          {
            (props.subscription_plan !== "free" && expirationDate >= new Date()) ?
            (<Box>
              <Box sx={{ display: "flex", justifyContent: "left", marginTop: "36px" }}>
                <Button
                  color="error"
                  sx={{ marginLeft: "-4px", textDecoration: "underline" }}
                  size="small"
                  href={`https://billing.stripe.com/p/login/test_bIYcPL9Tpfk1aUEcMM?prefilled_email=${userInformation.email}`}
                  target="_blank"
                >
                  Disattiva il piano
                </Button>
              </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "8px", }}>
                <Typography
                  variant="h3"
                    fontSize="16px"
                  color="#2F1847"
                  fontWeight="700"
                >
                  {`Il piano resterà comunque attivo fino al ${formattedExpirationDate}`}
                </Typography>
              </Box>
              </Box>) :
              (nutritionistCanSubscribe() &&
                (<Box>
                  <Divider
                    sx={{
                      marginTop: "32px",
                      width: "100%",
                      bgcolor: "transparent", // Imposta il colore di sfondo su trasparente
                      border: 0, // Rimuove il bordo predefinito
                      borderTop: "1px dashed rgba(193, 199, 205, 0.50)", // Imposta il bordo superiore come tratteggiato
                    }}
                  />
                  <FormControl>
                    <TextField
                      label="Referral Code"
                      variant="outlined"
                      color="secondary"
                      value={referralCode}
                      onChange={(e) => setReferralCode(e.target.value)}
                      sx={{
                        marginTop: "32px",
                      }}
                    />
                    <Button
                      sx={{
                        background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
                        textTransform: 'none',
                        fontSize: "14px",
                        color: "white",
                        fontWeight: 600,
                        padding: "8px 16px",
                        borderRadius: "8px",
                        boxShadow: 'none',
                        '&:hover, &:active, &:focus': {
                          boxShadow: 'none'
                        },
                        marginTop: "8px",
                      }}
                      disabled={!referralCode || isLoading}
                      onClick={handleSubmit}
                    >
                      {isLoading ? 'Stiamo verificando il codice' : 'Verifica codice'}
                    </Button>
                    {data &&
                      <p style={{ fontWeight: 600, color: (data.valid_code) ? '#68BE6A' : 'red' }}>
                        {data.msg}
                      </p>
                    }
                  </FormControl>
              </Box>)
              )
          }
        </CardContent>
        <CardActions disableSpacing>
          <Box display="flex" justifyContent="space-between" width="100%">
          </Box>
        </CardActions>
      </Card>
      {/* Second Card */}
      {(props.subscription_plan === "free" || expirationDate < new Date()) &&
        (nutritionistCanSubscribe() ?
          (
            <Card sx={{
              maxWidth: 1024,
              overflow: "hidden",
              height: "100%",
              borderRadius: "16px",
              border: "1px solid #dddddd",
              boxShadow: 'none'
            }}>
              <CardContent sx={{ marginTop: "16px" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ marginRight: "16px" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                      <Typography
                        variant="h3"
                        fontSize="20px"
                        color="#2F1847"
                        fontWeight="700"
                      >
                        Acquista il servizio!
                      </Typography>
                    </Box>
                    <Divider
                      sx={{
                        marginTop: "32px",
                        width: "100%",
                        bgcolor: "transparent", // Imposta il colore di sfondo su trasparente
                        border: 0, // Rimuove il bordo predefinito
                        borderTop: "1px dashed rgba(193, 199, 205, 0.50)", // Imposta il bordo superiore come tratteggiato
                      }}
                    />
                    <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "36px", textTransform: "uppercase" }}>
                      <Typography
                        variant="h3"
                        fontSize="12px"
                        color="#2F1847"
                        fontWeight="700"
                      >
                        descrizione
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "left", marginTop: "8px" }}>
                    <Typography
                      variant="h3"
                      sx={{
                        fontSize: '16px',
                        color: '#2F1847',
                        fontWeight: 500
                      }}
                    >
                      {props.showReferralDiscount ? (
                        <>
                          Grazie per aver consigliato Dietiamo ad un tuo collega, per te uno sconto speciale. <br />
                          Con l’abbonamento a Dietiamo avrai a disposizione tutte le funzioni presenti nel periodo di prova gratuito."
                        </>
                      ) : showPromoPriceTable ? (
                        <>
                          Abbiamo visto che hai sentito parlare di noi da un tuo collega… Ecco uno sconto speciale per te.<br />
                          Con l’abbonamento a Dietiamo avrai a disposizione tutte le funzioni presenti nel periodo di prova gratuito.
                        </>
                      ) : (
                        "Con l’abbonamento a Dietiamo avrai a disposizione tutte le funzioni presenti nel periodo di prova gratuito"
                      )}
                    </Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "left", marginTop: "36px" }}>
                      <SvgIcon component={clientiI} inheritViewBox />
                      <Typography
                        variant="h3"
                        fontSize="16px"
                        color="#2F1847"
                        fontWeight="500"
                        sx={{ marginLeft: "16px" }}
                      >
                      Creazione paziente con anamnesi, misurazioni e piano alimentare associato
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "left", marginTop: "8px" }}>
                      <SvgIcon component={ricetteI} inheritViewBox />
                      <Typography
                        variant="h3"
                        fontSize="16px"
                        color="#2F1847"
                        fontWeight="500"
                        sx={{ marginLeft: "16px" }}
                      >
                      Creazione di ricette da fornire agli utenti e generazione di ricette con Chef Ai
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "left", marginTop: "8px" }}>
                      <SvgIcon component={appI} inheritViewBox />
                      <Typography
                        variant="h3"
                        fontSize="16px"
                        color="#2F1847"
                        fontWeight="500"
                      sx={{ marginLeft: "16px" }}
                    >
                      App per i tuoi pazienti compresa nell’abbonamento
                    </Typography>
                  </Box>
                </Box>
                {props.showReferralDiscount ?
                  (
                    <Box>
                      <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
                      <stripe-pricing-table pricing-table-id="prctbl_1P5TBXGVY5FsKvGxyK3pUkWq"
                        publishable-key="pk_live_51O0l4VGVY5FsKvGxuelYc7SwlaEcUjCsiF38lEtOW7o9PKASop7TqYykIcZeyrSMNzs0iVzbxXCtMjJS9RY80POL00R2uFui5R">
                      </stripe-pricing-table>
                    </Box>
                  ) : showPromoPriceTable ?
                  (
                    <Box>
                      <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
                        <stripe-pricing-table pricing-table-id="prctbl_1P3zjdGVY5FsKvGxZ3gwURPD"
                          publishable-key="pk_live_51O0l4VGVY5FsKvGxuelYc7SwlaEcUjCsiF38lEtOW7o9PKASop7TqYykIcZeyrSMNzs0iVzbxXCtMjJS9RY80POL00R2uFui5R">
                        </stripe-pricing-table>
                    </Box>
                  ) :
                  (
                    <Box>
                      <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
                        <stripe-pricing-table pricing-table-id="prctbl_1P3xBJGVY5FsKvGxfypCHrUr"
                          publishable-key="pk_live_51O0l4VGVY5FsKvGxuelYc7SwlaEcUjCsiF38lEtOW7o9PKASop7TqYykIcZeyrSMNzs0iVzbxXCtMjJS9RY80POL00R2uFui5R">
                        </stripe-pricing-table>
                    </Box>
                  )
                }
              </Box>
            </CardContent>
            <CardActions disableSpacing>
              <Box display="flex" justifyContent="space-between" width="100%">
              </Box>
            </CardActions>
          </Card>
        ) :
        <Card sx={{
          maxWidth: 400,
          overflow: "hidden",
          height: "100%",
          borderRadius: "16px",
          border: "1px solid #dddddd",
          boxShadow: 'none'
        }}>
          <CardContent sx={{ marginTop: "16px" }}>
            {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                variant="h3"
                fontSize="20px"
                color="#2F1847"
                fontWeight="700"
              >
                Il tuo piano
              </Typography>
            </Box>
            <Divider
              sx={{
                marginTop: "32px",
                width: "100%",
                bgcolor: "rgba(193, 199, 205, 0.50)",
              }}
            /> */}
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                variant="h3"
                fontSize="16px"
                color="#2F1847"
                fontWeight="700"
              >
                Compila i tuoi dati personali
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "left", marginTop: "8px" }}>
              <Typography
                variant="h3"
                fontSize="16px"
                color="#2F1847"
                fontWeight="500"
              >
                Per noi è importante che tu sia un professionista della nutrizione prima di iscriverti al servizio.
              </Typography>
            </Box>
            <Divider
              sx={{
                marginTop: "16px",
                width: "100%",
                bgcolor: "transparent", // Imposta il colore di sfondo su trasparente
                border: 0, // Rimuove il bordo predefinito
                borderTop: "1px dashed rgba(193, 199, 205, 0.50)", // Imposta il bordo superiore come tratteggiato
              }}
            />
            <Box sx={{ display: "flex", justifyContent: "left", marginTop: "16px" }}>
              <Typography
                variant="h3"
                fontSize="16px"
                color="#2F1847"
                fontWeight="500"
              >
                Carica un tuo documento, indica a quale ordine fai parte con il tuo numero di iscrizione e la tua PEC.
                <Box sx={{ marginTop: "16px" }}>
                <Button
                  sx={{
                      background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
                    textTransform: 'none',
                      fontSize: "14px",
                      color: "white",
                    fontWeight: 600,
                      padding: "8px 16px",
                      borderRadius: "8px",
                    boxShadow: 'none',
                    '&:hover, &:active, &:focus': {
                      boxShadow: 'none'
                    }
                  }}
                  onClick={() => props.handleSwitchTab(0)}
                >
                  Aggiungi i dati mancanti
                </Button>
                </Box>
              </Typography>
            </Box>
          </CardContent>
          <CardActions disableSpacing>
            <Box display="flex" justifyContent="space-between" width="100%">
            </Box>
          </CardActions>
        </Card>
      )
        }
      </Box>

  );
}
