import React, { useState, useEffect } from "react";
import {
  Box,
  Toolbar,
  Button,
  Divider,
  Typography,
  FormControl,
  TextField,
  Select,
  MenuItem,
  Chip,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { BasicTooltip } from '@nivo/tooltip'
import { ResponsivePie } from '@nivo/pie'
import { useNavigate } from "react-router-dom";
import Header from "../../../components/Header";
import { Ingrediente } from "../details/index";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddIcon from "@mui/icons-material/Add";
import UploadDragAndDrop from "../../../components/UploadDragAndDrop";
import {
  useGetTagsQuery,
  usePostRicettaMutation
} from "../../../redux/state/api";
import { useForm, Controller } from "react-hook-form";
import IngredientAndQuantity from "../../../components/IngredientAndQuantity";

const CreazioneRicetta = () => {

  const categories = [
    { id: 1, name: "Colazione" },
    { id: 2, name: "Pranzo" },
    { id: 3, name: "Spuntino" },
    { id: 4, name: "Cena" },
  ];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [tipologiaPasto, setTipologiaPasto] = useState([]);
  const [quantity, setQuantity] = useState("");
  const [cookTime, setCookTime] = useState(0);
  const [prepTime, setPrepTime] = useState(0);
  const navigate = useNavigate();
  const [selectedTags, setSelectedTags] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [steps, setSteps] = useState([
  ]);
  const [imageFile, setImageFile] = useState("");
  const [macronutrients, setMacronutrients] = useState({
    carbohydrates: 0,
    proteins: 0,
    fats: 0,
    alcohol: 0,
    fibers: 0,
    sugars: 0,
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { data: tagsData, isLoading: isTagsLoading } = useGetTagsQuery();
  const [postRicetta, { isLoading: isPostLoading, isError }] =
    usePostRicettaMutation();

  const handleNavigateBack = () => {
    navigate("/ricette");
  };

  const handleSelectPasto = (event) => {
    const {
      target: { value },
    } = event;
    setTipologiaPasto(typeof value === "string" ? value.split(",") : value);
  };

  const handleChipClick = (tagId) => {
    if (selectedTags.includes(tagId)) {
      setSelectedTags(selectedTags.filter((id) => id !== tagId)); // Deselect tag
    } else {
      setSelectedTags([...selectedTags, tagId]); // Select tag
    }
  };

  const handleRemoveIngredient = (id) => {
    const updatedIngredients = ingredients.filter(
      (elem) => elem.ingredient.id !== id
    );
    setIngredients(updatedIngredients);
  };

  const handleAddStep = () => {
    const newStep = { description_it: "", description_en: "abce" };
    setSteps([...steps, newStep]);
  };

  const handleRemoveStep = (index) => {
    const updatedSteps = steps.filter((step, i) => i !== index);
    setSteps(updatedSteps);
  };

  const handleStepChange = (event, index) => {
    const updatedSteps = [...steps];
    updatedSteps[index].description_it = event.target.value;
    setSteps(updatedSteps);
  };

  const handleImageUpload = (base64Data) => {
    setImageFile(base64Data);
  };

  const onSubmit = async (formData) => {
    try {
      const simplifiedIngredients = ingredients.map(({ ingredient, quantity }) => ({ id: ingredient.id, quantity }))
      const filteredSteps = steps.filter((step) => {
        return step.description_it !== ""
      })
      formData.cook_time = cookTime;
      formData.prep_time = prepTime;
      formData.recipe_tags = selectedTags;
      formData.ingredients = simplifiedIngredients;
      formData.steps = filteredSteps;
      formData.image = imageFile;
      formData.categories = tipologiaPasto;
      const response = await postRicetta(formData);
      navigate(`/ricette/details/${response.data.recipe_id}`);
      window.location.reload();
    } catch (error) {
      console.error("Error occurred while creating recipe:", error);
    }
  };

  const nutrientPercentage = (nutrient, energy, factor) => {
    return parseFloat((100 * nutrient * factor / energy).toFixed(2));
  }
  const nutrientQuantity = (nutrient, quantity) => {
    return parseFloat((nutrient * quantity / 100).toFixed(2));
  }

  useEffect(() => {
    // Calculate total macronutrient values based on ingredients
    let energy = 0.00;
    let carbohydrates = 0.00;
    let proteins = 0.00;
    let fats = 0.00;
    let alcohol = 0.00;
    let fibers = 0.00;
    let sugars = 0.00;
    let quantity = 0.00;
    ingredients.forEach(({ ingredient: selectedIngredient, quantity: ingredientQuantity }) => {
      if (selectedIngredient) {
        energy += selectedIngredient.energy;
        carbohydrates += selectedIngredient.carbohydrates;
        proteins += selectedIngredient.proteins;
        fats += selectedIngredient.fats;
        alcohol += selectedIngredient.alcohol;
        fibers += selectedIngredient.fibers;
        sugars += selectedIngredient.sugars;
        quantity += ingredientQuantity;
      }
    })
    const totalNutrient = carbohydrates + proteins + fats + alcohol;
    const totalMacronutrients = {
      carbohydrates: {
        quantity: nutrientQuantity(carbohydrates, quantity),
        percentage: nutrientPercentage(carbohydrates, energy, 4) - nutrientPercentage(sugars, energy, 4)
      },
      proteins: {
        quantity: nutrientQuantity(proteins, quantity),
        percentage: nutrientPercentage(proteins, energy, 4)
      },
      fats: {
        quantity: nutrientQuantity(fats, quantity),
        percentage: nutrientPercentage(fats, energy, 9)
      },
      alcohol: {
        quantity: nutrientQuantity(alcohol, quantity),
        percentage: nutrientPercentage(alcohol, energy, 7)
      },
      fibers: {
        quantity: nutrientQuantity(fibers, quantity)
      },
      sugars: {
        quantity: nutrientQuantity(sugars, quantity),
        percentage: nutrientPercentage(sugars, energy, 4)
      },
      energy: {
        quantity: nutrientQuantity(energy, quantity),
      },
    }

    setMacronutrients(totalMacronutrients);
  }, [ingredients]);

  if (isTagsLoading || isPostLoading) {
    return <div className="loader-container">
      <div className="loader"></div>
    </div>
      ;
  }

  if (isError) {
    return <p>Errore durante la creazione della ricetta.</p>;
  }

  return (
    <Box
      m="1.5rem 2.5rem"
      width="90%"
      sx={{
        overflow: "hidden",
      }}
    >
      {" "}
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Button
          sx={{
            color: "#464868",
            textDecoration: "underline",
            fontSize: "14px",
            fontWeight: "500",
          }}
          onClick={handleNavigateBack}
        >
          Annulla
        </Button>
        <Button
          variant="contained"
          sx={{
            background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
            textTransform: 'none',
            fontSize: "14px",
            fontWeight: 600,
            padding: "8px 16px",
            borderRadius: "8px",
            boxShadow: 'none',
            '&:hover, &:active, &:focus': {
              boxShadow: 'none'
            }
          }}
          onClick={handleSubmit(onSubmit)}
          disabled={isPostLoading}
        >
          {isPostLoading ? (
            <CircularProgress color="secondary" />
          ) : (
            "Salva Ricetta"
          )}
        </Button>
      </Toolbar>
      <Box
        width="100%"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Box width="70%">
          <Box ml="20px">
            <Header title="Creazione ricetta" />
            <Box mt="50px">
              <Typography
                color="#2F1847"
                fontSize="24px"
                fontWeight="700"
                variant="h1"
              >
                Informazioni generali
              </Typography>
            </Box>
            <Box mt="20px">
              <FormControl
                variant="standard"
                sx={{ width: "48%", mr: "2%", mb: "2%" }}
              >
                <TextField
                  id="nome"
                  label="Nome ricetta"
                  variant="filled"
                  color="secondary"
                  {...register("name_it", { required: "Campo obbligatorio" })}
                />
                {errors.name_it && <p style={{ color: "red" }}>{errors.name_it.message}</p>}
              </FormControl>
              <FormControl variant="filled" sx={{ width: "48%", mr: "2%", mb: "2%" }}>
                <InputLabel id="pasto-select-label" color="secondary">
                  Tipologia di pasto
                </InputLabel>
                <Controller
                  name="categories"
                  control={control}
                  rules={{ required: 'Campo obbligatorio' }}
                  defaultValue={[]}
                  render={({ field }) => (
                    <Select
                      labelId="pasto-select-label"
                      id="selectPasto"
                      label="Tipologia di pasto"
                      multiple
                      value={tipologiaPasto}
                      onChange={(e) => {
                        field.onChange(e);
                        handleSelectPasto(e);
                      }}
                      color="secondary"
                      renderValue={(selected) => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                          {selected.map((value) => {
                            const selectedItem = categories.find(
                              (category) => category.id === value
                            );
                            return (
                              <Chip
                                key={selectedItem.id}
                                label={selectedItem.name}
                                style={{
                                  backgroundColor: 'rgba(70, 72, 104, 1)',
                                  color: "white",
                                  fontWeight: "500",
                                  borderRadius: "16px"
                                }}
                              />
                            );
                          })}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {categories.map(({ name, id }) => (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors.categories && <p style={{ color: "red" }}>{errors.categories.message}</p>}
              </FormControl>
              <FormControl
                variant="standard"
                sx={{ width: "48%", mr: "2%", mb: "2%" }}
              >
                <TextField
                  id="tdc"
                  label="Tempo di cottura"
                  variant="filled"
                  color="secondary"
                  type="number"
                  {...register("cook_time", { required: "Campo obbligatorio" })}
                  onChange={(event) => setCookTime(Number(event.target.value))}
                />
                {errors.cook_time && <p style={{ color: "red" }}>{errors.cook_time.message}</p>}
              </FormControl>
              <FormControl variant="filled" sx={{ width: "48%", mr: "2%", mb: "2%" }}>
                <TextField
                  id="tdp"
                  label="Tempo di preparazione"
                  variant="filled"
                  color="secondary"
                  type="number"
                  {...register("prep_time", { required: "Campo obbligatorio" })}
                  onChange={(event) => setPrepTime(Number(event.target.value))}
                />
                {errors.prep_time && <p style={{ color: "red" }}>{errors.prep_time.message}</p>}
              </FormControl>
              <FormControl
                variant="filled"
                sx={{ width: "98%", mr: "2%", mb: "2%" }}
              >
                <TextField
                  id="description"
                  label="Descrizione"
                  multiline
                  rows={10}
                  variant="filled"
                  color="secondary"
                  {...register("description_it", { required: "Campo obbligatorio" })}
                />
                {errors.description_it && <p style={{ color: "red" }}>{errors.description_it.message}</p>}
              </FormControl>
            </Box>
            <Divider
              sx={{
                marginTop: "48px",
                width: "95%",
                bgcolor: "transparent", // Imposta il colore di sfondo su trasparente
                border: 0, // Rimuove il bordo predefinito
                borderTop: "1px dashed rgba(193, 199, 205, 0.50)", // Imposta il bordo superiore come tratteggiato
              }}
            />
            <Box mt="48px">
              <Typography
                color="#2F1847"
                fontSize="24px"
                fontWeight="700"
                variant="h1"
              >
                Caratteristiche
              </Typography>
              <Typography
                color="#21272A"
                fontSize="16px"
                fontWeight="400"
                variant="h1"
                sx={{ mt: "10px" }}
              >
                Seleziona tra i badge le caratteristiche della ricetta
              </Typography>

              <Box display="flex" gap="1rem" mt="10px" flexWrap="wrap" flex="5">
                {tagsData.map(({ id, name }) => (
                  <Chip
                    label={name}
                    key={id}
                    onClick={() => handleChipClick(id)}
                    color={selectedTags.includes(id) ? "secondary" : "default"}
                    sx={{ cursor: "pointer" }}
                  />
                ))}
              </Box>
            </Box>
            <Divider
              sx={{
                marginTop: "48px",
                width: "95%",
                bgcolor: "transparent", // Imposta il colore di sfondo su trasparente
                border: 0, // Rimuove il bordo predefinito
                borderTop: "1px dashed rgba(193, 199, 205, 0.50)", // Imposta il bordo superiore come tratteggiato
              }}
            />
            <Box mt="48px">
              <Typography
                color="#2F1847"
                fontSize="24px"
                fontWeight="700"
                variant="h1"
              >
                Ingredienti
              </Typography>
              {ingredients.map(({ ingredient: selectedIngredient, quantity }) => {
                return (
                  <Box display="flex" gap="1rem" key={selectedIngredient.id}>
                    <Ingrediente
                      name={
                        selectedIngredient ? selectedIngredient.simple_name_it : ""
                      }
                      specific_info={
                        selectedIngredient ? selectedIngredient.specific_info : ""
                      }
                      quantity={quantity}
                      energy={selectedIngredient.energy}
                      categories_id={selectedIngredient.categories_id}
                    />
                    <Box
                      mt="20px"
                      display="inherit"
                      gap="1rem"
                      onClick={() => handleRemoveIngredient(selectedIngredient.id)}
                    >
                      <HighlightOffIcon
                        sx={{ cursor: "pointer", color: "red" }}
                      />
                    </Box>
                  </Box>
                );
              })}
              <IngredientAndQuantity
                ingredients={ingredients}
                quantity={quantity}
                setIngredients={setIngredients}
                setQuantity={setQuantity}
              />
            <Divider
                sx={{
                  marginTop: "48px",
                  width: "66%",
                  bgcolor: "transparent", // Imposta il colore di sfondo su trasparente
                  border: 0, // Rimuove il bordo predefinito
                  borderTop: "1px dashed rgba(193, 199, 205, 0.50)", // Imposta il bordo superiore come tratteggiato
                }}
              />
              <Box mt="48px">
                <Typography
                  color="#2F1847"
                  fontSize="24px"
                  fontWeight="700"
                  variant="h1"
                >
                  Procedimento
                </Typography>

                {steps.map((step, index) => (
                  <Box mt="20px" key={`step-index-${index}`}>
                    <Typography
                      color="#21272A"
                      fontSize="12px"
                      fontWeight="400"
                      variant="h1"
                    >
                      Step {index + 1}
                    </Typography>

                    <Box display="flex" gap="80px">
                      <FormControl
                        variant="filled"
                        sx={{ width: "80%", mr: "2%", mb: "2%" }}
                      >
                        <TextField
                          id={`procedimento${index}`}
                          label={`Step ${index + 1}`}
                          multiline
                          variant="filled"
                          color="secondary"
                          value={step.description_it}
                          onChange={(event) => handleStepChange(event, index)}
                        />
                      </FormControl>
                      <Box mt="20px">
                        <HighlightOffIcon
                          sx={{ cursor: "pointer", color: "red" }}
                          onClick={() => handleRemoveStep(index)}
                        />
                      </Box>
                    </Box>
                  </Box>
                ))}

                <Button
                  sx={{
                    background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
                    textTransform: 'none',
                    fontSize: "14px",
                    mt: "8px",
                    color: "white",
                    fontWeight: 600,
                    padding: "8px 16px",
                    borderRadius: "8px",
                    boxShadow: 'none',
                    '&:hover, &:active, &:focus': {
                      boxShadow: 'none'
                    }
                  }}
                  startIcon={<AddIcon />}
                  onClick={handleAddStep}
                >
                  Aggiungi Step
                </Button>
              </Box>
              <Box display="flex" justifyContent="flex-end" mt="20px">
                <Button
                  variant="contained"
                  sx={{
                    background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
                    textTransform: 'none',
                    fontSize: "14px",
                    fontWeight: 600,
                    padding: "8px 16px",
                    borderRadius: "8px",
                    boxShadow: 'none',
                    '&:hover, &:active, &:focus': {
                      boxShadow: 'none'
                    }
                  }}
                  disabled={isPostLoading}
                  onClick={handleSubmit(onSubmit)}
                >
                  {isPostLoading ? (
                    <CircularProgress color="secondary" />
                  ) : (
                    "Salva Ricetta"
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box width="30%">
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box height="332px" width="100%" borderRadius="6px" marginBottom="60px">
              <UploadDragAndDrop
                action={
                  "http://localhost:3000/ricette/creazionemodificaricetta"
                }
                text={"Trascina l'immagine"}
                onUpload={handleImageUpload}
              />
            </Box>
          </Box>
          <Typography
            color="#2F1847"
            fontSize="24px"
            fontWeight="700"
            variant="h1"
            sx={{ mt: "36px" }}
          >
            Valori (auto-completati)
          </Typography>
          <Box mt="20px" display="flex" justifyContent="flex-end">
            <Box
              sx={{
                width: "100%",
                height: "332px",
                borderRadius: "6px",
                border: "1px solid rgba(193, 199, 205, 1)",
              }}
            >
              <Box display="flex">
                <Box minWidth="350px" mt="40px" ml="25px" mr="25px">
                  <Typography
                    color="#464868"
                    fontSize="20px"
                    fontWeight="700"
                    variant="h1"
                  >
                    Macronutrienti
                  </Typography>
                </Box>
              </Box>
              <ResponsivePie
                colors={["#EDC047", "#4496A8", "#ED8047", "#BF2B2B", "#F2E68A", "#ffffff"]}
                tooltip={e => {
                  let { datum } = e; return <BasicTooltip
                    id={datum.id}
                    value={`${datum.formattedValue}%`}
                    enableChip={true}
                    color={datum.color}
                  />
                }}
                data={[
                  {
                    id: "Carbo",
                    value: macronutrients.carbohydrates.percentage,
                    label: `Carbo ${macronutrients.carbohydrates.quantity}g`,
                  },
                  {
                    id: "Proteine",
                    value: macronutrients.proteins.percentage,
                    label: `Proteine ${macronutrients.proteins.quantity}g`,
                  },
                  {
                    id: "Grassi",
                    value: macronutrients.fats.percentage,
                    label: `Grassi ${macronutrients.fats.quantity}g`,
                  },
                  {
                    id: "Alcol",
                    value: macronutrients.alcohol.percentage,
                    label: `Alcol ${macronutrients.alcohol.quantity}g`,
                  },
                  {
                    id: "Zuccheri",
                    value: macronutrients.sugars.percentage,
                    label: `Zuccheri ${macronutrients.sugars.quantity}g`,
                  },
                  {
                    id: "Fibre",
                    label: `Fibre ${macronutrients.fibers.quantity}g`,
                  },
                ]}
                margin={{ top: 30, right: 70, bottom: 100, left: -100 }}
                innerRadius={0.4}
                enableArcLinkLabels={false}
                padAngle={1}
                cornerRadius={5}
                enableArcLabels={false}
                theme={{ legends: { text: { fontSize: 18, fontFamily: "Geomanist" } } }}
                activeOuterRadiusOffset={8}
                legends={[
                  {
                    anchor: 'right',
                    direction: 'column',
                    justify: false,
                    translateX: -10,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemWidth: 80,
                    itemHeight: 20,
                    itemTextColor: '#464868',
                    itemDirection: 'left-to-right',
                    itemOpacity: 1,
                    symbolSize: 16,
                    symbolShape: 'square',
                  }
                ]}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CreazioneRicetta;
