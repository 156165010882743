import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Button,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import logo from "../../assets/Modalità_Isolamento.svg";
import frame from "../../assets/Frame.png";
import Header from "../../components/Header.jsx";
import UploadDragAndDrop from "../../components/UploadDragAndDrop.jsx";

const PrimoAccesso = () => {
  const [ordine, setOrdine] = React.useState("");

  const handleChangeSelectOrdine = (event) => {
    setOrdine(event.target.value);
  };
  const navigate = useNavigate();

  return (
    <Box
      height="100%"
      width="100%"
      sx={{
        display: "flex",
        justifyContent: "flex-between",
        overflow: "hidden",
      }}
    >
      <Box height="100vh" width="50%" sx={{ bgcolor: "#a1d392" }}>
        <Box component="img" alt="logo" src={logo} width="25%" m="1.5rem 2.5rem"></Box>
        <Box m="10.5rem 2.5rem">
          <Typography
            variant="h5"
            color="white"
            sx={{ fontWeight: "600", fontSize: "32px", marginTop: "-8px" }}
            width="70%"
          >
            Benvenuto nella Nutriboard Dietiamo
          </Typography>
          <Typography
            variant="body2"
            color="white"
            sx={{ fontSize: "16px", marginTop: "10px" }}
            width="50%"
          >
            Inizia subito a connetterti ai tuoi pazienti, effettua il Login
          </Typography>
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "-13%" }}
        >
          <Box component="img" alt="frame" src={frame} width="55%" sx={{ marginLeft: "-300px"}}></Box>
        </Box>
      </Box>
      <Box height="100vh" width="50%">
        <Box m="1.5rem 3.5rem">
          <Header title="I tuoi dati da professionista" color="black" />
          <Typography
            variant="body1"
            color="black"
            sx={{ fontSize: "16px", marginTop: "10px" }}
            width="55%"
          >
            Per avere un account verificato, dovrai... I campi contrassegnati
            dall'asterisco sono obbligatori in questa fase
          </Typography>
          <Typography
            variant="h3"
            color="#2F1847"
            sx={{ fontSize: "24px", marginTop: "30px", fontWeight: "700" }}
          >
            Documento d'identità*
          </Typography>
          <Box m="1.5rem 0">
            <UploadDragAndDrop
              action={"http://localhost:3000/primoaccesso"}
              text={"Trascina il file"}
            />
          </Box>
          <Typography
            variant="h3"
            color="#2F1847"
            sx={{ fontSize: "24px", marginTop: "30px", fontWeight: "700" }}
          >
            Curriculum Vitae
          </Typography>
          <Box m="1.5rem 0">
            <UploadDragAndDrop />
          </Box>
          <Typography
            variant="h3"
            color="#2F1847"
            sx={{ fontSize: "24px", marginTop: "30px", fontWeight: "700" }}
          >
            Dati
          </Typography>
          <Box m="1.5rem 0">
            <FormControl variant="filled" sx={{ m: 1, minWidth: 350 }}>
              <InputLabel id="ordine-select-label" sx={{  background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
                textTransform: 'none',
                fontWeight: 600,
                boxShadow: 'none',
                '&:hover, &:active, &:focus': {
                  boxShadow: 'none'
                }
              }}>
                Ordine*
              </InputLabel>
              <Select
                labelId="ordine-select-label"
                id="selectOrdine"
                label="ORDINE"
                value={ordine}
                onChange={handleChangeSelectOrdine}
                sx={{
                  background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
                  textTransform: 'none',
                  fontWeight: 600,
                  boxShadow: 'none',
                  '&:hover, &:active, &:focus': {
                    boxShadow: 'none'
                  }
                }}
              >
                <MenuItem value={"a"}>Lorem Ipsum</MenuItem>
                <MenuItem value={"b"}>Dolor</MenuItem>
                <MenuItem value={"c"}>Sit Amet</MenuItem>
                <MenuItem value={"d"}>Consecetur</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 350 }}>
              <TextField
                id="numeroIscrizione"
                label="NUMERO D'ISCRIZIONE ALL'ORDINE"
                defaultValue="000000000"
                variant="filled"
                sx={{  background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
                  textTransform: 'none',
                  fontWeight: 600,
                  boxShadow: 'none',
                  '&:hover, &:active, &:focus': {
                    boxShadow: 'none'
                  }
                }}
              />
            </FormControl>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "4rem",
              }}
            >
              <Typography
                variant="h5"
                color="#2F1847"
                sx={{
                  fontWeight: "600",
                  fontSize: "14px",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                width="50%"
                onClick={() => navigate("/dashboard")}
              >
                Completa in un secondo momento
              </Typography>
              <Button
                sx={{
                  background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
                  textTransform: 'none',
                  fontWeight: 600,
                  boxShadow: 'none',
                  '&:hover, &:active, &:focus': {
                    boxShadow: 'none'
                  }
                }}
                variant="contained"
                size="medium"
                onClick={() => navigate("/dashboard")}
              >
                Vai alla Dashboard
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PrimoAccesso;
