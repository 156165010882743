import { Typography, Box, Modal, FormControl, TextField, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../assets/group.png";

import React from "react";
import { usePostSendEmailMutation } from "../redux/state/api";

const EmailModal = ({ title, subTitle, type, open, handleClose }) => {
    const [emailContent, setEmailContent] = React.useState("");
    const [showResponseMessage, setShowResponseMessage] = React.useState(false);
    const [responseMessage, setResponseMessage] = React.useState("");
    const [responseSubMessage, setResponseSubMessage] = React.useState("");
    const [postSendEmail, { isLoading: isPostSendEmailLoading, isError: isErrorPostSendEmail }] =
        usePostSendEmailMutation();
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 500,
        height: 600,
        bgcolor: "background.paper",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        p: 4,
        overflow: "hidden",
    };

    const styleResponseMessage = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 500,
        height: 250,
        bgcolor: "background.paper",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        p: 4,
        overflow: "hidden",
    };

    const handleCloseResponseModal = () => {
        setShowResponseMessage(false);
        handleClose();
    }

    const handleSendEmail = () => {
        // Call the postSendEmail mutation with the necessary data
        postSendEmail({
            subject: type,
            message: emailContent,
        }).then((response) => {
            if (response.error) {
                // Handle the error (422 or other)
                console.error("Error sending email:", response.error);
                setResponseMessage("Errore nell'invio");
                setResponseSubMessage("Abbiamo riscontrato un errore nell'invio della segnalazione, riprova più tardi o inviaci un'email a info@dietiamo.it");
                setShowResponseMessage(true);
            } else {
                // Email sent successfully
                console.log("Email sent successfully!", response);
                setResponseMessage("Messaggio inviato correttamente");
                setResponseSubMessage("Grazie per la segnalazione!");
                setShowResponseMessage(true);
            }
        }).catch((error) => {
            // Handle error
            console.error("Error sending email:", error);
            setResponseMessage("Errore nell'invio");
            setResponseSubMessage("Abbiamo riscontrato un errore nell'invio della segnalazione, riprova più tardi o inviaci un'email a info@dietiamo.it");
            setShowResponseMessage(true);
        });
    };
    return (
        <Modal open={open} onClose={handleCloseResponseModal}>

            <Box sx={showResponseMessage ? styleResponseMessage : style}>
                {showResponseMessage ?
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }} >
                        <Typography
                            variant="h5"
                            color="black"
                            sx={{ fontWeight: "bold", marginTop: "20px" }}
                        >
                            {responseMessage}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="black"
                            sx={{ mt: "20px", textAlign: "center" }}
                        >
                            {responseSubMessage}
                        </Typography>
                        <Button
                            onClick={handleCloseResponseModal}
                            sx={{
                                background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
                                textTransform: 'none',
                                fontWeight: 600,
                                boxShadow: 'none',
                                '&:hover, &:active, &:focus': {
                                    boxShadow: 'none'
                                },
                                mt: "30px"
                            }} variant="contained" size="medium" style={{ borderRadius: "8px" }}>
                            Chiudi
                        </Button>
                    </Box> : (
                        <Box>
                <Box
                    width="100%"
                    height="5%"
                    sx={{ display: "flex", justifyContent: "end" }}
                >
                    <CloseIcon
                        sx={{ right: 0, cursor: "pointer" }}
                        onClick={handleClose}
                    />
                </Box>
                <Box
                    width="100%"
                    height="20%"
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Box component="img" alt="logo" src={logo} width="40%" m="1.5rem 2.5rem"></Box>
                </Box>
                <Box
                    width="100%"
                    height="10%"
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        variant="h5"
                        color="black"
                        sx={{ fontWeight: "bold", marginTop: "10px" }}
                    >
                        {title}
                    </Typography>
                </Box>
                <Box
                    width="100%"
                    height="10%"
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        variant="body2"
                        color="black"
                        sx={{ marginTop: "10px", ml: "10px", textAlign: "center" }}
                    >
                        {subTitle}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <FormControl
                        variant="filled"
                        sx={{ m: 1, minWidth: 400, marginLeft: "-5px" }}
                    >
                        <TextField
                            id="description"
                            label="Inserisci qui il tuo messaggio"
                            multiline
                            rows={6}
                            variant="filled"
                            color="secondary"
                            onChange={(e) => setEmailContent(e.target.value)}
                        />
                    </FormControl>
                </Box>
                <Box
                    width="100%"
                    marginTop="50px"
                    sx={{ display: "flex", justifyContent: "space-between" }}
                >
                    <Typography
                        variant="h7"
                        color="black"
                        sx={{
                            fontWeight: "bold",
                            textDecoration: "underline",
                            cursor: "pointer",
                        }}
                        onClick={handleClose}
                    >
                        Annulla
                    </Typography>
                    <Button
                        onClick={handleSendEmail}
                        sx={{
                            background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
                            textTransform: 'none',
                            fontWeight: 600,
                            boxShadow: 'none',
                            '&:hover, &:active, &:focus': {
                                boxShadow: 'none'
                            }
                        }} variant="contained" size="medium" style={{ borderRadius: "8px" }}>
                        Invia
                    </Button>
                </Box>
            </Box>
                    )}
            </Box>
        </Modal>
    );
};

export default EmailModal;
