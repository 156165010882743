import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Typography,
} from "@mui/material";

import React from "react";

const MetricsExplanationDialog = ({ open, handleClose, title, metricsInfo }) => {

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="info-dialog-title" PaperProps={{
            sx: { minWidth: "40%" }
        }}>
            <DialogTitle id="info-dialog-title" color="#2F1847" fontSize="24px" fontWeight="700">
                {title || "Metriche"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText color="#2F1847" fontSize="20px" fontWeight="600" sx={{ mb: 1 }}>
                    Di seguito la letteratura di riferimento per le formule utilizzate
                </DialogContentText>
                {metricsInfo.map(info => (
                    <DialogContentText>
                        <Typography component="span" color="#2F1847" fontSize="16px" fontWeight="500">
                            {`${info.metric}: `}
                        </Typography>
                        {info.description}
                        <Typography component="span" color="#2F1847" sx={{ whiteSpace: 'pre-line' }}>
                            {info.paper}
                        </Typography>
                    </DialogContentText>
                ))}
            </DialogContent>
        </Dialog>
    );
};

export default MetricsExplanationDialog;