import React from "react";
import {
    Box,
    FormControlLabel,
    FormGroup,
    MenuItem,
    Typography,
    Select,
    Switch,
    Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
    bd_dw,
    bd_jp_7p,
    bd_jp_3p,
    bd_thorland,
    bd_lohman,
    bd_katch,
    bd_sloan,
    bd_forsyth,
    percentage_siri,
    percentage_brozek,
    percentage_evans_3p,
    percentage_slaughter,
    sum_skinfolds_body,
    sum_skinfolds_limbs,
    sum_skinfolds_leg,
    sum_skinfolds_arm,
    sum_skinfolds,
    percentage_franco,
    fm,
    ffm,
    whr
} from "../../utils/measurements_metrics"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { MeasurementMultiLineChart } from "../MeasurementLineChart";
import MetricsExplanationDialog from "./MetricsExplanationDialog";

const renderNumWith1Decimals = (params) => {
    if (params.value) {
        const value = Number(params.value).toFixed(1);
        return value;
    }
}


export function SkinfoldMetricsSection({ visitsData, userInfo }) {
    const [lineChartLinesBodyDensity, setLineChartLinesBodyDensity] = React.useState({
        peso: {
            color: "#4496A8",
            hide: false,
            name: "Peso"
        },
        massa_grassa: {
            color: "#BF2B2B",
            hide: false,
            name: "Massa grassa",
        },
        massa_magra: {
            color: "#68BE6A",
            hide: false,
            name: "Massa magra",
        }
    })
    const [metricToUse, setMetricToUse] = React.useState("dw");
    const [showMetricsPercentage, setShowMetricsPercentage] = React.useState(false);
    const metrics =
    {
        "dw": "Durnin-Womersley",
        "jp_7p": "Jackson-Pollock 7 pliche",
        "jp_3p": "Jackson-Pollock 3 pliche",
        "thorland": "Thorland",
        "lohman": "Lohman",
        "katch": "Katch",
        "sloan": "Sloan",
        "forsyth": "Forsyth",
        "evans_3p": "Evans",
        "slaughter": "Slaughter",
    };
    const computeAge = (birthDate) => {
        const currentDate = new Date();

        let age = currentDate.getFullYear() - birthDate.getFullYear();

        const currentMonth = currentDate.getMonth();
        const birthMonth = birthDate.getMonth();
        const currentDay = currentDate.getDate();
        const birthDay = birthDate.getDate();

        if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDay < birthDay)) {
            age--;
        }

        return age;
    }

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        const day = date.getDate().toString().padStart(2, '0'); // Get the day and pad it with leading zero if necessary
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get the month (zero-based) and pad it with leading zero if necessary
        const year = date.getFullYear(); // Get the full year

        return `${day}/${month}/${year}`;
    }
    const bodyDensityData = visitsData.data.map((item) => {
        if (!item.skinfold_measurement) {
            return {};
        }
        const gender = userInfo.gender
        const weight = item.measurement.weight;
        const age = computeAge(new Date(userInfo.birth_date));
        const {
            pectoral, mid_axillary, midclavicular, anterior_axillary, abdominal, subscapular, bicipital, tricipital, thigh, calf
        } = item.skinfold_measurement
        const bodyDensityDw = bd_dw(gender, bicipital, anterior_axillary, tricipital, subscapular);
        const bodyDensityJp7p = bd_jp_7p(gender, age, pectoral, mid_axillary, anterior_axillary, abdominal, tricipital, subscapular, thigh);
        const bodyDensityJp3p = bd_jp_3p(gender, age, pectoral, anterior_axillary, abdominal, tricipital, thigh);
        const bodyDensityThorland = bd_thorland(gender, pectoral, mid_axillary, anterior_axillary, abdominal, tricipital, subscapular, thigh, calf);
        const bodyDensityLohman = bd_lohman(abdominal, tricipital, subscapular);
        const bodyDensityKatch = bd_katch(abdominal, tricipital, subscapular);
        const bodyDensitySloan = bd_sloan(subscapular, thigh);
        const bodyDensityForsyth = bd_forsyth(mid_axillary, abdominal, tricipital, subscapular);
        const percentageBodyDensityDw = percentage_siri(bodyDensityDw)
        const percentageBodyDensityJp7p = percentage_siri(bodyDensityJp7p)
        const percentageBodyDensityJp3p = percentage_siri(bodyDensityJp3p)
        const percentageBodyDensityThorland = percentage_siri(bodyDensityThorland)
        const percentageBodyDensityLohman = percentage_siri(bodyDensityLohman)
        const percentageBodyDensityKatch = percentage_brozek(bodyDensityKatch)
        const percentageBodyDensitySloan = percentage_brozek(bodyDensitySloan)
        const percentageBodyDensityForsyth = percentage_siri(bodyDensityForsyth)
        const percentageBodyDensityEvans3p = percentage_evans_3p(gender, userInfo.ethnicity, pectoral, anterior_axillary, abdominal, tricipital, thigh)
        const percentageBodyDensitySlaughter = percentage_slaughter(gender, tricipital, calf)
        const fmDw = (percentageBodyDensityDw / 100) * weight;
        const fmJp7p = (percentageBodyDensityJp7p / 100) * weight;
        const fmJp3p = (percentageBodyDensityJp3p / 100) * weight;
        const fmThorland = (percentageBodyDensityThorland / 100) * weight;
        const fmLohman = (percentageBodyDensityLohman / 100) * weight;
        const fmKatch = (percentageBodyDensityKatch / 100) * weight;
        const fmSloan = (percentageBodyDensitySloan / 100) * weight;
        const fmForsyth = (percentageBodyDensityForsyth / 100) * weight;
        const fmEvans3p = (percentageBodyDensityEvans3p / 100) * weight;
        const fmSlaughter = (percentageBodyDensitySlaughter / 100) * weight;
        return {
            visit_date: formatDate(item.visit_date),
            weight: weight,
            dw: { value: parseFloat(fmDw.toFixed(1)), percentage: parseFloat(percentageBodyDensityDw.toFixed(1)) },
            jp_7p: { value: parseFloat(fmJp7p.toFixed(1)), percentage: parseFloat(percentageBodyDensityJp7p.toFixed(1)) },
            jp_3p: { value: parseFloat(fmJp3p.toFixed(1)), percentage: parseFloat(percentageBodyDensityJp3p.toFixed(1)) },
            thorland: { value: parseFloat(fmThorland.toFixed(1)), percentage: parseFloat(percentageBodyDensityThorland.toFixed(1)) },
            lohman: { value: parseFloat(fmLohman.toFixed(1)), percentage: parseFloat(percentageBodyDensityLohman.toFixed(1)) },
            katch: { value: parseFloat(fmKatch.toFixed(1)), percentage: parseFloat(percentageBodyDensityKatch.toFixed(1)) },
            sloan: { value: parseFloat(fmSloan.toFixed(1)), percentage: parseFloat(percentageBodyDensitySloan.toFixed(1)) },
            forsyth: { value: parseFloat(fmForsyth.toFixed(1)), percentage: parseFloat(percentageBodyDensityForsyth.toFixed(1)) },
            evans_3p: { value: parseFloat(fmEvans3p.toFixed(1)), percentage: parseFloat(percentageBodyDensityEvans3p.toFixed(1)) },
            slaughter: { value: parseFloat(fmSlaughter.toFixed(1)), percentage: parseFloat(percentageBodyDensitySlaughter.toFixed(1)) },
        }
    }).filter(item => Object.keys(item).length > 0);
    const bd_dwData = visitsData.data.map((item) => ({
        visit_date: item.visit_date,
        bd_dw: bd_dw(),
    }))
    const bd_jp_7pData = visitsData.data.map((item) => ({
        visit_date: item.visit_date,
        bd_jp_7p: bd_jp_7p(),
    }))
    const bd_jp_3pData = visitsData.data.map((item) => ({
        visit_date: item.visit_date,
        bd_jp_3p: bd_jp_3p(),
    }))
    const bd_thorlandData = visitsData.data.map((item) => ({
        visit_date: item.visit_date,
        bd_thorland: bd_thorland(),
    }))
    const bd_lohmanData = visitsData.data.map((item) => ({
        visit_date: item.visit_date,
        bd_lohman: bd_lohman(),
    }))
    const bd_katchData = visitsData.data.map((item) => ({
        visit_date: item.visit_date,
        bd_katch: bd_katch(),
    }))
    const bd_sloanData = visitsData.data.map((item) => ({
        visit_date: item.visit_date,
        bd_sloan: bd_sloan(),
    }))
    const bd_forsythData = visitsData.data.map((item) => ({
        visit_date: item.visit_date,
        bd_forsyth: bd_forsyth(),
    }))
    const percentage_siriData = visitsData.data.map((item) => ({
        visit_date: item.visit_date,
        percentage_siri: percentage_siri(),
    }))
    const percentage_brozekData = visitsData.data.map((item) => ({
        visit_date: item.visit_date,
        percentage_brozek: percentage_brozek(),
    }))
    const percentage_evans_3pData = visitsData.data.map((item) => ({
        visit_date: item.visit_date,
        percentage_evans_3p: percentage_evans_3p(),
    }))
    const percentage_slaughterData = visitsData.data.map((item) => ({
        visit_date: item.visit_date,
        percentage_slaughter: percentage_slaughter(),
    }))
    const sum_skinfolds_bodyData = visitsData.data.map((item) => ({
        visit_date: item.visit_date,
        sum_skinfolds_body: sum_skinfolds_body(),
    }))
    const sum_skinfolds_limbsData = visitsData.data.map((item) => ({
        visit_date: item.visit_date,
        sum_skinfolds_limbs: sum_skinfolds_limbs(),
    }))
    const sum_skinfolds_legData = visitsData.data.map((item) => ({
        visit_date: item.visit_date,
        sum_skinfolds_leg: sum_skinfolds_leg(),
    }))
    const sum_skinfolds_armData = visitsData.data.map((item) => ({
        visit_date: item.visit_date,
        sum_skinfolds_arm: sum_skinfolds_arm(),
    }))
    const sum_skinfoldsData = visitsData.data.map((item) => ({
        visit_date: item.visit_date,
        sum_skinfolds: sum_skinfolds(),
    }))
    const percentage_francoData = visitsData.data.map((item) => ({
        visit_date: item.visit_date,
        percentage_franco: percentage_franco(),
    }))
    const fmData = visitsData.data.map((item) => ({
        visit_date: item.visit_date,
        fm: fm(),
    }))
    const ffmData = visitsData.data.map((item) => ({
        visit_date: item.visit_date,
        ffm: ffm(),
    }))
    const whrData = visitsData.data.map((item) => ({
        visit_date: item.visit_date,
        whr: whr(),
    }))

    const skinfoldRows = visitsData.data.map((item) => {
        const skinfold_measurement = { ...item.skinfold_measurement };
        const skinfold_sum = Object.values(skinfold_measurement).reduce((acc, val) => {
            return acc + val;
        }, 0);
        skinfold_measurement["sum"] = skinfold_sum
        skinfold_measurement["visit_date"] = new Date(item.visit_date);
        skinfold_measurement["id"] = item.id;

        return skinfold_measurement;
    });
    const skinfoldColumns = [
        {
            field: "visit_date",
            headerName: "Data Visita",
            type: "date",
            flex: 1,
            headerClassName: "theme",
        },
        { field: "bicipital", headerName: "Bicipitale", flex: 1, headerClassName: "theme", sortable: false, valueFormatter: renderNumWith1Decimals },
        { field: "pectoral", headerName: "Pettorale", flex: 1, headerClassName: "theme", sortable: false, valueFormatter: renderNumWith1Decimals },
        { field: "mid_axillary", headerName: "Ascellare", flex: 1, headerClassName: "theme", sortable: false, valueFormatter: renderNumWith1Decimals },
        { field: "midclavicular", headerName: "Sovrailiaca mediana", flex: 1.5, headerClassName: "theme", sortable: false, valueFormatter: renderNumWith1Decimals },
        { field: "anterior_axillary", headerName: "Sovrailiaca anteriore", flex: 1.5, headerClassName: "theme", sortable: false, valueFormatter: renderNumWith1Decimals },
        { field: "abdominal", headerName: "Addominale", headerClassName: "theme", sortable: false, valueFormatter: renderNumWith1Decimals },
        { field: "tricipital", headerName: "Tricipitale", flex: 1, headerClassName: "theme", sortable: false, valueFormatter: renderNumWith1Decimals },
        { field: "subscapular", headerName: "Sottoscapolare", flex: 1, headerClassName: "theme", sortable: false, valueFormatter: renderNumWith1Decimals },
        { field: "thigh", headerName: "Coscia", flex: 1, headerClassName: "theme", sortable: false, valueFormatter: renderNumWith1Decimals },
        { field: "calf", headerName: "Polpaccio", flex: 1, headerClassName: "theme", sortable: false, valueFormatter: renderNumWith1Decimals },
        { field: "sum", headerName: "Somma pliche", flex: 1, headerClassName: "theme", sortable: false, valueFormatter: renderNumWith1Decimals },
    ];
    const handleChangeSwitchPercentage = () => {
        setShowMetricsPercentage(!showMetricsPercentage);
    }

    const [openMetricsDialog, setOpenMetricsDialog] = React.useState(false);
    const handleClickOpenMetricsDialog = () => {
        setOpenMetricsDialog(true);
    };

    const handleCloseMetricsDialog = () => {
        setOpenMetricsDialog(false);
    };
    return (
        <Box mt="32px" ml="-18px">
            <Box mb="20px">
                <Typography color="#2F1847" fontSize="24px" fontWeight="700" sx={{
                    display: 'flex', // Make sure the content is aligned
                    alignItems: 'center' // Align items vertically
                }}>
                    Plicometria
                    <Tooltip title="Clicca qui per dettagli sulle formule utilizzate" arrow>
                        <InfoOutlinedIcon sx={{ ml: 1, cursor: 'pointer' }} aria-label="information" onClick={handleClickOpenMetricsDialog} />
                    </Tooltip>
                </Typography>
                <MetricsExplanationDialog
                    open={openMetricsDialog}
                    handleClose={handleCloseMetricsDialog}
                    metricsInfo={[
                        {
                            metric: "Durnin-Womersley",
                            paper: "Durnin JVGA, Womersley J (1974). Body fat assesed from total body density and its estimation from skinfold thickness: measuraments on 481 men and women aged 16 to 72 years, Br J Nutr; 32: 77-97."
                        },
                        {
                            metric: "Jackson-Pollock 7 pliche",
                            paper: "Jackson AS, Pollock ML (1978). Generalized equations for predicting body density of men, Br J Nutr; 40: 497-504. \n Jackson AS, Pollock ML, Ward A (1980). Generalized equations for predicting body density of women, Med Sci Sports Exerc."
                        },
                        {
                            metric: "Jackson-Pollock 3 pliche",
                            paper: "Jackson AS, Pollock ML (1978). Generalized equations for predicting body density of men, Br J Nutr; 40: 497-504. \n Jackson AS, Pollock ML, Ward A (1980). Generalized equations for predicting body density of women, Med Sci Sports Exerc."
                        },
                        {
                            metric: "Thorland",
                            paper: "Thorland WG, Johnson GO, Tharp GD, Housh TJ, Cisar CJ (1984). Estimation of body density in adolescent athletes, Human Biology; 56(3): 439-48."
                        },
                        {
                            metric: "Lohman",
                            paper: "Lohman TG (1981). Skinfolds and body density and their relation to body fatness: a review. Hum Biol 53: 181-225."
                        },
                        {
                            metric: "Katch",
                            paper: "Katch FI, McArdle WD. Prediction of body density from simple anthropometric measurements in college-age men and women. Human Biology 1973; 45(3): 445-54."
                        },
                        {
                            metric: "Sloan",
                            paper: "Sloan A.W.: Estimation of body fat in young men. Journal of Applied Physiology. 1967;23:311-315."
                        },
                        {
                            metric: "Forsyth",
                            paper: "Forsyth, H. L. & Sinning, W. E. (1973). The anthropometric estimation of body density and lean body weight of male athletes. Medicine and Science in Sports, 5 (3), 174-180."
                        },
                        {
                            metric: "Evans",
                            paper: "Evans EM, Prior BM, Modlesky CM (2005). A mathematical method to estimate body composition in tall individuals using DXA. Med Sci Sports Exerc; 37: 1211-1215."
                        },
                        {
                            metric: "Slaughter",
                            paper: "Slaughter MH, Lohman TG, Boileau RA, Horswill CA, Stillman RJ, Van Loan MD, Bemben DA (1988). Skinfold equations for estimation of body fatness in children and youth, Human Biology; 60(5): 709-23."
                        }
                    ]}
                />
            </Box>
            <Box display="flex" alignItems="center" justifyContent="start" mb="20px" >
                <Select
                    style={{ marginRight: '16px' }}
                    color="secondary"
                    value={metricToUse}
                    onChange={(event) => setMetricToUse(event.target.value)}
                >
                    {Object.entries(metrics).map(([metricKey, metricValue]) => (
                        <MenuItem key={metricKey} value={metricKey}>
                            {metricValue}
                        </MenuItem>
                    ))}
                </Select>
                <FormGroup>
                    <FormControlLabel control={<Switch color="secondary" onChange={handleChangeSwitchPercentage} />} label="%" />
                </FormGroup>
            </Box>
            <MeasurementMultiLineChart
                data={bodyDensityData.map(item => {
                    if (showMetricsPercentage) {
                        return {
                            visit_date: item.visit_date,
                            massa_grassa: item[metricToUse]["percentage"],
                            massa_magra: 100 - item[metricToUse]["percentage"],
                        }
                    }
                    return {
                        visit_date: item.visit_date,
                        peso: item.weight,
                        massa_grassa: item[metricToUse]["value"],
                        massa_magra: item.weight - item[metricToUse]["value"],
                    }
                }
                )}
                xAxisDataKey="visit_date"
                yAxisLabelValue={`${metrics[metricToUse]} ${showMetricsPercentage ? "(%)" : "(kg)"}`}
                lines={lineChartLinesBodyDensity}
                setLines={setLineChartLinesBodyDensity}
                isXAxisDate={true}
                xAxisLabel="Data visita"
            />
            <Box mt="20px" ml="-18px" pl={3}>
                <DataGrid
                    rows={skinfoldRows.sort((a, b) => new Date(b.visit_date) - new Date(a.visit_date)).filter((elem) =>
                        !!elem["bicipital"] |
                        !!elem["pectoral"] |
                        !!elem["mid_axillary"] |
                        !!elem["midclavicular"] |
                        !!elem["anterior_axillary"] |
                        !!elem["abdominal"] |
                        !!elem["tricipital"] |
                        !!elem["subscapular"] |
                        !!elem["thigh"] |
                        !!elem["calf"]
                    )}
                    columns={skinfoldColumns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5,
                            },
                        },
                    }}
                    pageSizeOptions={[5]}
                    sx={{
                        "& .theme": {
                            backgroundColor: "rgba(70, 72, 104, 0.10)",
                            color: "#2F1847",
                        },
                    }}
                    hideFooterSelectedRowCount
                    disableColumnMenu
                    disableRowSelectionOnClick
                />
            </Box>
        </Box>
    );
};

