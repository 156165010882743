import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  InputBase,
  Toolbar,
  FormControl,
  InputLabel,
  IconButton,
  Modal,
  ListItemText,
  ListItemIcon,
  Alert,
  AlertTitle,
  Collapse
} from "@mui/material";
import { Search } from "@mui/icons-material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UndoIcon from '@mui/icons-material/Undo';
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import { DataGrid } from "@mui/x-data-grid";
import Header from "../../components/Header";
import FlexBetween from "../../components/FlexBetween";
import { useGetAllClientsQuery } from "../../redux/state/api";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDeleteClienteMutation, usePutClientMutation } from "../../redux/state/api";
import dayjs from "dayjs";
import "dayjs/locale/it";

dayjs.locale('it');

const Pazienti = () => {
  const [tipologiaUtente, setTipologiaUtente] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmDeleteVisit, setOpenConfirmDeleteVisit] = React.useState(false);

  const [deleteCliente, { isLoading: isDeleteCliente, isError }] =
    useDeleteClienteMutation();

  const navigate = useNavigate();

  const { data, isLoading } = useGetAllClientsQuery();

  const [
    putClient,
    { isLoading: isPutClientLoading, isError: isPutClientError },
  ] = usePutClientMutation();

  const handleDelete = async (id) => {
    try {
      setOpenModal(false);
      await deleteCliente(id);
      window.location.reload();
    } catch (err) {
      console.error("Error occurred while deleting:", err);
    }
  };

  const handleAddVisit = (id) => {
    setOpenModal(false);
    navigate(`/pazienti/addVisit/${id}`);
  };

  const handleSelectChange = (event) => {
    setTipologiaUtente(event.target.value);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedRow(null);
    setOpenConfirmDeleteVisit(false)
  };

  const handleOpenModal = (row) => {
    setOpenModal(true);
    setSelectedRow(row);
  };

  const handleAddFirstClient = () => {
    navigate("/pazienti/aggiuntanuovo");
  };

  const handleChangeTypology = async (id, typology) => {
    const newTypology = typology === "active" ? "inactive" : "active";
    try {
      await putClient({ data: { typology: newTypology }, id: id });
      setOpenModal(false);
      window.location.reload();
    } catch (err) {
      console.error("Error while editing client's typology:", err);
    }
  }

  const columns = [
    {
      field: "surname",
      headerClassName: "theme",
      headerName: <div style={{ marginRight: '10px' }}>Cognome</div>, // Aggiunto un margine a destra
      flex: 20,
      renderCell: (params) => (
        <Link
          style={{ color: "#000", fontWeight: "bold", textDecoration: "none" }}
          to={`/pazienti/details/${params.row.id}`}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "name",
      headerClassName: "theme",
      headerName: <div style={{ marginRight: '10px' }}>Nome</div>, // Aggiunto un margine a destra
      flex: 20,
      renderCell: (params) => (
        <Link
          style={{ color: "#000", fontWeight: "bold", textDecoration: "none" }}
          to={`/pazienti/details/${params.row.id}`}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "lastVisitDate",
      headerName: <div style={{ marginRight: '10px' }}>Ultima visita</div>, // Aggiunto un margine a destra
      headerClassName: "theme",
      type: "date",
      flex: 20,
      sortable: true,
    },
    {
      field: "createdAt",
      headerName: <div style={{ marginRight: '10px' }}>Data di aggiunta</div>, // Aggiunto un margine a destra
      headerClassName: "theme",
      type: "date",
      flex: 20,
      sortable: true,
    },
    {
      field: "typology",
      headerClassName: "theme",
      headerName: <div style={{ marginRight: '10px' }}>Stato paziente</div>, // Aggiunto un margine a destra
      flex: 20,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <ListItemText primary={params.row.typology === "active" ? "attivo" : "non attivo"} />
          <ListItemIcon>
            <IconButton onClick={() => handleOpenModal(params.row)}>
              <MoreHorizIcon />
            </IconButton>
          </ListItemIcon>
        </div>
      ),
    },
  ];

  if (isLoading) {
    return <div class="loader-container">
      <div class="loader"></div>
    </div>
      ;
  }

  if (isDeleteCliente) {
    return <p>Deleting...</p>;
  }

  if (isError) {
    return <p>Error occurred while deleting.</p>;
  }

  const allRows = data.data.map((item) => ({
    id: item.id,
    surname: item.surname,
    name: item.name,
    lastVisitDate: new Date(item.last_visit_date),
    createdAt: new Date(item.created_at),
    typology: item.typology,
  }));

  const filteredRows = allRows.filter((row) => {
    const matchesTypology =
      !tipologiaUtente || row.typology === tipologiaUtente;
    const matchesSearch = row.surname
      .toLowerCase()
      .includes(searchInput.toLowerCase());
    return matchesTypology && matchesSearch;
  });

  return (
    <Box m="1.5rem 2.5rem" >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/*LEFT SIDE*/}
        <Header title="Pazienti" />

        {/*RIGHT SIDE*/}
        <Button
          sx={{
            background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
            textTransform: 'none',
            fontWeight: 600,
            padding: "8px 16px",
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none'
            }
          }}
          style={{ borderRadius: "8px" }}
          variant="contained"
          size="medium"
          onClick={() => navigate("/pazienti/aggiuntanuovo")}
        >
          Aggiungi paziente
        </Button>
      </Toolbar>
      <Box mt="48px" ml="18px">
        <Typography color="#2F1847" fontSize="24px" fontWeight="700">
          Tutti i pazienti
        </Typography>
      </Box>
      <Toolbar
        sx={{
          marginLeft: "-18px",
          marginTop: "12px",
          justifyContent: "space-between",
        }}
      >
        <FormControl sx={{ m: 1, minWidth: 300 }} >
          <InputLabel id="tipologia-pasto-select-label" color="secondary">
            Tipologia paziente
          </InputLabel>
          <Select
            labelId="tipologia-pasto-select-label"
            id="selectTipPasto"
            label="Tipologia di pasto"
            value={tipologiaUtente}
            onChange={handleSelectChange}
            color="secondary"
          >
            <MenuItem value={""}>Mostra tutti</MenuItem>
            <MenuItem value={"active"}>Attivo</MenuItem>
            <MenuItem value={"inactive"}>Non Attivo</MenuItem>
          </Select>
        </FormControl>
        <FlexBetween gap="1.5rem">
          <FlexBetween
            backgroundColor={"transparent"}
            borderRadius="9px"
            gap="3rem"
            p="0.5rem 1.5rem"
            border={"1px solid var(--border, rgba(193, 199, 205, 0.50))"}
          >
            <InputBase
              placeholder="Cerca tra i pazienti..."
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <IconButton>
              <Search sx={{ color: "black" }} />
            </IconButton>
          </FlexBetween>
        </FlexBetween>
      </Toolbar>
      <Box mt="40px" ml="10px">
        {filteredRows.length > 0 ?
          <DataGrid
            className="dx-datagrid-focus-overlay"
            rows={filteredRows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            hideFooterSelectedRowCount
            disableColumnMenu
            disableRowSelectionOnClick
            sx={{
              "& .theme": {
                color: "#2F1847",

              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-cell:focus-within': {
                outline: 'none',
              },
              '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
                outline: 'none',
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: 'rgba(70, 72, 104, 0.10)', // Sostituisci con il colore dello sfondo desiderato per l'header
                // altri stili necessari per l'header
              },
            }}
          />
          : <Box
            display="flex"
            mt="20px"
            ml="6px"
            justifyContent="left">
            <Typography
              color="#2f1847"
              fontSize="18px"
              fontWeight="400"
              variant="h1"
            >
              {tipologiaUtente === "inactive" ? "Nessun paziente non attivo" : (<span> Non ci sono ancora paziente.{" "}
                <u><strong onClick={handleAddFirstClient}>
                  Aggiungi il tuo primo paziente
                </strong></u>
                {" "}adesso! </span>)}
            </Typography>
          </Box>
        }
      </Box>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: 'fixed', // Usa fixed per posizionare la modale in relazione alla viewport
            top: '45%',       // Posiziona la parte superiore della modale a metà della viewport
            left: '50%',      // Posiziona il lato sinistro della modale a metà della viewport
            transform: 'translate(-50%, -50%)', // Sposta la modale indietro di metà della sua altezza e larghezza

          }}
        >
          {selectedRow && (
            <Box
              sx={{
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "8px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                position: "relative",
              }}
            >
              <Typography variant="h6" mb="8px">
                Paziente: {selectedRow.surname} {selectedRow.name}
              </Typography>

              {!openConfirmDeleteVisit && <Button
                sx={{
                  marginRight: 2,
                  background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
                  textTransform: 'none',
                  borderRadius: "8px",
                  fontWeight: 600,
                  padding: "8px 16px",
                  color: "white",
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: 'none'
                  }
                }}
                startIcon={<AddIcon />}
                onClick={() => handleAddVisit(selectedRow.id)}
              >
                Aggiungi visita
              </Button>}

              {!openConfirmDeleteVisit && <div className="gradient-border-wrapper" style={{ marginRight: "16px" }}>
                <Button
                  sx={{ marginRight: 2, fontWeight: "600", textTransform: "none", color: "#B0D99C" }}
                  startIcon={<EditIcon />}
                  className="gradient-button"
                  onClick={() => navigate(`/pazienti/modifica/${selectedRow.id}`)}
                >
                  Modifica
                </Button>
              </div>}

              {!openConfirmDeleteVisit && <div className="gradient-border-wrapper" style={{ marginRight: "16px" }}>
                <Button
                  sx={{ marginRight: 2, fontWeight: "600", textTransform: "none", color: "#B0D99C" }}
                  startIcon={selectedRow.typology === "active" ? <PowerOffIcon /> : <PowerIcon />}
                  className="gradient-button"
                  onClick={() => handleChangeTypology(selectedRow.id, selectedRow.typology)}
                >
                  {selectedRow.typology === "active" ? "Disattiva" : "Attiva"}
                </Button>
              </div>}

              {!openConfirmDeleteVisit && <div className="gradient-border-wrapper-r" style={{ marginRight: "16px" }}>
                <Button
                  sx={{ marginRight: 2, fontWeight: "600", textTransform: "none", color: "red" }}
                  className="red-button"
                  startIcon={openConfirmDeleteVisit ? <UndoIcon /> : <DeleteIcon />}
                  onClick={() => setOpenConfirmDeleteVisit(!openConfirmDeleteVisit)}
                >
                  {openConfirmDeleteVisit ? "Annulla" : "Elimina"}
                </Button>
              </div>}
              <Box pt="16px">
                <Collapse in={openConfirmDeleteVisit}>
                  <Alert
                    severity="warning"
                    action={
                      <Box>
                        <Button
                          color="inherit"
                          sx={{
                            textTransform: "none"
                          }}
                          onClick={() => setOpenConfirmDeleteVisit(false)}
                        >
                          Annulla
                        </Button>
                        <Button
                          color="inherit"
                          sx={{
                            textTransform: "none"
                          }}
                          onClick={() => handleDelete(selectedRow.id)}
                        >
                          Elimina
                        </Button>
                      </Box>
                    }
                  >
                    <AlertTitle>Attenzione</AlertTitle>
                    Stai eliminando un paziente — <strong>Sei sicuro?</strong>
                  </Alert>
                </Collapse>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default Pazienti;
