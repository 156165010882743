import React from "react";
import AsyncSelect from 'react-select/async';
import axios from "axios"
import { useSelector } from "react-redux";


const IngredientSearchBar = ({
  value,
  getOptionLabel,
  onChange,
  multiple,
  id,
  label,
}) => {
  const apiUrl = process.env.REACT_APP_BASE_URL
  const accessToken = useSelector((state) => state.auth.userToken);
  const getIngredients = async (ingredient) => {
    try {
      const res = await axios.get(
        `${apiUrl}/nutriboard/ingredients/?page=0&limit=10${ingredient !== "" ? "&text=" + ingredient : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
        }
      );

      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      console.error("An error occurred while getting ingredients:", error.message);
    }
  }
  const loadOptions = async (inputValue, callback) => {
    try {
      const ingredients = await getIngredients(inputValue);
      callback(ingredients); // Assuming ingredients is an array
    } catch (error) {
      console.error("An error occurred while loading options:", error.message);
      callback([]); // Return an empty array in case of error
    }
  }
  const handleOnChange = (newValue, actionMeta) => {
    // const values = select.option
    onChange(actionMeta, newValue)
  }
  return (
    <AsyncSelect
      cacheOptions
      defaultOptions
      getOptionLabel={getOptionLabel}
      getOptionValue={({ id }) => id}
      id={id}
      isMulti={multiple}
      loadOptions={loadOptions}
      onChange={handleOnChange}
      value={value}
      loadingMessage={() => "Caricamento"}
      noOptionsMessage={() => "Nessun ingrediente"}
      placeholder={label}
    />
  );
}

export default IngredientSearchBar;
