import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Toolbar, Button, Typography } from "@mui/material";
import { useGetClientDetailsQuery } from "../../../redux/state/api";
import Header from "../../../components/Header";
import EditIcon from "@mui/icons-material/Edit";
import CakeIcon from "@mui/icons-material/Cake";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { ClientTable } from "../../../components/Tabs";
import dayjs from "dayjs";
import "dayjs/locale/it";

dayjs.locale('it');

const PazientiDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const pathnameParts = location.pathname.split("/");
  const id = pathnameParts[pathnameParts.length - 1];

  const { data, isLoading } = useGetClientDetailsQuery(id);

  if (isLoading) {
    return <div class="loader-container">
      <div class="loader"></div>
    </div>
      ;
  }
  return (
    <Box m="1.5rem 2.5rem">
      <Toolbar sx={{ justifyContent: "space-between", ml: "-18px" }}>
        {/*LEFT SIDE*/}
        <Button
          sx={{
            color: "#464868",
            textDecoration: "underline",
            fontSize: "14px",
            fontWeight: "500",
          }}
          onClick={() => navigate("/pazienti")}
        >
          Torna ai pazienti
        </Button>

        {/*RIGHT SIDE*/}
        <div className="gradient-border-wrapper">
          <Button
            sx={{
              color: "linear-gradient(to top right, #B0D99C, #68BE6A)",
              fontWeight: 600, textTransform: 'none'
            }}
            className="gradient-button"
            startIcon={<EditIcon style={{ color: '#68BE6A' }} />}
            onClick={() => navigate(`/pazienti/modifica/${id}`)}
          >
            Modifica
          </Button>
        </div>
      </Toolbar>
      <Header title={`${data.name} ${data.surname}`} />
      <Box sx={{ display: "flex", gap: "5rem" }}>
        <Box
          display="flex"
          alignItems="center"
          gap="8px"
          width="150px"
          height="50px"
        >
          <CakeIcon sx={{ color: "#464868" }} />
          <Typography variant="h1" fontSize="16px" color="#464868">
            {
              data.birth_date !== null ?
                new Date(data.birth_date).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })
                : "Data di nascita"
            }
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          gap="8px"
          width="150px"
          height="50px"
        >
          {data.gender === "male" ? (
            <MaleIcon sx={{ color: "#464868" }} />
          ) : (
            <FemaleIcon sx={{ color: "#464868" }} />
          )}
          <Typography variant="h1" fontSize="16px" color="#464868">
            {data.gender !== null ? data.gender === "male" ? "maschio" : "femmina" : "Sesso"}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          gap="8px"
          width="150px"
          height="50px"
        >
          <EmailIcon sx={{ color: "#464868" }} />
          <Typography variant="h1" fontSize="16px" color="#464868">
            {data.email !== null ? data.email : "Email"}
          </Typography>
        </Box>
        <Box
          ml="70px"
          display="flex"
          alignItems="center"
          gap="8px"
          width="200px"
          height="50px"
        >
          <LocalPhoneIcon sx={{ color: "#464868" }} />
          <Typography variant="h1" fontSize="16px" color="#464868">
            {data.phone !== null ? data.phone : "+39 000 0000 0000"}
          </Typography>
        </Box>
      </Box>
      <Box>
        <ClientTable userInfo={data} clientName={data.name} clientSurname={data.surname} />
      </Box>
    </Box>
  );
};

export default PazientiDetails;
