import React from "react";
import {
    Box,
    FormControlLabel,
    FormGroup,
    MenuItem,
    Typography,
    Select,
    Switch,
    Tooltip as MuiTooltip
} from "@mui/material";
import { MeasurementMultiLineChart } from "../MeasurementLineChart";
import {
    calculate_ffm_athletes,
    calculate_ffm_lohman_general,
    calculate_ffm_overweight,
    calculate_tbw_sun,
    calculate_ecw_sergi
} from "../../utils/measurements_metrics";
import {
    Legend,
    ScatterChart,
    Scatter,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    ZAxis,
} from "recharts";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MetricsExplanationDialog from './MetricsExplanationDialog';

export function BiaMetricsSection({ visitsData, userInfo }) {

    const vertexMale = {
        major: [
            {
                "resistance": 365.5,
                "reactance": 48.5,
            },
            {
                "resistance": 226,
                "reactance": 13.5,
            }
        ],
        minor: [
            {
                "resistance": 219,
                "reactance": 35.5,
            },
            {
                "resistance": 378,
                "reactance": 27,
            }
        ]
    }

    const vertexFemale = {
        major: [
            {
                "resistance": 448,
                "reactance": 52.55,
            },
            {
                "resistance": 296,
                "reactance": 16,
            }
        ],
        minor: [
            {
                "resistance": 278,
                "reactance": 39,
            },
            {
                "resistance": 460,
                "reactance": 28,
            }
        ]
    }

    const vertex = userInfo.gender === "female" ? vertexFemale : vertexMale;

    const italianMaleEllipses = [
        { cx: 298.6, cy: 30.8, majorAxis: 106.83797732440009, minorAxis: 15.83321208713557, angle: 0.08121654067141293 },
        { cx: 298.6, cy: 30.8, majorAxis: 72.51151373159298, minorAxis: 10.746086779474712, angle: 0.08121654067141293 },
        { cx: 298.6, cy: 30.8, majorAxis: 51.22287530632584, minorAxis: 7.591145665135424, angle: 0.08121654067141293 },
    ];
    const italianFemaleEllipses = [
        { cx: 371.9, cy: 34.4, majorAxis: 121.0069747782861, minorAxis: 17.270466847888187, angle: 0.06577405531294733 },
        { cx: 371.9, cy: 34.4, majorAxis: 82.13726058524713, minorAxis: 11.722868359555196, angle: 0.06577405531294733 },
        { cx: 371.9, cy: 34.4, majorAxis: 58.02538599614417, minorAxis: 8.28155159666175, angle: 0.06577405531294733 },
    ];
    const ellipses = userInfo.gender === "female" ? italianFemaleEllipses : italianMaleEllipses;

    const generateEllipse = (cx, cy, majorAxis, minorAxis, angle, numPoints = 100) => {
        const points = [];

        for (let i = 0; i < numPoints; i++) {
            const theta = (2 * Math.PI * i) / numPoints;
            const x = majorAxis * Math.cos(theta);
            const y = minorAxis * Math.sin(theta);

            // Rotate the point by the inclination angle
            const xRot = cx + (x * Math.cos(angle) - y * Math.sin(angle));
            const yRot = cy + (x * Math.sin(angle) + y * Math.cos(angle));

            points.push({ resistance: xRot, reactance: yRot });
        }

        // Close the ellipse
        points.push(points[0]);

        return points;
    };
    const generateEllipseVertices = (cx, cy, majorSemiAxis, minorSemiAxis, angle) => {
        // Major axis vertices
        const majorVertices = [
            {
                resistance: cx + majorSemiAxis * Math.cos(angle),
                reactance: cy + majorSemiAxis * Math.sin(angle),
            },
            {
                resistance: cx - majorSemiAxis * Math.cos(angle),
                reactance: cy - majorSemiAxis * Math.sin(angle),
            }
        ];
        // Minor axis vertices
        const minorVertices = [
            {
                resistance: cx + minorSemiAxis * Math.cos(angle + Math.PI / 2),
                reactance: cy + minorSemiAxis * Math.sin(angle + Math.PI / 2),
            },
            {
                resistance: cx - minorSemiAxis * Math.cos(angle + Math.PI / 2),
                reactance: cy - minorSemiAxis * Math.sin(angle + Math.PI / 2),
            }
        ];

        return { majorVertices, minorVertices };
    };

    const ellipsesColor = [
        "#BF2B2B",
        "#EDC047",
        "#68BE6A",
    ]

    const biaColor = [
        "#4496A8",
        "#EA6B4D",
        "#68BE6A",
        "#BF2B2B",
    ]
    const [lineChartLinesWater, setLineChartLinesWater] = React.useState({
        totale: {
            color: "#4496A8",
            hide: false,
            name: "Totale",
        },
        intracellulare: {
            color: "#68BE6A",
            hide: false,
            name: "Intracellulare",
        },
        extracellulare: {
            color: "#BF2B2B",
            hide: false,
            name: "Extracellulare",
        }
    })
    const [lineChartLinesBodyDensity, setLineChartLinesBodyDensity] = React.useState({
        peso: {
            color: "#4496A8",
            hide: false,
            name: "Peso"
        },
        massa_grassa: {
            color: "#BF2B2B",
            hide: false,
            name: "Massa grassa",
        },
        massa_magra: {
            color: "#68BE6A",
            hide: false,
            name: "Massa magra",
        }
    })
    const [showWaterMetricsPercentage, setShowWaterMetricsPercentage] = React.useState(false);
    const [showBodyDensityMetricsPercentage, setShowBodyDensityMetricsPercentage] = React.useState(false);
    const [metricToUse, setMetricToUse] = React.useState("normopeso");
    const metrics = [
        "atleta",
        "normopeso",
        "sovrappeso"
    ]

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        const day = date.getDate().toString().padStart(2, '0'); // Get the day and pad it with leading zero if necessary
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get the month (zero-based) and pad it with leading zero if necessary
        const year = date.getFullYear(); // Get the full year

        return `${day}/${month}/${year}`;
    }
    const computeAge = (birthDate) => {
        const currentDate = new Date();

        let age = currentDate.getFullYear() - birthDate.getFullYear();

        const currentMonth = currentDate.getMonth();
        const birthMonth = birthDate.getMonth();
        const currentDay = currentDate.getDate();
        const birthDay = birthDate.getDate();

        if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDay < birthDay)) {
            age--;
        }

        return age;
    }
    const handleChangeSwitchWaterPercentage = () => {
        setShowWaterMetricsPercentage(!showWaterMetricsPercentage);
    }
    const handleChangeSwitchBodyDensityPercentage = () => {
        setShowBodyDensityMetricsPercentage(!showBodyDensityMetricsPercentage);
    }

    const handleLegendClick = (o) => {
        const { value } = o;
        //handle click on ellipses legend
        if (!!!value) {
            return;
        }
        const index = biaData.findIndex(obj => obj.label === value);
        const biaDataToUpdate = [...biaData];
        biaDataToUpdate[index].hide = !biaData[index].hide
        setBiaData(biaDataToUpdate);
    };

    const [biaData, setBiaData] = React.useState(visitsData.data.map((item) => {
        if (!item.bia_measurement) {
            return {};
        }
        const angleRadians = Math.atan2(item.bia_measurement.reactance, item.bia_measurement.resistance);
        const angleDegrees = angleRadians * 180 / Math.PI;
        const heightCm = item.measurement.height / 100
        return {
            data: [
                {
                    visit_date: formatDate(item.visit_date),
                    resistance: item.bia_measurement && parseFloat((item.bia_measurement.resistance / heightCm).toFixed(1)),
                    reactance: item.bia_measurement && parseFloat((item.bia_measurement.reactance / heightCm).toFixed(1)),
                    phase: parseFloat(angleDegrees.toFixed(1)),
                }],
            label: formatDate(item.visit_date),
            hide: false,
        }
    }).filter(item => Object.keys(item).length > 0));
    const waterData = visitsData.data.map((item) => {
        if (!item.bia_measurement) {
            return {};
        }
        const resistance = item.bia_measurement && item.bia_measurement.resistance;
        const reactance = item.bia_measurement && item.bia_measurement.reactance;
        const height = item.measurement && item.measurement.height;
        const weight = item.measurement && item.measurement.weight;
        const ecw = calculate_ecw_sergi(userInfo.gender, height, weight, resistance, reactance);
        const tbw = calculate_tbw_sun(userInfo.gender, height, resistance, weight);
        const icw = tbw - ecw;

        return {
            visit_date: formatDate(item.visit_date),
            totale: tbw,
            extracellulare: { value: ecw, percentage: ecw * 100 / tbw },
            intracellulare: { value: icw, percentage: icw * 100 / tbw },
        }
    }).filter(item => Object.keys(item).length > 0);
    const bodyDensityData = visitsData.data.map((item) => {
        if (!item.bia_measurement) {
            return {};
        }
        const resistance = item.bia_measurement && item.bia_measurement.resistance;
        const reactance = item.bia_measurement && item.bia_measurement.reactance;
        const height = item.measurement && item.measurement.height;
        const weight = item.measurement && item.measurement.weight;
        const age = computeAge(new Date(userInfo.birth_date));

        const ffmAthletes = calculate_ffm_athletes(height, weight, resistance, reactance, age);
        const ffmLohmanGeneral = calculate_ffm_lohman_general(height, resistance, weight);
        const ffmOverweight = calculate_ffm_overweight(userInfo.gender, height, weight, resistance, age);
        const ffmAthletesPercentage = ffmAthletes * 100 / weight;
        const ffmLohmanGeneralPercentage = ffmLohmanGeneral * 100 / weight;
        const ffmOverweightPercentage = ffmOverweight * 100 / weight;
        return {
            visit_date: formatDate(item.visit_date),
            weight: weight,
            atleta: { value: ffmAthletes, percentage: ffmAthletesPercentage },
            normopeso: { value: ffmLohmanGeneral, percentage: ffmLohmanGeneralPercentage },
            sovrappeso: { value: ffmOverweight, percentage: ffmOverweightPercentage },
        }
    }).filter(item => Object.keys(item).length > 0);


    const [openMassDialog, setOpenMassDialog] = React.useState(false);
    const [openWaterDialog, setOpenWaterDialog] = React.useState(false);
    const handleClickOpenMassDialog = () => {
        setOpenMassDialog(true);
    };

    const handleCloseMassDialog = () => {
        setOpenMassDialog(false);
    };
    const handleClickOpenWaterDialog = () => {
        setOpenWaterDialog(true);
    };

    const handleCloseWaterDialog = () => {
        setOpenWaterDialog(false);
    };

    return (
        <Box mt="32px" ml="-18px">
            <Box mb="20px">
                <Typography color="#2F1847" fontSize="24px" fontWeight="700">
                    BIA
                </Typography>
            </Box>
            <Box mb="20px">
                <Typography color="#2F1847" fontSize="24px" fontWeight="600">
                    Vettore BIA
                </Typography>
            </Box>
            <ResponsiveContainer width={700} height={700}>
                <ScatterChart
                    margin={{
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: 20,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        type="number"
                        dataKey="resistance"
                        name="Resistenza"
                        unit="Ω"
                        tickCount={10}
                        domain={[0, 800]}
                    />
                    <YAxis
                        type="number"
                        dataKey="reactance"
                        name="Reattanza"
                        unit="Ω"
                        tickCount={20}
                        domain={[0, 100]}
                    />
                    <ZAxis
                        type="number"
                        dataKey="phase"
                        name="Angolo di fase"
                        unit="°"
                    />
                    <Tooltip
                    />
                    <Legend onClick={handleLegendClick} />
                    {/* <ReferenceArea key="yellow" x1={0} x2={752} y1={0} y2={90} fill="#68BE6A" fillOpacity={0.1} label={{ value: 'Malnutrizione', position: 'insideTopRight', fontWeight: 600 }} />
                        <ReferenceArea key="green" x1={0} x2={667} y1={0} y2={68} fill="#68BE6A" fillOpacity={0.125} label={{ value: 'Normalità', position: 'insideTopRight', fontWeight: 600 }} />
                        <ReferenceArea key="blue" x1={0} x2={471} y1={0} y2={50} fill="#68BE6A" fillOpacity={0.15} label={{ value: 'Obesità', position: 'insideTopRight', fontWeight: 600 }} />
                        <ReferenceArea key="red" x1={0} x2={464} y1={0} y2={25} fill="#68BE6A" fillOpacity={0.175} label={{ value: 'Edema', position: 'insideTopRight', fontWeight: 600 }} /> */}
                    {ellipses.map((ellipse, index) => (
                        <Scatter data={generateEllipse(ellipse.cx, ellipse.cy, ellipse.majorAxis, ellipse.minorAxis, ellipse.angle)} line fill={ellipsesColor[index]} strokeWidth={2} shape={null} legendType="none" />
                    ))}
                    <Scatter data={vertex.major} fill="black" line strokeWidth={1} shape={null} legendType="none" />
                    <Scatter data={vertex.minor} fill="black" line shape={null} strokeWidth={1} legendType="none" />
                    {biaData.map((elem, index) => (
                        <Scatter name={elem.label} data={elem.data} fill={biaColor[index % biaColor.length]} shape="circle" hide={elem.hide} />
                    ))}
                </ScatterChart>
            </ResponsiveContainer>
            <Box mb="20px">
                <Typography color="#2F1847" fontSize="24px" fontWeight="600">
                    Composizione corporea
                </Typography>
            </Box>
            <Box display={"flex"} justifyContent="space-between" gap={2}>
                <Box flex="1 1 45%" minWidth={0}>
                    <Box mb="20px" height={"120px"}>
                        <Typography
                            color="#2F1847"
                            sx={{
                                fontSize: '20px',
                                fontWeight: 600,
                                display: 'flex', // Make sure the content is aligned
                                alignItems: 'center' // Align items vertically
                            }}>
                            Massa
                            <MuiTooltip title="Clicca qui per dettagli sulle formule utilizzate" arrow>
                                <InfoOutlinedIcon
                                    sx={{ ml: 1, cursor: 'pointer' }}
                                    aria-label="information"
                                    onClick={handleClickOpenMassDialog}
                                />
                            </MuiTooltip>
                        </Typography>
                        <MetricsExplanationDialog
                            open={openMassDialog}
                            handleClose={handleCloseMassDialog}
                            metricsInfo={[
                                {
                                    metric: "atleta",
                                    paper: "Campa, F., et al. (2023). Assessment of body composition in athletes: A comparison of different methods. DOI: 10.1186/s12967-023-04795-z."
                                },
                                {
                                    metric: "normopeso",
                                    paper: "Lohman, T. G. (1992) (Campa, 2022). Advances in body composition assessment. Current Issues in Exercise Science Series Monograph No. 3. Champaign, IL: Human Kinetics. DOI: 10.1016/j.nut.2022.111694."
                                },
                                {
                                    metric: "sovrappeso",
                                    paper: "Pichard, C., et al. (2000). Nutritional assessment of obese patients. Obesity Surgery, 10(2), 126-130. DOI: 10.1381/096089200321677437."
                                }
                            ]}
                        />
                        <Box display="flex" alignItems="center" justifyContent="start" mb={2} mt={2} >

                            <Select
                                style={{ marginRight: '16px' }}
                                color="secondary"
                                value={metricToUse}
                                onChange={(event) => setMetricToUse(event.target.value)}
                            >
                                {metrics.map((metric) => (
                                    <MenuItem key={metric} value={metric}>
                                        {metric}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormGroup>
                                <FormControlLabel control={<Switch color="secondary" onChange={handleChangeSwitchBodyDensityPercentage} />} label="%" />
                            </FormGroup>
                        </Box>
                    </Box>
                    <MeasurementMultiLineChart
                        data={bodyDensityData.map(item => {
                            if (showBodyDensityMetricsPercentage) {
                                return {
                                    visit_date: item.visit_date,
                                    massa_magra: parseFloat(item[metricToUse]["percentage"].toFixed(1)),
                                    massa_grassa: parseFloat((100 - item[metricToUse]["percentage"]).toFixed(1)),
                                }
                            }
                            return {
                                visit_date: item.visit_date,
                                peso: item.weight,
                                massa_magra: parseFloat(item[metricToUse]["value"].toFixed(1)),
                                massa_grassa: parseFloat((item.weight - item[metricToUse]["value"]).toFixed(1)),
                            }
                        }
                        )}
                        xAxisDataKey="visit_date"
                        yAxisLabelValue={`${metricToUse} ${showBodyDensityMetricsPercentage ? "(%)" : "(kg)"}`}
                        lines={lineChartLinesBodyDensity}
                        setLines={setLineChartLinesBodyDensity}
                        isXAxisDate={true}
                        xAxisLabel="Data visita"
                    />
                </Box>
                <Box flex="1 1 45%" minWidth={0}>
                    <Box mb="20px" height={"120px"}>
                        <Typography color="#2F1847" fontSize="20px" fontWeight="600" sx={{
                            fontSize: '20px',
                            fontWeight: 600,
                            display: 'flex', // Make sure the content is aligned
                            alignItems: 'center' // Align items vertically
                        }}>
                            Acqua
                            <MuiTooltip title="Clicca qui per dettagli sulle formule utilizzate" arrow>
                                <InfoOutlinedIcon
                                    sx={{ ml: 1, cursor: 'pointer' }}
                                    aria-label="information" onClick={handleClickOpenWaterDialog}
                                />
                            </MuiTooltip>
                        </Typography>
                        <MetricsExplanationDialog
                            open={openWaterDialog}
                            handleClose={handleCloseWaterDialog}
                            metricsInfo={[
                                {
                                    metric: "Acqua totale",
                                    paper: "Sun, S. S., et al. (2003). Estimating Total Body Water from bioelectrical impedance in pediatric populations. DOI: 10.1203/01.PDR.0000061910.19756.F7."
                                },
                                {
                                    metric: "Acqua extracellulare",
                                    paper: "Sergi, G., et al. (1994). Estimating extracellular water in healthy subjects and in patients with cirrhosis using bioelectrical impedance analysis. DOI: DOI: 10.1007/BF00205154."
                                }
                            ]}
                        />
                        <Box mt={2}>
                            <FormGroup>
                                <FormControlLabel control={<Switch color="secondary" onChange={handleChangeSwitchWaterPercentage} />} label="%" />
                            </FormGroup>
                        </Box>
                    </Box>
                    <MeasurementMultiLineChart
                        data={waterData.map(item => {
                            if (showWaterMetricsPercentage) {
                                return {
                                    visit_date: item.visit_date,
                                    extracellulare: parseFloat(item.extracellulare["percentage"].toFixed(1)),
                                    intracellulare: parseFloat(item.intracellulare["percentage"].toFixed(1)),
                                }
                            }
                            return {
                                visit_date: item.visit_date,
                                totale: parseFloat(item.totale.toFixed(1)),
                                extracellulare: parseFloat(item.extracellulare["value"].toFixed(1)),
                                intracellulare: parseFloat(item.intracellulare["value"].toFixed(1)),
                            }
                        }
                        )}
                        xAxisDataKey="visit_date"
                        yAxisLabelValue={`Acqua ${showWaterMetricsPercentage ? "(%)" : "(kg)"}`}
                        lines={lineChartLinesWater}
                        setLines={setLineChartLinesWater}
                        isXAxisDate={true}
                        xAxisLabel="Data visita"
                    />
                </Box>
            </Box>
        </Box>
    );
};

