import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../redux/features/slice/authSlice";
import {
  Box,
  Typography,
  FormControl,
  FilledInput,
  InputAdornment,
  IconButton,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Button,
  CircularProgress,
} from "@mui/material";
import logo from "../../assets/Modalità_Isolamento.svg";
import frame from "../../assets/Frame.png";
import Header from "../../components/Header.jsx";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Login = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const { loading, success, error } = useSelector((state) => state.auth);
  const [rememberMe, setRememberMe] = React.useState(false);
  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();

  useEffect(() => {
    if (success) {
      navigate("/dashboard");
    }
  }, [navigate, success]);

  const submitForm = (data) => {
    dispatch(userLogin({ ...data, rememberMe }));
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box
      height="100%"
      width="100%"
      sx={{
        display: "flex",
        justifyContent: "flex-between",
        overflow: "hidden",
      }}
    >
      <Box height="100vh" width="50%" sx={{ bgcolor: "#a1d392" }}>
        <Box component="img" alt="logo" src={logo} width="25%" m="1.5rem 2.5rem"></Box>
        <Box m="10rem 2.5rem">
          <Typography
            variant="h5"
            color="white"
            sx={{ fontWeight: "600", fontSize: "32px" }}
            width="70%"
          >
            Benvenuto nella Nutriboard Dietiamo 
          </Typography>
          <Typography
            variant="body2"
            color="white"
            sx={{ fontSize: "16px", marginTop: "10px" }}
            width="50%"
          >
            Inizia subito a connetterti ai tuoi pazienti, effettua il Login
          </Typography>
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "-12%" }}
        >
          <Box component="img" alt="frame" src={frame} width="55%" sx={{marginLeft: "-300px",}} ></Box>
        </Box>
      </Box>
      <Box height="100vh" width="50%">
        <Box m="14rem 3.5rem">
          <Header title="Login" />
          <FormControl
            variant="standard"
            sx={{ m: 1, minWidth: 500, marginLeft: "-10px", marginTop: "30px" }}
          >
            <InputLabel
              id="email"
              sx={{
                marginLeft: "10px",
                textTransform: 'none',
                fontWeight: 500,
                boxShadow: 'none',
                '&:hover, &:active, &:focus': {
                  boxShadow: 'none'
                }
              }}
              color="secondary"
            >
              Email
            </InputLabel>
            <FilledInput
              type="email"
              id="email"
              variant="filled"
              sx={{
                textTransform: 'none',
                fontWeight: 500,
                boxShadow: 'none',
                '&:hover, &:active, &:focus': {
                  boxShadow: 'none'
                }
              }}
              color="secondary"
              {...register("email")}
              required
            />
          </FormControl>
          <FormControl
            variant="standard"
            sx={{ m: 1, minWidth: 500, marginLeft: "-10px", marginTop: "30px" }}
          >
            <InputLabel
              id="password-label"
              sx={{ marginLeft: "10px",
                textTransform: 'none',
                fontWeight: 500,
                boxShadow: 'none',
                '&:hover, &:active, &:focus': {
                  boxShadow: 'none'
                }
              }}
              color="secondary"
            >
              Password
            </InputLabel>
            <FilledInput
              {...register("password")}
              required
              sx={{
                textTransform: 'none',
                fontWeight: 500,
                boxShadow: 'none',
                '&:hover, &:active, &:focus': {
                  boxShadow: 'none'
                }
              }}
              color="secondary"

              id="password-label"
              type={showPassword ? "text" : "password"}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault(); // Prevents the default form submission
                  handleSubmit(submitForm)(); // Manually trigger the form submission
                }
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {error && <p style={{ color: "red" }}> {error}</p>}

          </FormControl>
          <FormControl
            variant="standard"
            sx={{
              m: 1,
              minWidth: 600,
              marginLeft: "-10px",
              marginTop: "30px",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      textTransform: 'none',
                      fontWeight: 500,
                      boxShadow: 'none',
                      '&:hover, &:active, &:focus': {
                        boxShadow: 'none'
                      }
                    }}
                    color="secondary"
                    checked={rememberMe}
                    onChange={handleRememberMeChange}
                  />
                }
                label="Ricordami"
              />
              <Typography
                variant="h5"
                color="#68BE6A"
                sx={{ fontWeight: "600", fontSize: "14px", cursor: "pointer", mt: "14px" }}
                width="50%"
                onClick={() => navigate("/passwordDimenticata")}
              >
                Ho dimenticato la password
              </Typography>
            </Box>
           
            <Typography
  variant="h5"
  sx={{
    color: "#68BE6A",
    fontWeight: "500",
    fontSize: "14px",
    mt: "14px",
    display: 'flex',
    alignItems: 'center'
  }}
>
  Non sei ancora iscritto?&nbsp;
  <a
    style={{
      color: "#68BE6A",
      fontWeight: "600",
      fontSize: "14px",
      cursor: "pointer",
      textDecoration: "none"
    }}
    href="https://dietiamo.it/"
    target="_blank"
    rel="noreferrer noopener"
  >
    Registrati subito&nbsp;
  </a>

</Typography>


          </FormControl>
          <FormControl
            variant="standard"
            sx={{
              
              m: 1,
              minWidth: 500,
              marginLeft: "-10px",
            }}
          >
            <Button
              sx={{
                mt: "14px",
                background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
                textTransform: 'none',
                fontWeight: 600,
                fontSize: "1rem",
                boxShadow: 'none',
                '&:hover, &:active, &:focus': {
                  boxShadow: 'none'
                }
              }}
              variant="contained"
              size="medium"
              onClick={handleSubmit(submitForm)}
              disabled={loading}
            >
              {loading ? <CircularProgress color="secondary" /> : "Invia"}
            </Button>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
