import React, { createContext, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "../redux/features/slice/userSlice";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const userId = useSelector((state) => state.auth.userId);
  const accessToken = useSelector((state) => state.auth.userToken);
  const { lastFetchTime, initialFetchDone } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();

  const MIN_FETCH_INTERVAL = 600000;

  window.addEventListener("beforeunload", () => {
    dispatch(fetchUserData({ userId, accessToken }));
  });

  useEffect(() => {
    const currentTime = Date.now();
    if (
      !initialFetchDone ||
      currentTime - lastFetchTime >= MIN_FETCH_INTERVAL
    ) {
      dispatch(fetchUserData({ userId, accessToken }));
    }
  }, [dispatch, initialFetchDone, lastFetchTime, accessToken, userId]);

  const data = useSelector((state) => state.user.data);

  return <UserContext.Provider value={data}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  return useContext(UserContext);
};
